import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {ReduxStateType} from 'src/store/store';
import {
  selectTaxProfile,
  selectTaxProfileAnswerForGivenYear,
} from 'src/store/taxProfile/taxProfile.selector';
import BoiSubSectionBox from './BoiSubsectionBox';
import {selectBoiData} from 'src/store/boi/boi.selectors';
import SimpleArrayField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleArrayField';
import {
  BOI_REQUIRED_ANSWERS,
  SELECT_POINTS_APPLICABLE_FOR_BUSINESS_COPIES,
} from '../constants';
import {BOI_SECTION_NAMES} from '../types';

interface Props {
  style?: React.CSSProperties;
}

const EligibilityQuestions = ({style}: Props) => {
  const {loaded: taxProfileLoaded} = useSelector(selectTaxProfile);
  const {data, loaded: boiDataLoaded} = useSelector(selectBoiData);

  const isLoading = !taxProfileLoaded || !boiDataLoaded;

  const answers = useSelector((state: ReduxStateType) => {
    return {
      [TaxProfileQuestion.IS_BOI_REQUIRED]: selectTaxProfileAnswerForGivenYear(
        state,
        TaxProfileQuestion.IS_BOI_REQUIRED,
        data.year,
      ),
      [TaxProfileQuestion.BUSINESS_FORMED_OR_REGISTERED_IN_USA]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.BUSINESS_FORMED_OR_REGISTERED_IN_USA,
          data.year,
        ),
      [TaxProfileQuestion.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024,
          data.year,
        ),
      [TaxProfileQuestion.BUSINESS_LLC_OR_C_CORP]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.BUSINESS_LLC_OR_C_CORP,
          data.year,
        ),
      [TaxProfileQuestion.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY,
          data.year,
        ),
      [TaxProfileQuestion.SELECT_POINTS_APPLICABLE_FOR_BUSINESS]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.SELECT_POINTS_APPLICABLE_FOR_BUSINESS,
          data.year,
        ),
      [TaxProfileQuestion.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TaxProfileQuestion.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES,
          data.year,
        ),
    };
  });

  return (
    <BoiSubSectionBox
      showIsEligibleTag={true}
      name="Eligibility details"
      style={style}
      boiSubsection={BOI_SECTION_NAMES.BOI_ELIGIBILIITY_QUESTIONS}
      year={data.year}>
      <SimpleKeyValueField
        name="Is BOI required"
        style={{marginBottom: 12}}
        value={answers[TaxProfileQuestion.IS_BOI_REQUIRED]}
        loading={isLoading}
      />
      {![BOI_REQUIRED_ANSWERS.NO, BOI_REQUIRED_ANSWERS.YES].includes(
        answers[TaxProfileQuestion.IS_BOI_REQUIRED],
      ) && (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{flex: 0.45}}>
            <SimpleKeyValueField
              name="Business formed or first registered in United States or its territories?"
              style={{marginBottom: 12}}
              value={
                answers[
                  TaxProfileQuestion.BUSINESS_FORMED_OR_REGISTERED_IN_USA
                ]
              }
              loading={isLoading}
            />
            <SimpleKeyValueField
              name="Company was created or registered before January 1, 2024 "
              style={{marginBottom: 12}}
              value={
                answers[
                  TaxProfileQuestion
                    .BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024
                ]
              }
              loading={isLoading}
            />
            <SimpleKeyValueField
              style={{marginBottom: 12}}
              name="Is the company either an LLC, partnership (LLP), C Corp or S Corp?"
              loading={isLoading}
              value={answers[TaxProfileQuestion.BUSINESS_LLC_OR_C_CORP]}
            />
            <SimpleKeyValueField
              style={{marginBottom: 12}}
              name="Was it formed by filing documents with a secretary of state or similar office?"
              value={
                answers[
                  TaxProfileQuestion
                    .BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY
                ]
              }
              loading={isLoading}
            />
          </div>
          <div style={{flex: 0.45}}>
            <SimpleArrayField
              name="Select all that applies to your business"
              style={{marginBottom: 12}}
              value={
                answers[
                  TaxProfileQuestion.SELECT_POINTS_APPLICABLE_FOR_BUSINESS
                ] ?? []
              }
              getItem={(item) =>
                SELECT_POINTS_APPLICABLE_FOR_BUSINESS_COPIES[item]
              }
              loading={isLoading}
            />
            <SimpleKeyValueField
              name="Business falls under Financial services, Public company or Tax-exempt"
              style={{marginBottom: 12}}
              value={
                answers[
                  TaxProfileQuestion.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES
                ]
              }
              loading={isLoading}
            />
          </div>
        </div>
      )}
    </BoiSubSectionBox>
  );
};

export default EligibilityQuestions;
