import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import DSButton from 'src/DesignSystem/Button/Button';
import {patchTaxFiling, updateCCHClientId} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import BackIcon from 'src/icons/BackIcon';
import {selectUser} from 'src/store/user/user.selector';
import {useDispatch} from 'react-redux';
import {fetchUserInfo} from 'src/store/user/user.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import UpdateCCHClientId from 'src/CpaCenterV2/common/UserSummaryBar/components/UpdateCCHClientId';
import LabelField from 'src/CpaCenterV2/common/UserSummaryBar/components/LabelField';
import NotesIcon from 'src/icons/NotesIcon';
import NotesPopUp from 'src/CpaCenterV2/common/UserSummaryBar/components/NotesPopUp';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import Box from 'src/DesignSystem/Box/Box';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectAppReducerState} from 'src/store/app/app.selector';
import {ReduxStateType} from 'src/store/store';
import TaxProfileIcon from 'src/icons/TaxProfileIcon';
import {RETURN_USER_INFO_HEADER} from '../Partnership.constants';
import ReturnStatusUpdate from 'src/CpaCenterV2/common/UserSummaryBar/components/ReturnStatusUpdate';

const ReturnUserInfoHeader = () => {
  const {
    taxFilingId,
    userId,
    year,
    userName,
    partnerName,
    cchClientId,
    lastStatusChange,
    notes,
    loaded,
  } = useSelector(selectUser);
  const isTaxProfileSummaryDrawer = useSelector<ReduxStateType>((state) =>
    selectAppReducerState(state, AppReducerStates.isTaxProfileSummaryDrawer),
  ) as boolean;
  const dispatch = useDispatch();
  const {userId: uid} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {navigateToTaxReturnsList} = usePartnershipNavigation();

  const [loading, setLoading] = useState(!loaded);
  const [cchPopUp, setCchPopUp] = useState(false);
  const [notesPopUp, setNotesPopUp] = useState(false);

  useEffect(() => {
    if (loaded) {
      setLoading(false);
    }
  }, [loaded]);

  const onUpdateCchId = async (newId: number) => {
    try {
      setLoading(true);
      await updateCCHClientId(userId, newId);
    } catch (e) {
      alert('Failed to update CCH Id');
    } finally {
      await dispatch(fetchUserInfo(uid, activeYear));
      setLoading(false);
      setCchPopUp(false);
    }
  };

  const onUpdateNote = async (newNote: string) => {
    try {
      setLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.NOTE]: newNote},
      );
    } catch (e) {
      alert('Failed to update Note');
    } finally {
      await dispatch(fetchUserInfo(uid, activeYear));
      setLoading(false);
      setNotesPopUp(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: themmeColor.white,
          display: 'flex',
          height: RETURN_USER_INFO_HEADER,
          alignItems: 'center',
          paddingInline: 20,
          gap: 32,
        }}>
        <DSButton
          type="secondary"
          startIcon={<BackIcon />}
          text="User list"
          onClick={() => navigateToTaxReturnsList()}
        />
        <SimpleKeyValueField name="User ID" value={userId} loading={loading} />
        <SimpleKeyValueField name="Year" value={year} loading={loading} />
        <SimpleKeyValueField name="Name" value={userName} loading={loading} />
        <SimpleKeyValueField
          name="Partner name"
          value={partnerName}
          loading={loading}
        />
        <LabelField
          label={'CCH Client ID'}
          value={cchClientId}
          onClick={() => setCchPopUp(true)}
          loaded={!loading}
        />
        <SimpleKeyValueField
          name="Status changed at"
          value={lastStatusChange}
          loading={loading}
        />
        <ReturnStatusUpdate loading={loading} setLoading={setLoading} />
        <DSButton
          startIcon={<NotesIcon />}
          text={'Notes'}
          disabled={loading}
          onClick={() => setNotesPopUp(true)}
          type="secondary"
        />
        <Box
          hoverEffect
          onClick={() =>
            dispatch(
              setAppState(
                AppReducerStates.isTaxProfileSummaryDrawer,
                !isTaxProfileSummaryDrawer,
              ),
            )
          }
          backgroundColor={
            isTaxProfileSummaryDrawer ? themmeColor.black : '#F0F0F0'
          }
          style={{borderColor: themmeColor.silver, padding: 12}}>
          <TaxProfileIcon />
        </Box>
      </div>
      <UpdateCCHClientId
        key={`cchUpdatePopUp-${cchClientId}-${cchPopUp}`}
        isOpen={cchPopUp}
        onClose={() => setCchPopUp(false)}
        cchClientId={cchClientId}
        loading={loading}
        onUpdate={onUpdateCchId}
      />
      <NotesPopUp
        key={`notesPopUp-${notes}-${notesPopUp}`}
        isOpen={notesPopUp}
        onClose={() => setNotesPopUp(false)}
        loading={loading}
        onUpdate={onUpdateNote}
        notes={notes}
      />
    </>
  );
};

export default ReturnUserInfoHeader;
