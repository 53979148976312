import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {selectDeductionSummary} from 'src/store/deductionSummary/deductionSummary.selector';
import {IRSCategory} from 'src/store/transactions/transactions.constants';
import _ from 'lodash';
import {selectApplicableDeductions} from 'src/store/applicableDeductions/applicableDeductions.selector';
import {
  DeductionAmountSource,
  TAX_DEDUCTION_TYPE,
} from 'src/store/deductionSummary/deductionSummary.reducer';
import {getTaxDeductionSectionSchedule} from '../TaxProfileSections/Deductions/Deductions.utils';
import {ReduxStateType} from 'src/store/store';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useDeductions = () => {
  const deductionSummary = useSelector(selectDeductionSummary);
  const {activeYear} = useActiveYear();
  const casualtyTheftLosses = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.CASUALTY_N_THEFT_LOSSES,
      activeYear,
    ),
  );
  const interestOnInvestmentLoans = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.INTEREST_ON_INVESTMENT_LOANS,
      activeYear,
    ),
  );
  const gamblingLosses = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.GAMBLING_LOSSES,
      activeYear,
    ),
  );

  const {
    sch_a_category_wise_data,
    sch_c_category_wise_data_map,
    loaded: deductionSummaryLoaded,
  } = deductionSummary;

  const {
    sectionWiseMapping,
    allOtherDeductions,
    loaded: sectionWiseApplicableDeductionsLoaded,
  } = useSelector(selectApplicableDeductions);

  const formatAmount = (amount: number | null | undefined) => {
    if (amount != null) {
      return CurrencyNumberFormat(amount);
    }
    return null;
  };

  const getOtherDeductions = () => {
    if (!sectionWiseApplicableDeductionsLoaded || !deductionSummaryLoaded) {
      return [];
    }
    const didBusinessStartInBetween = deductionSummary.biz_start_date_used;
    const otherDeductions: {
      label: string;
      value: string | null;
      value_pre_startup: string | null;
      source: DeductionAmountSource | null;
    }[] = [];
    allOtherDeductions.forEach((deductionItem) => {
      const deductionInfo =
        sch_c_category_wise_data_map[deductionItem.category];
      if (deductionItem?.l2?.length > 0) {
        deductionItem.l2.forEach((l2Name) => {
          const l2Info = deductionInfo?.l2_wise.find(
            (l2) => l2.l2_name === l2Name,
          );
          otherDeductions.push({
            label: `${deductionItem.category} (${l2Name})`,
            value: formatAmount(
              deductionItem.category === IRSCategory.Phone ||
                deductionItem.category === IRSCategory.Internet
                ? l2Info?.amount
                : l2Info?.total_expenses,
            ),
            value_pre_startup:
              didBusinessStartInBetween == null
                ? null
                : formatAmount(l2Info?.startup_expenses),
            source: l2Info?.source ?? null,
          });
        });
      } else {
        otherDeductions.push({
          label: deductionItem.category,
          value: formatAmount(
            deductionItem.category === IRSCategory.Phone ||
              deductionItem.category === IRSCategory.Internet
              ? deductionInfo?.amount
              : deductionInfo?.total_expenses,
          ),
          value_pre_startup:
            didBusinessStartInBetween == null
              ? null
              : formatAmount(deductionInfo?.startup_expenses),
          source: deductionInfo?.source ?? null,
        });
      }
    });
    return otherDeductions.sort((i1, i2) => i1.label.localeCompare(i2.label));
  };

  const getPersonalDeductions = () => {
    const deductions: {
      label: string;
      value: string | null;
      value_pre_startup?: string | null;
      source?: DeductionAmountSource | null;
    }[] = sch_a_category_wise_data
      .map((category) => ({
        label: category.category_display_name,
        value: formatAmount(category.total_expenses),
        source: category.source ?? null,
      }))
      .filter((item) => item.value !== null)
      .sort((i1, i2) => i1.label.localeCompare(i2.label));

    deductions.push({
      label: 'Casualty & Theft Losses',
      value: formatAmount(casualtyTheftLosses),
    });

    deductions.push({
      label: 'Interest on Investment Loans',
      value: formatAmount(interestOnInvestmentLoans),
    });

    deductions.push({
      label: 'Gambling Losses',
      value: formatAmount(gamblingLosses),
    });

    return deductions;
  };

  const getSectionApplicableDeductions = (
    sectionName: TaxProfileSubsection,
  ) => {
    if (!sectionWiseApplicableDeductionsLoaded || !deductionSummaryLoaded) {
      return [];
    }
    const scheduleName = getTaxDeductionSectionSchedule(sectionName);
    const schedule = deductionSummary[scheduleName];
    const didBusinessStartInBetween = deductionSummary.biz_start_date_used;
    const applicableDeductionsForSection = sectionWiseMapping[
      sectionName
    ]?.applicable_de.map((sectionDeductionInfo) => {
      const scheduleCategoryInfo = schedule.find(
        (categoryInfo) =>
          categoryInfo.category_display_name === sectionDeductionInfo.category,
      );
      const isL2Case = !!sectionDeductionInfo.l2;
      if (isL2Case) {
        const scheduleL2Info = scheduleCategoryInfo?.l2_wise.find(
          (l2Info) => l2Info.l2_name === sectionDeductionInfo.l2,
        );
        return {
          label: `${sectionDeductionInfo.category} (${sectionDeductionInfo.l2})`,
          value: formatAmount(
            sectionDeductionInfo.category === IRSCategory.Phone ||
              sectionDeductionInfo.category === IRSCategory.Internet ||
              scheduleName === TAX_DEDUCTION_TYPE.SCH_1
              ? scheduleL2Info?.amount
              : scheduleL2Info?.total_expenses,
          ),
          value_pre_startup:
            didBusinessStartInBetween == null
              ? null
              : formatAmount(scheduleL2Info?.startup_expenses),
          source: scheduleL2Info?.source ?? null,
        };
      }
      return {
        label: sectionDeductionInfo.category,
        value: formatAmount(
          sectionDeductionInfo.category === IRSCategory.Phone ||
            sectionDeductionInfo.category === IRSCategory.Internet ||
            scheduleName === TAX_DEDUCTION_TYPE.SCH_1
            ? scheduleCategoryInfo?.amount
            : scheduleCategoryInfo?.total_expenses,
        ),
        value_pre_startup:
          didBusinessStartInBetween == null
            ? null
            : formatAmount(scheduleCategoryInfo?.startup_expenses),
        source: scheduleCategoryInfo?.source ?? null,
      };
    });
    return applicableDeductionsForSection.sort((i1, i2) =>
      i1.label.localeCompare(i2.label),
    );
  };

  const getFields = (taxProfileSubsection: TaxProfileSubsection) => {
    let deductions: {
      label: string;
      value: string | null;
      value_pre_startup?: string | null;
      source?: DeductionAmountSource | null;
    }[] = [];
    switch (taxProfileSubsection) {
      case TaxProfileSubsection.HomeOfficeDetails:
      case TaxProfileSubsection.BusinessVehicleDetails:
      case TaxProfileSubsection.PhoneAndInternet:
      case TaxProfileSubsection.OfficeMortgage:
      case TaxProfileSubsection.StudentLoan:
      case TaxProfileSubsection.BusinessLoans:
      case TaxProfileSubsection.SalaryAndWagesPaid:
      case TaxProfileSubsection.HSAContributions:
      case TaxProfileSubsection.IRAContributions:
      case TaxProfileSubsection.HealthInsurancePremium:
        deductions = getSectionApplicableDeductions(taxProfileSubsection);
        break;
      case TaxProfileSubsection.PersonalDeductions:
        deductions = getPersonalDeductions();
        break;
      case TaxProfileSubsection.AllOtherDeductions:
        deductions = getOtherDeductions();
    }
    return deductions.filter(
      (data) => data.value != null || data.value_pre_startup != null,
    );
  };

  const getApplicableDeductions = () => {
    const sections =
      TaxProfileSectionToSubsectionMapping[TaxProfileSection.Deductions];
    const validSections: string[] = [];
    sections.forEach((section) => {
      const deductions = getFields(section);
      if (deductions.length > 0) {
        validSections.push(section);
      }
    });
    return validSections;
  };
  return {
    getFields,
    getApplicableDeductions,
  };
};

export default useDeductions;
