import React from 'react';
import ViewDocumentContext from 'src/CpaCenterV2/ViewDocument/ViewDocument.context';
import DocumentActions from 'src/CpaCenterV2/ViewDocument/components/DocumentActions';
import RenderDocument from 'src/CpaCenterV2/ViewDocument/components/RenderDocument';
import useViewDocument from 'src/CpaCenterV2/ViewDocument/useViewDocument';
import RenderOcrData from 'src/CpaCenterV2/ViewDocument/components/RenderOcrData';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const PartnerReturnDocumentReview = () => {
  const value = useViewDocument();
  const {navigateToDocumentReview} = usePartnershipNavigation();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  return (
    <ViewDocumentContext.Provider value={value}>
      <DocumentActions
        onNavigateToDoc={(docId) => {
          navigateToDocumentReview(docId);
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          overflow: 'hidden',
        }}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            flex: 0.7,
            overflowY: 'auto',
            overflowX: 'auto',
          }}>
          <RenderDocument />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            flex: 0.3,
            overflowY: 'auto',
          }}>
          <RenderOcrData showAssignBusiness />
        </div>
      </div>
    </ViewDocumentContext.Provider>
  );
};

export default PartnerReturnDocumentReview;
