import {
  FormConfigProps,
  FORM_INPUT_TYPE,
} from 'src/DesignSystem/Form/Form.types';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {BOOLEAN_OPTIONS} from 'src/constants/constants';
import {
  TaxFormFieldIds,
  DependentRelations,
  TaxFormType,
  QuarterlyPaymentType,
  IncomeEarnerType,
} from './taxForms.types';
import {TRANSFORMERS} from 'src/DesignSystem/Form/Forms.transformers';

const EditDepedendentTaxFormConfig: FormConfigProps[] = [
  {
    title: 'First name & middle initial',
    path: TaxFormFieldIds.DEPENDENT_FIRST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Last name',
    path: TaxFormFieldIds.DEPENDENT_LAST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Relationship to you',
    path: TaxFormFieldIds.DEPENDENT_RELATION,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(DependentRelations).map((val) => ({
      label: val,
      value: val,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Date of birth',
    path: TaxFormFieldIds.DEPENDENT_BIRTHDATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'SSN or ITIN or ATIN',
    path: TaxFormFieldIds.DEPENDENT_SSN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [
      VALIDATOR.NON_NULL_VALUE,
      VALIDATOR.NON_EMPTY_STRING,
      VALIDATOR.SSN_ITIN_ATIN,
    ],
    transformers: TRANSFORMERS.SSN,
  },
  {
    title: 'Are they either a US resident or US citizen?',
    path: TaxFormFieldIds.DEPENDENT_US_CITIZEN,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for dependent credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_DEPENDENT_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for education credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for adoption credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_ADOPTION_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
];

const EditPaymentsTaxFormConfig: FormConfigProps[] = [
  {
    title: 'Payment type',
    path: TaxFormFieldIds.PAYMENT_TYPE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(QuarterlyPaymentType).map((k) => ({
      label: k,
      value: k,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Amount paid',
    path: TaxFormFieldIds.AMOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Date of payment',
    path: TaxFormFieldIds.PAYMENT_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
];

const EditStateLivedTaxFormConfig: FormConfigProps[] = [
  {
    title: 'State',
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
    path: TaxFormFieldIds.STATE,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Who lived in this state',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: TaxFormFieldIds.INCOME_EARNER,
    options: Object.values(IncomeEarnerType)
      .filter((v) => v !== IncomeEarnerType.BUSINESS)
      .map((v) => ({
        label: v,
        value: v,
      })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'House number and street',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.NUMBER_AND_STREET,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Apartment/Unit number (if applicable)',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.APARTMENT_NUMBER,
  },
  {
    title: 'City',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.CITY,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Zip code',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.ZIP_CODE,
    validators: [VALIDATOR.VALIDATE_ZIP_CODE],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    title: 'County',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: TaxFormFieldIds.COUNTY,
    validators: [VALIDATOR.NON_NULL_VALUE],
    options: [],
  },
  {
    title: 'School district code',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.SCHOOL_DISTRICT_CODE,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Period of stay',
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Period',
    childProps: [
      {
        title: 'From',
        path: TaxFormFieldIds.FROM,
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'To',
        path: TaxFormFieldIds.TO,
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
    ],
    path: TaxFormFieldIds.PERIODS,
  },
];

const EditDLTaxFormConfig: FormConfigProps[] = [
  {
    title: 'Who does this is belong to?',
    path: TaxFormFieldIds.IS_SPOUSE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [
      {label: 'Self', value: false},
      {label: 'Spouse', value: true},
    ],
  },
  {
    title: 'Driving license number',
    path: TaxFormFieldIds.DL_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Expiry date',
    path: TaxFormFieldIds.EXPIRY_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Issue date',
    path: TaxFormFieldIds.ISSUE_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Issuing state.',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    useAbbrevation: true,
    isSingle: true,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Doc Number',
    path: TaxFormFieldIds.DOC_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
];

export const EditTaxFormConfigMap: {
  [k in TaxFormType]?: FormConfigProps[];
} = {
  [TaxFormType.DEPENDENT]: EditDepedendentTaxFormConfig,
  [TaxFormType.QUARTERLY_TAX_PAYMENTS]: EditPaymentsTaxFormConfig,
  [TaxFormType.STATES_LIVED]: EditStateLivedTaxFormConfig,
  [TaxFormType.DRIVING_LICENSE]: EditDLTaxFormConfig,
};
