import React from 'react';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {themmeColor} from 'src/constants/constants';
import DeleteIcon from 'src/icons/DeleteIcon';

interface EditShareholdersProps extends ArrayChildProps {}

const EditShareholders = ({
  array,
  arrayObjectIndex,
  onChangeValue,
  childProps,
}: EditShareholdersProps) => {
  const {updateObject, onChangeArrayItem, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );

  const {fields} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
  });

  const onDeleteShareholder = () => {
    const updatedArray = array.filter((_, idx) => idx !== arrayObjectIndex);
    onChangeValue(updatedArray);
  };

  return (
    <Box
      style={{padding: 12, marginBottom: 12}}
      col
      borderColor={themmeColor.silver}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={`Shareholder ${arrayObjectIndex + 1}`}
        />
        <DSButton
          type="secondary"
          startIcon={<DeleteIcon />}
          onClick={onDeleteShareholder}
          text={`Delete Shareholder ${arrayObjectIndex + 1}`}
        />
      </div>
      {fields.map((field) => (
        <div style={{marginBottom: 12}}>
          <Form {...field} />
        </div>
      ))}
    </Box>
  );
};

export default EditShareholders;
