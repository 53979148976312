import React from 'react';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {
  MortgageTypes,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const OfficeMortgage = () => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const {activeYear} = useActiveYear();
  const haveMortgageForOffice = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.HAVE_MORTGAGE_FOR_OFFICE,
      activeYear,
    ),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const officeMortgageTF = taxForms.filter(
    (tf) =>
      tf.formType === TaxFormType.FORM_1098_MIS &&
      tf.formData[TaxFormFieldIds.MORTGAGE_TYPE] === MortgageTypes.OFFICE,
  );

  return (
    <SubSectionBox
      name="Office Mortgage"
      taxprofileSubsection={TaxProfileSubsection.OfficeMortgage}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name="Did you have a mortgage for your office space?"
            value={haveMortgageForOffice}
          />
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.black60,
              marginBottom: 4,
            }}>
            Related document
          </Typography>
          {officeMortgageTF.map((tf) => (
            <DocumentPointer
              taxform={tf}
              border
              doc={documents.find((doc) => doc.docId === tf?.docId)}
            />
          ))}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.OfficeMortgage}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default OfficeMortgage;
