import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {TaxFormTypeToFieldsMap} from '../../Income.constants';

export const getTotalIncomeForFormType = (taxform: GenericTaxForm) => {
  let amount = 0;
  let calculation: string[] = [];
  TaxFormTypeToFieldsMap[taxform.formType]?.forEach((obj) => {
    const value = taxform.formData[obj.field];
    if (value) {
      amount += obj.operation * value;
      calculation.push(`${obj.operation > 0 ? '+ ' : '- '}${obj.field}`);
    }
  });
  return {amount, calculation};
};
