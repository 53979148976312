import {TaxProfileQuestion} from './taxProfile.types';
import {SET_TAX_PROFILE, UPDATE_TAX_PROFILE} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {SUPPORTED_YEARS} from '../globals/globals.reducer';
import _ from 'lodash';

export interface TaxProfileQuestionType {
  question_id: TaxProfileQuestion;
  answer: any;
}

let defaultState: {[year: number]: TaxProfileQuestionType[]} = {};
SUPPORTED_YEARS.forEach(
  (year) => (defaultState[year] = [] as TaxProfileQuestionType[]),
);

const otherDefaultStates = {
  status: REDUX_STATUS.INIT,
  loaded: false,
};

const initialState = {
  ...otherDefaultStates,
  ...defaultState,
} as typeof defaultState & typeof otherDefaultStates;

export const taxProfile = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_PROFILE:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_TAX_PROFILE:
      const year = action.payload.year;
      const reduxTpQuestions = state[year];

      const payloadQuestions = action.payload
        .questions as typeof reduxTpQuestions;

      const payloadQuestionsById = _.keyBy(payloadQuestions, 'question_id');

      const commonQuestions = _.intersectionBy(
        payloadQuestions,
        reduxTpQuestions,
        (q) => q.question_id,
      );

      const newQuestions = _.differenceBy(
        payloadQuestions,
        reduxTpQuestions,
        (q) => q.question_id,
      );

      const unmodifiedQuestions = _.differenceBy(
        reduxTpQuestions,
        payloadQuestions,
        (q) => q.question_id,
      );

      return {
        ...state,
        [year]: [
          ...newQuestions,
          ...commonQuestions.map((q) => payloadQuestionsById[q.question_id]),
          ...unmodifiedQuestions,
        ],
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxProfileStateType = typeof initialState;
