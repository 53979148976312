import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {PartnershipRoutes, RouteParts} from 'src/constants/routeName';
import {TaxReturn} from 'src/store/taxReturns/taxReturns.reducer';

export const getPartnershipSteps = ({
  cpaFacingRetStatus,
  taxReturns,
}: {
  cpaFacingRetStatus: TAX_FILING_STATUS;
  taxReturns: TaxReturn[];
}) => {
  return [
    {
      label: 'Document review',
      props: {
        url: PartnershipRoutes.UserDocuments,
        section: RouteParts.DocumentReview,
      },
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
      ),
    },
    {
      label: 'Tax profile review',
      props: {
        url: PartnershipRoutes.TaxProfileReview,
        section: RouteParts.TaxProfileReview,
      },
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
      ),
    },
    {
      label: 'User queries',
      props: {
        url: PartnershipRoutes.Queries,
        section: RouteParts.UserQueries,
      },
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.PREP_STARTED,
      ),
    },
    {
      label: 'Return review & E-filing',
      props: {
        url: PartnershipRoutes.ReviewEsign,
        section: RouteParts.ReviewEsign,
      },
      disabled: false,
      isCompleted: !taxReturns.some(
        (taxReturn) =>
          taxReturn.cpa_status !== TAX_FILING_STATUS.APPROVED_FROM_IRS,
      ),
    },
  ];
};
