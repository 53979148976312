import React from 'react';
import DocumentsList from 'src/CpaCenterV2/DocumentsReview/components/DocumentsList';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {useSelector} from 'react-redux';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {CircularProgress} from '@mui/material';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {
  BOTTOM_PRIMARY_ACTION_HEIGHT,
  MAIN_CONTENT_HEIGHT,
} from '../Partnership.constants';
import DSButton from 'src/DesignSystem/Button/Button';
import {useParntershipSteps} from '../hooks/usePartnershipSteps';
import {
  TAX_FILING_STATUS,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import {selectUser} from 'src/store/user/user.selector';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import CompletedRibbon from 'src/CpaCenterV2/common/CompletedRibbon/CompletedRibbon';
import {useUploadPartnerReturnDocument} from '../components/UploadPartnerReturnDocumentProvider';

const PartnerReturnDocumentList = () => {
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {navigateToDocumentReview} = usePartnershipNavigation();
  const {documents, loaded} = useSelector(selectDocuments);
  const {markStepComplete, isLoading} = useParntershipSteps();
  const {cpaFacingRetStatus} = useSelector(selectUser);

  const isDocumentReviewDone = isCpaCenterV2StepComplete(
    cpaFacingRetStatus,
    TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
  );

  const {ocrIdsInProgress, onUploadFiles} = useUploadPartnerReturnDocument();

  return (
    <>
      <div style={{display: 'flex', height: MAIN_CONTENT_HEIGHT}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 0.6,
            paddingInline: 12,
          }}>
          {ocrIdsInProgress.length > 0 && (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <CircularProgress
                size={24}
                style={{marginRight: 8, marginTop: 4}}
              />
              <Text
                text={`OCR in progress for ${ocrIdsInProgress.length} docs`}
                type={TEXT_TYPES.H6}
                fontWeight={FONT_WEIGHTS.SemiBold}
                containerStyle={{marginTop: 12}}
              />
            </div>
          )}
          <DocumentsList
            onDocumentClick={(doc) => {
              navigateToDocumentReview(doc.docId);
            }}
            onUpload={onUploadFiles}
          />
        </div>
        <div style={{display: 'flex', flex: 0.4}}>
          <ActionRequired
            title="Comments"
            types={[CreateQueryDrawerTab.DOCUMENT]}
          />
        </div>
      </div>
      {!isDocumentReviewDone ? (
        <div
          style={{
            display: 'flex',
            height: BOTTOM_PRIMARY_ACTION_HEIGHT,
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingInline: 40,
          }}>
          <Text
            type={TEXT_TYPES.BASE}
            text={
              'Once you have reviewed all the documents you will be able to proceed to next section'
            }
          />
          <DSButton
            type="primary"
            onClick={() => {
              markStepComplete(TAX_FILING_STATUS.TAX_DOCS_REVIEWED);
            }}
            disabled={
              !loaded ||
              documents.some(
                (doc) =>
                  doc.docStatus === VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW,
              ) ||
              isLoading
            }
            text="Mark as complete"
            style={{paddingInline: 100, paddingBlock: 20}}
            textType={TEXT_TYPES.BASE}
            textFontWeight={FONT_WEIGHTS.SemiBold}
          />
        </div>
      ) : (
        <CompletedRibbon
          height={BOTTOM_PRIMARY_ACTION_HEIGHT}
          text="Document review is complete"
        />
      )}
    </>
  );
};

export default PartnerReturnDocumentList;
