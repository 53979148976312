import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {updateReturnStatus} from 'src/appApi';
import DSButton from 'src/DesignSystem/Button/Button';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import ActionRequired from '../common/ActionRequired/ActionRequired';
import useCurrentUserId from '../hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCpaCenterV2Navigation from '../hooks/useCpaCenterV2Navigation';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import useLayout from '../hooks/useLayout';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {Integrator} from './components/CCHAndDrake.types';
import CCHAndDrake from './components/CCHAndDrake';

const SentToCCH = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const [integrator, setIntegrator] = useState<Integrator>(Integrator.DRAKE);
  const {taxReturns} = useSelector(selectTaxReturnsReducer);

  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  const {DOCUMENT_BOTTOM_BAR_HEIGHT, spaceLeftBelowTaxFilingSteps} =
    useLayout();

  const returnId = taxReturns.find(
    (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
  )?.return_id;
  const returnType = taxReturns.find(
    (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
  )?.return_type;

  const dispatch = useDispatch();

  const onCompleteCCHStep = async () => {
    if (returnId && returnType) {
      await updateReturnStatus(
        {tax_filing_id: returnId},
        {
          return_type: returnType,
          cpa_facing_ret_status: TAX_FILING_STATUS.DETAILS_SENT_TO_CCH,
        },
      );
      await dispatch(fetchTaxReturns(userId, activeYear));
      navigateToTaxReturnDetailedView(
        returnId,
        returnType,
        TaxReturnStatus.ADD_RETURN,
      );
    }
  };

  return (
    <>
      <div
        style={{
          height: spaceLeftBelowTaxFilingSteps - 80,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            height:
              spaceLeftBelowTaxFilingSteps - DOCUMENT_BOTTOM_BAR_HEIGHT - 80,
          }}>
          <div
            style={{
              marginLeft: 8,
              width: '60%',
              marginTop: 12,
            }}>
            <CCHAndDrake
              integrator={integrator}
              setIntegrator={setIntegrator}
            />
          </div>
          <div style={{overflowY: 'auto', width: '35%'}}>
            <ActionRequired
              types={[
                CreateQueryDrawerTab.DOCUMENT,
                CreateQueryDrawerTab.TAX_PROFILE,
                CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
                CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
              ]}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: DOCUMENT_BOTTOM_BAR_HEIGHT,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 40px',
            borderWidth: 1,
            background: '#F5F6F8',
            borderColor: '#E1E1E1',
          }}>
          <Typography style={{fontSize: 14, fontWeight: 400, lineHeight: 24}}>
            {integrator === Integrator.DRAKE
              ? 'Download drake file once you have clarified all the queries with the user'
              : 'Send all the data to CCH once you have clarified all the queries with the user'}
          </Typography>
          <DSButton
            type="primary"
            text="Next"
            onClick={onCompleteCCHStep}
            disabled={false}
            style={{paddingInline: 80, paddingBlock: 10}}
          />
        </div>
      </div>
    </>
  );
};

export default SentToCCH;
