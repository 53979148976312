import React, {useState, useRef, useEffect} from 'react';
import {OCRBox} from './OCR.types';
import DSButton from '../Button/Button';
import {ZoomIn, ZoomOut} from '@mui/icons-material';
import Text from '../Form/common/Text/Text';
import {TEXT_TYPES} from '../Form/common/Text/Text.types';

interface ImageWithOCRBoxesProps {
  height?: number;
  imageUrl: string;
  ocrData?: OCRBox[];
}

const delta = 0.02;
const zoomDelta = 10; // Percentage change for zoom in/out

const ImageWithOCRBoxes = ({
  height = 300,
  imageUrl,
  ocrData = [],
}: ImageWithOCRBoxesProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [widthPercentage, setWidthPercentage] = useState(100);

  const updateImageDimensions = () => {
    if (imgRef.current) {
      setImageDimensions({
        width: imgRef.current.offsetWidth || 0,
        height: imgRef.current.offsetHeight || 0,
      });
    }
  };

  useEffect(() => {
    if (imgRef.current) {
      const handleImageLoad = () => {
        setImageDimensions({
          width: imgRef.current?.offsetWidth || 0,
          height: imgRef.current?.offsetHeight || 0,
        });
      };

      const imgElement = imgRef.current;
      imgElement.addEventListener('load', handleImageLoad);

      // Cleanup
      return () => imgElement.removeEventListener('load', handleImageLoad);
    }
  }, [imgRef]);

  useEffect(() => {
    // Update dimensions on window resize
    window.addEventListener('resize', updateImageDimensions);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', updateImageDimensions);
  }, []);

  const onZoomOut = () => {
    setWidthPercentage((prev) => Math.max(prev - zoomDelta, 10));
  };

  const onZoomIn = () => {
    setWidthPercentage((prev) => Math.min(prev + zoomDelta, 500));
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div onClick={onZoomOut}>
          <ZoomOut style={{height: 24, width: 24}} />
        </div>
        <Text
          containerStyle={{marginInline: 8, width: 50, textAlign: 'center'}}
          type={TEXT_TYPES.S}
          text={`${widthPercentage}%`}
        />
        <div onClick={onZoomIn}>
          <ZoomIn style={{height: 24, width: 24}} />
        </div>
      </div>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'auto',
        }}>
        <div
          style={{
            position: 'relative',
            width: `${widthPercentage}%`,
            height,
          }}>
          <img
            ref={imgRef}
            src={imageUrl}
            alt="Document Preview"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}>
            {ocrData.map(({Top, Left, Height, Width, pageNumber}, index) => {
              const actualTop = Top * imageDimensions.height;
              const newTop = actualTop * (1 - delta);
              const heightDifference = actualTop - newTop;
              const actualHeight = Height * imageDimensions.height;
              const newHeight = actualHeight + 2 * heightDifference;

              const actualLeft = Left * imageDimensions.width;
              const newLeft = actualLeft * (1 - delta);
              const leftDifference = actualLeft - newLeft;
              const actualWidth = Width * imageDimensions.width;
              const newWidth = actualWidth + 2 * leftDifference;

              const pct = widthPercentage / 100;
              return (
                <div
                  key={index}
                  style={{
                    position: 'absolute',
                    top: newTop * pct,
                    height: newHeight * pct,
                    left: newLeft * pct,
                    width: newWidth * pct,
                    border: '2px solid red',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                  }}
                  title={`Page: ${pageNumber}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithOCRBoxes;
