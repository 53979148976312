import {Typography} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxProfileSubsection} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import SubSectionBox from '../../common/SubSectionBox';
import TaxFormIncomeCard from './TaxFormIncomeCard/TaxFormIncomeCard';
import {getTotalIncomeForFormType} from './TaxFormIncomeCard/TaxFormIncomeCard.utils';
import Skeleton from 'src/icons/Skeleton';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {
  FORM_1099_MISC_TYPES,
  TaxProfileSubsectionToFormTypeMap,
  mapTaxProfileSectionToIncomeSource,
} from '../Income.constants';
import {ReduxStateType} from 'src/store/store';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {selectTransactionIdMap} from 'src/store/transactions/transaction.selector';
import K1Upload from './K1Upload';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface IncomeTypeCardProps {
  name: string;
  subsection: TaxProfileSubsection;
}

const INCOME_EARNER_AND_BUSINESS_ID_SEPERATOR = '<*|*>';

const IncomeTypeCard = ({name, subsection}: IncomeTypeCardProps) => {
  const {taxForms, loaded} = useSelector(selectTaxFormsReducer);
  const {activeYear} = useActiveYear();
  const userAddedIncomes =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.TAX_PROFILE_INCOME_SOURCES,
        activeYear,
      ),
    ) ?? [];
  const transactionIdMap = useSelector(selectTransactionIdMap);

  const formTypes = TaxProfileSubsectionToFormTypeMap[subsection];

  const incomeForms = taxForms.filter((tf) => {
    if (
      subsection === TaxProfileSubsection.Freelance &&
      tf.formType === TaxFormType.FORM_1099_MISC
    ) {
      return (
        tf.formData[TaxFormFieldIds.INCOME_TYPE] !==
        FORM_1099_MISC_TYPES.RENTAL
      );
    }
    return formTypes.includes(tf.formType);
  });

  const {businesses} = useSelector(selectBusinessDetails);

  const totalIncome = incomeForms
    .map((tf) => {
      const areTransactionsLinked =
        (tf.formData[TaxFormFieldIds.TXN_IDS] ?? []).length !== 0;
      if (areTransactionsLinked) {
        return tf.formData[TaxFormFieldIds.TXN_IDS].reduce((acc, txnId) => {
          return acc + Math.abs(transactionIdMap[txnId]?.currentAmount ?? 0);
        }, 0);
      } else {
        return getTotalIncomeForFormType(tf).amount;
      }
    })
    .reduce((acc, curr) => acc + curr, 0);

  const groupedIncomeForms = _.groupBy(
    incomeForms,
    (tf) =>
      `${
        tf.formData[TaxFormFieldIds.INCOME_EARNER]
      }${INCOME_EARNER_AND_BUSINESS_ID_SEPERATOR}${
        tf.formData[TaxFormFieldIds.BUSINESS_ID]
      }`,
  );

  const getFormattedGroupName = (rawGroupName: string) => {
    const [incomeEarner, businessId] = rawGroupName.split(
      INCOME_EARNER_AND_BUSINESS_ID_SEPERATOR,
    );
    const relatedBusiness = businesses.find(
      (business) =>
        typeof businessId === 'string' &&
        businessId !== 'null' &&
        business[BusinessDetailsField.id] === parseInt(businessId, 10),
    );
    if (relatedBusiness && incomeEarner !== 'null') {
      // has both
      return `Income earner: ${incomeEarner} & Business name: ${relatedBusiness.name}`;
    }
    if (relatedBusiness) {
      // has only business
      return `Business name: ${relatedBusiness.name} & Income earner not specified`;
    }
    if (incomeEarner !== 'null') {
      // has only income earner
      return `Income earner: ${incomeEarner} & Business not specified`;
    }
    // no income and no business
    return 'Neither income earner nor business specified';
  };

  const sectionNames = Object.keys(groupedIncomeForms).sort();

  const renderContent = () => {
    return sectionNames.map((section) => {
      return (
        <div key={section}>
          <Typography style={{fontWeight: 600}}>
            {getFormattedGroupName(section)}
          </Typography>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {groupedIncomeForms[section].map((tf) => (
              <TaxFormIncomeCard key={tf.taxFormId} taxform={tf} />
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <SubSectionBox
      name={`${name} (${CurrencyNumberFormat(totalIncome)})`}
      querieNotAllowed={
        !userAddedIncomes.includes(
          mapTaxProfileSectionToIncomeSource[subsection] ?? '',
        )
      }
      taxprofileSubsection={subsection}
      customSectionActions={
        subsection === TaxProfileSubsection.K1_INCOME ? <K1Upload /> : null
      }>
      {!loaded ? <Skeleton height={100} width={'100%'} /> : renderContent()}
    </SubSectionBox>
  );
};

export default IncomeTypeCard;
