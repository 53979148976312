import React, {ComponentProps, useEffect, useState} from 'react';
import {InputWrapperBaseType} from '../../common/components/InputWrapper/InputWrapper';
import CheckField from '../../common/CheckField/CheckField';
import RadioField from '../../common/RadioField/RadioField';
import {getProfessions} from 'src/appApi';
import {CircularProgress} from '@mui/material';

interface SingleProfessionProps {
  isSingle: true;
  value: string;
}

interface MultiProfessionProps {
  isSingle: false;
  value: string[];
}

type ConditionalProps = SingleProfessionProps | MultiProfessionProps;

type ProfessionProps = ConditionalProps &
  InputWrapperBaseType & {
    onChangeValue: (newValue: any) => void;
  };

const ProfessionField = ({title, onChangeValue, ...rest}: ProfessionProps) => {
  const [options, setOptions] = useState<
    ComponentProps<typeof CheckField>['options']
  >([]);

  const fetchProfesstions = async () => {
    const response = await getProfessions();
    const selectedProfessions = Array.isArray(rest.value)
      ? rest.value
      : typeof rest.value === 'string'
      ? [rest.value]
      : [];
    const allOptions: typeof options = [];
    const professionMap: {[key: string]: true} = {};
    response.data.professions.forEach((profession) => {
      allOptions.push({label: profession.title, value: profession.title});
      professionMap[profession.title] = true;
    });
    selectedProfessions.forEach((profession) => {
      if (!professionMap[profession]) {
        allOptions.push({label: profession, value: profession});
      }
    });
    setOptions(allOptions);
  };

  useEffect(() => {
    fetchProfesstions();
  }, []);

  if (options.length === 0) {
    return <CircularProgress />;
  }

  return (
    <div>
      {!rest.isSingle ? (
        <CheckField
          title={title}
          onChangeValue={onChangeValue}
          value={rest.value}
          options={options}
          validators={rest.validators}
          transformers={rest.transformers}
          disableValidation={rest.disableValidation}
        />
      ) : (
        <RadioField
          title={title}
          onChangeValue={onChangeValue}
          value={rest.value}
          options={options}
          validators={rest.validators}
          transformers={rest.transformers}
          disableValidation={rest.disableValidation}
        />
      )}
    </div>
  );
};

export default ProfessionField;
