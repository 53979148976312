import React, {useEffect, useMemo, useState} from 'react';
import {useCreateReturn} from '../useCreateReturn';
import DSButton from 'src/DesignSystem/Button/Button';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import PendingDocument from './PendingDocument';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {PartnershipReturnFields} from 'src/appApi.types';
import _ from 'lodash';
import {
  CREATE_RETURN_STATUS,
  DocumentAssignmentStatus,
  ReturnFields,
} from '../CreateReturn.types';
import {
  getUsersToAssignDoc,
  tranformPendingDocsDataToFormState,
  transformDocAssignStateToBackend,
} from '../CreateReturn.utils';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {MultipleDocumentAssignmentConfig} from '../CreateReturn.constants';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {assignDocsToUser} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

const AssignDocument = () => {
  const {createdReturns, pendingDocs, setStatus, status} = useCreateReturn();
  const isLoading = status === CREATE_RETURN_STATUS.ASSIGNING_DOCUMENTS;

  const {notify} = useNotify();

  const users = useMemo(() => {
    return getUsersToAssignDoc(createdReturns);
  }, [createdReturns]);

  const userIdMap = useMemo(() => {
    return _.keyBy(users, PartnershipReturnFields.fly_user_id);
  }, [users]);

  const docIdMap = useMemo(() => {
    return _.keyBy(pendingDocs, ReturnFields.ocr_job_id);
  }, [pendingDocs]);

  const [docs, setDocs] = useState<
    ReturnType<typeof tranformPendingDocsDataToFormState>
  >({
    [ReturnFields.documents]: [],
  });

  useEffect(() => {
    setDocs(tranformPendingDocsDataToFormState(pendingDocs, users));
  }, [pendingDocs, users]);

  const {fields, areAllFieldsValid} = useFormData({
    data: docs,
    setData: setDocs,
    config: MultipleDocumentAssignmentConfig,
  });

  const areAllDocsReviewedOrAssigned = useMemo(() => {
    return docs[ReturnFields.documents].every((d) => {
      return [
        DocumentAssignmentStatus.ASSIGNED,
        DocumentAssignmentStatus.REVIEWED,
      ].includes(d[ReturnFields.document_assignment_status]);
    });
  }, [docs]);

  const onDoneAssigning = async () => {
    try {
      setStatus(CREATE_RETURN_STATUS.ASSIGNING_DOCUMENTS);
      const assignedDoc = transformDocAssignStateToBackend(
        docs[ReturnFields.documents],
      );
      await assignDocsToUser(assignedDoc);
      setStatus(CREATE_RETURN_STATUS.RETURNS_CREATED);
      notify('Document assigned successfully', NotificationType.success);
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
    }
  };

  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}>
        {fields.map((field) => {
          if (field.inputType === FORM_INPUT_TYPE.Array) {
            return field.value.map((docState, index) => {
              return (
                <PendingDocument
                  key={docState[ReturnFields.ocr_job_id]}
                  users={users}
                  doc={docIdMap[docState[ReturnFields.ocr_job_id]]}
                  valueArray={field.value}
                  onChangeValue={field.onChangeValue}
                  arrayObjectIndex={index}
                  userIdMap={userIdMap}
                />
              );
            });
          }
          return null;
        })}
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <DSButton
          type="primary"
          onClick={onDoneAssigning}
          text="Done Assigning"
          style={{paddingBlock: 12, paddingInline: 60}}
          textType={TEXT_TYPES.BASE}
          disabled={
            !areAllFieldsValid || !areAllDocsReviewedOrAssigned || isLoading
          }
        />
      </div>
    </>
  );
};

export default AssignDocument;
