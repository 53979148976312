import {get} from 'lodash';
import {TaxProfileQuestion} from './taxProfile.types';
import {ReduxStateType} from '../store';

export const selectTaxProfileAnswer: (
  state: ReduxStateType,
  questionId: TaxProfileQuestion,
  year: number,
) => any = (state, questionId, year) => {
  return get(
    state.taxProfile[year]?.find((item) => item.question_id === questionId),
    'answer',
    undefined,
  );
};

/**
 * @deprecated The method should not be used use selectTaxProfileAnswer
 */
export const selectTaxProfileAnswerForGivenYear: (
  state: ReduxStateType,
  questionId: TaxProfileQuestion,
  year: number,
) => any = (state, questionId, year) => {
  return get(
    state.taxProfile[year].find((item) => item.question_id === questionId),
    'answer',
    undefined,
  );
};

export const selectTaxProfile = (state: ReduxStateType) => {
  return state.taxProfile;
};
