import React from 'react';
import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {
  QuarterlyPaymentType,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {selectTaxForms} from 'src/store/taxForms/taxForms.selector';
import {selectTransactionIdMap} from 'src/store/transactions/transaction.selector';

const PaymentsTable = () => {
  const taxForms = useSelector(selectTaxForms);
  const transactionIdMap = useSelector(selectTransactionIdMap);
  const {businesses} = useSelector(selectBusinessDetails);

  const taxPaymentForms = taxForms.filter(
    (taxForm) => taxForm.formType === TaxFormType.QUARTERLY_TAX_PAYMENTS,
  );

  const getTxnId = (rowData: GenericTaxForm) =>
    rowData.formData[TaxFormFieldIds.TXN_ID];

  const getPaymentType = (rowData: GenericTaxForm) => {
    let paymentType = rowData.formData[TaxFormFieldIds.PAYMENT_TYPE];
    const state = rowData.formData[TaxFormFieldIds.STATE];
    const bId = rowData.formData[TaxFormFieldIds.BUSINESS_ID];
    const business = businesses.find(
      (business) => business[BusinessDetailsField.id] === bId,
    );
    if (paymentType === QuarterlyPaymentType.STATE) {
      paymentType = `${paymentType}(${state})`;
    } else if (
      [
        QuarterlyPaymentType.BUSINESS_FEDERAL,
        QuarterlyPaymentType.BUSINESS_STATE,
        QuarterlyPaymentType.BUSINESS_CITY,
      ].includes(paymentType)
    ) {
      paymentType = `${paymentType} - ${business?.name}`;
    }
    return paymentType;
  };

  const getAmount = (rowData: GenericTaxForm) => {
    let amount: any = false;
    let isFromTxns = false;
    const txnId = getTxnId(rowData);
    if (!txnId) {
      amount = rowData.formData[TaxFormFieldIds.AMOUNT];
    } else {
      amount = transactionIdMap[txnId]?.currentAmount;
      isFromTxns = true;
    }
    if (typeof amount !== 'number') {
      return amount;
    }
    let amountString = CurrencyNumberFormat(amount);
    if (isFromTxns) {
      amountString = `${amountString}(T)`;
    }
    return amountString;
  };

  const getPaymentDate = (rowData: GenericTaxForm) => {
    const txnId = getTxnId(rowData);
    if (!txnId) {
      return rowData.formData[TaxFormFieldIds.PAYMENT_DATE];
    }
    return transactionIdMap[txnId]?.txnDate;
  };

  const keys = [
    {
      getValue: getPaymentType,
    },

    {
      getValue: getPaymentDate,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      getValue: getAmount,
      textStyle: {
        fontWeight: 600,
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ];

  return <KeyValueTable data={taxPaymentForms} keys={keys} />;
};

export default PaymentsTable;
