import React from 'react';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import SubSectionBox from '../../common/SubSectionBox';
import CommonDeductionTable from './CommonDeductionTable';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {CONTRIBUTION_TYPE} from '../Deductions.constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const HSAContributions = () => {
  const {documents} = useSelector(selectDocuments);
  const {loaded: taxFormsLoaded, taxForms} = useSelector(
    selectTaxFormsReducer,
  );
  const {activeYear} = useActiveYear();

  const hsaForms = taxFormsLoaded
    ? taxForms.filter(
        (form) =>
          form.formType === TaxFormType.FORM_SA_5498 &&
          form.formData[TaxFormFieldIds.ContributionType] ===
            CONTRIBUTION_TYPE.HSA,
      )
    : [];

  const hsaDocs = documents.filter((doc) =>
    hsaForms.find((form) => form.docId === doc.docId),
  );

  const isClaimedHSA = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.CLAIMED_HSA_CONTRIBUTIONS,
      activeYear,
    ),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  return (
    <SubSectionBox
      name="HSA contributions"
      taxprofileSubsection={TaxProfileSubsection.HSAContributions}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField name="Has claimed HSA?" value={isClaimedHSA} />
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.black60,
              marginBottom: 4,
            }}>
            Related document
          </Typography>
          {hsaDocs.map((doc) => (
            <DocumentPointer doc={doc} border />
          ))}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.HSAContributions}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default HSAContributions;
