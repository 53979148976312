import {STATES} from 'src/constants/constants';
import {SCHOOL_DISTRICT_STATES} from './TaxProfileSections.constants';

export const stateAbbreviationNameMap: {[key: string]: string} = STATES.reduce(
  (result, stateItem) => ({
    ...result,
    [stateItem.abbreviation]: stateItem.name,
  }),
  {},
);

export const stateNameToAbbreviationMap: {[key: string]: string} =
  STATES.reduce(
    (result, stateItem) => ({
      ...result,
      [stateItem.name]: stateItem.abbreviation,
    }),
    {},
  );

export const isSchoolDistrictCodeRequired = (state: string) => {
  return SCHOOL_DISTRICT_STATES.includes(
    stateAbbreviationNameMap[state] ?? state,
  );
};
