import {cloneDeep} from 'lodash';
import {SET_USER_INFO} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {TAX_EXTENSION_STATUS} from 'src/constants/constants';

export enum USER_COMMS_MODE {
  APP = 'APP',
  EMAIL = 'EMAIL',
}

export enum ESIGN_MODE {
  APP = 'APP',
  EMAIL = 'EMAIL',
}

export enum TF_SEGMENT {
  NO_REPLY = 'NO_REPLY',
  GOLDEN_SET = 'GOLDEN_SET',
  RECENT = 'RECENT',
  REST = 'REST',
}

const initialState = {
  userId: -1,
  userName: '',
  email: '',
  cchClientId: -1,
  cpaFacingRetStatus: TAX_FILING_STATUS.NOT_INITIATED,
  taxFilingId: -1,
  lastStatusChange: '',
  notes: '',
  nextActionDate: '',
  userFilingStatus: '',
  year: -1,
  status: REDUX_STATUS.INIT,
  loaded: false,
  comms_mode: '',
  esign_mode: '',
  tax_extension_status: null as TAX_EXTENSION_STATUS | null,
  dateOfDebit: '',
  taxFilingPlanName: '',
  tfSegment: null as TF_SEGMENT | null,
  partnerName: '',
};

export const user = (
  state = cloneDeep(initialState),
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
        status: REDUX_STATUS.SUCCESS,
        loaded: true,
      };
  }
  return state;
};

export type UserStateType = typeof initialState;
