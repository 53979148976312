import {differenceInYears, parseISO} from 'date-fns';
import {get} from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {INCOME_FORMS} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import useDeductions from 'src/CpaCenterV2/hooks/useDeductions';
import {getTotalIncomeForFormType} from 'src/CpaCenterV2/TaxProfileSections/Income/components/TaxFormIncomeCard/TaxFormIncomeCard.utils';
import {TaxFormTypeToPayerNameMap} from 'src/CpaCenterV2/TaxProfileSections/Income/Income.constants';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {selectTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.selector';

function calculateAge(dob: Date) {
  // const date = parse(dob, "dd/MM/yyyy", new Date());
  const age = differenceInYears(new Date(), dob);
  return age;
}

const useTaxProfileSummary = () => {
  const {activeYear} = useActiveYear();
  const taxProfileName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.LEGAL_NAME, activeYear),
  );
  const filingStatus =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.FILING_STATUS,
        activeYear,
      ),
    ) ?? '';

  const skipLastYear1040Reason =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.SKIP_LAST_YEAR_1040_REASON,
        activeYear,
      ),
    ) ?? '';

  const {businesses} = useSelector(selectBusinessDetails);

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const {statesLivedInfo} = useSelector(selectTaxProfileStatesInfo);

  const fullName = `${get(
    taxProfileName,
    TaxProfileField.FIRST_NAME,
    '',
  )} ${get(taxProfileName, TaxProfileField.LAST_NAME, '')}`;

  const dependentDetails: Array<{name: string; age: number}> = useMemo(() => {
    return taxForms
      .filter((tf) => tf.formType === TaxFormType.DEPENDENT)
      .map(({formData}) => {
        const fName = formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME];
        const lName = formData[TaxFormFieldIds.DEPENDENT_LAST_NAME];
        const age = calculateAge(
          parseISO(formData[TaxFormFieldIds.DEPENDENT_BIRTHDATE]),
        );
        const name = `${fName} ${lName}`;
        return {
          name,
          age,
        };
      });
  }, [taxForms]);

  const incomeSummary: {
    type: string;
    payerName: string;
    amount: number;
  }[] = taxForms
    .filter((tf) => INCOME_FORMS.includes(tf.formType))
    .map((tf) => {
      const type = tf.formType;
      const {amount} = getTotalIncomeForFormType(tf);
      const payerName = tf.formData[TaxFormTypeToPayerNameMap[type]];
      return {
        // type: FormTypeToTaxProfileSubsection[type],
        type,
        amount,
        payerName,
      };
    });

  const livingStates = statesLivedInfo.map((stateInfo) =>
    stateInfo.is_current_address
      ? `${stateInfo.state} (Present address)`
      : stateInfo.state,
  );

  const earningStates = statesLivedInfo
    .filter((stateInfo) => stateInfo.related_income_forms.length > 0)
    .map((stateInfo) => stateInfo.state);

  const {getApplicableDeductions, getFields} = useDeductions();

  return {
    fullName,
    filingStatus,
    livingStates,
    earningStates,
    dependentDetails,
    businesses,
    incomeSummary,
    getApplicableDeductions,
    getFields,
    skipLastYear1040Reason,
  };
};

export default useTaxProfileSummary;
