import React, {useMemo} from 'react';
import TaxReturnFieldGridWrapper from './TaxReturnFieldGridWrapper';
import BasicTaxReturnFields from './BasicTaxReturnFields';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import ReturnContainerCard from './ReturnContainerCard';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {ReturnFields, UserRelationType} from '../CreateReturn.types';
import IndividualTaxReturnFields from './IndividualTaxReturnFields';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import BusinessTaxReturnField from './BusinessTaxReturnField';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import _ from 'lodash';
import {Form} from 'src/DesignSystem/Form/Form';
import DSButton from 'src/DesignSystem/Button/Button';
import {Add, Warning} from '@mui/icons-material';
import {useCreateReturn} from '../useCreateReturn';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import InlineNotification from 'src/DesignSystem/InlineNotification/InlineNotification';
import {InlineNotificationType} from 'src/DesignSystem/InlineNotification/InlineNotification.types';
import {themmeColor} from 'src/constants/constants';

const UserCard = ({
  fields,
  onAddBusiness,
  user,
  validationDisabled,
}: {
  fields: ReturnType<typeof useFormData>['fields'];
  onAddBusiness: () => void;
  user: UserRelationType | null;
  validationDisabled: boolean;
}) => {
  const {existingUsersData, setSelectedSsn} = useCreateReturn();
  const fieldsByPath = useMemo(() => _.keyBy(fields, 'path'), [fields]);
  const ssn = fieldsByPath[TaxProfileField.SSN].value;

  const duplicateUsers = existingUsersData[ssn];

  const bizsField = fieldsByPath[ReturnFields.businesses];

  return (
    <>
      {Array.isArray(duplicateUsers) && duplicateUsers.length > 0 && (
        <div style={{marginBottom: 12}}>
          <InlineNotification
            icon={<Warning style={{color: themmeColor.white}} />}
            text="User profile exists with this SSN."
            variant={InlineNotificationType.error}
            content={
              <div
                style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                <DSButton
                  type="secondary"
                  text="View"
                  onClick={() => {
                    setSelectedSsn(ssn);
                  }}
                  textColor={themmeColor.white}
                  borderColor={themmeColor.white}
                />
              </div>
            }
          />
        </div>
      )}
      <TaxReturnFieldGridWrapper>
        <BasicTaxReturnFields
          fields={fields}
          validationDisabled={validationDisabled}
        />
      </TaxReturnFieldGridWrapper>
      <Text
        type={TEXT_TYPES.BASE}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={'Returns'}
        containerStyle={{marginTop: 20, marginBottom: 8}}
      />
      <ReturnContainerCard
        tagName="Individual Return"
        switchComponent={
          <Form
            {...fieldsByPath[ReturnFields.should_file_individual_return]}
          />
        }>
        <IndividualTaxReturnFields
          fieldsDictionary={fieldsByPath}
          user={user}
          validationDisabled={validationDisabled}
        />
      </ReturnContainerCard>

      {bizsField.inputType === FORM_INPUT_TYPE.Array ? (
        <div>
          {bizsField.value.map((_, arrayObjectIndex) => {
            return (
              <div style={{marginTop: 20}}>
                <BusinessTaxReturnField
                  key={
                    bizsField.value[arrayObjectIndex][ReturnFields.unique_key]
                  }
                  childProps={bizsField.childProps}
                  arrayObjectIndex={arrayObjectIndex}
                  onChangeValue={bizsField.onChangeValue}
                  array={bizsField.value}
                  user={user}
                  validationDisabled={validationDisabled}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      <div>
        <DSButton
          type="secondary"
          text="Add business return"
          onClick={onAddBusiness}
          startIcon={<Add />}
          style={{marginTop: 12}}
        />
      </div>
    </>
  );
};

export default UserCard;
