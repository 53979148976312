import React, {ComponentProps, useEffect} from 'react';
import {Divider, Typography} from '@mui/material';
import {StatesInfo} from 'src/appApi.types';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import SubSectionBox from '../../common/SubSectionBox';
import {useDispatch, useSelector} from 'react-redux';
import {selectDocuments} from 'src/store/documents/documents.selector';
import StatesWithIncome from './StatesWithIncome';
import StatesTaxFilingCategory from './StatesTaxFilingCategory';
import {selectTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.selector';
import {fetchTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.actions';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import DrivingLicense from './DrivingLicense';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import StatesLived from './StatesLived';

const StatesTaxFilingInformation = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const {loaded, documents} = useSelector(selectDocuments);
  const {
    loaded: statesInfoLoaded,
    statesLivedInfo: statesTaxesInfo,
    businessStateReturnInfo,
    isDrivingLicenseRequired,
  } = useSelector(selectTaxProfileStatesInfo);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const isLoading = !statesInfoLoaded || !loaded;

  useEffect(() => {
    dispatch(fetchTaxProfileStatesInfo(userId, activeYear));
  }, [userId, activeYear]);

  const statesWithIncomeDocs = isLoading
    ? []
    : statesTaxesInfo.filter((state) => state.related_income_forms.length > 0);

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const drivingLicenseTaxForms = taxForms.filter(
    (tf) => tf.formType === TaxFormType.DRIVING_LICENSE,
  );

  const selfDLTaxform = drivingLicenseTaxForms.find(
    (tf) => !tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );
  const spouseDLTaxform = drivingLicenseTaxForms.find(
    (tf) => tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );

  const getState = (row: StatesInfo) => {
    const {is_current_address, state} = row;
    if (is_current_address) {
      return `${state} (Present address)`;
    }
    return state;
  };

  return (
    <SubSectionBox
      name="State tax filing information"
      taxprofileSubsection={TaxProfileSubsection.StatesTaxFilingInformation}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }
        }>
        <StatesLived />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}>
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {flex: 0.48}
            }>
            <Typography
              style={{
                fontSize: 13,
                color: themmeColor.black60,
                marginBottom: 8,
              }}>
              States with income in {activeYear}
            </Typography>
            <StatesWithIncome
              statesWithIncomeDocs={statesWithIncomeDocs}
              isLoading={isLoading}
              documents={documents}
            />
          </div>
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {flex: 0.48}
            }>
            <StatesTaxFilingCategory
              stateReturnsInfo={statesTaxesInfo}
              businessStateReturnInfo={businessStateReturnInfo}
              isLoading={isLoading}
              getState={getState}
            />
          </div>
        </div>
      </div>
      <Divider style={{marginTop: 20}} />
      <div>
        <Typography
          style={{
            color: themmeColor.black60,
            marginTop: 20,
            marginBottom: 8,
            fontWeight: 600,
          }}>
          Driving License Information
        </Typography>
        {isDrivingLicenseRequired && (
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                  }
            }>
            <div
              style={
                summaryVariant ===
                TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                  ? {}
                  : {flex: 0.48}
              }>
              <DrivingLicense taxForm={selfDLTaxform} isSpouse={false} />
            </div>
            <div
              style={
                summaryVariant ===
                TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                  ? {marginTop: 20}
                  : {flex: 0.48}
              }>
              <DrivingLicense taxForm={spouseDLTaxform} isSpouse />
            </div>
          </div>
        )}
      </div>
    </SubSectionBox>
  );
};

export default StatesTaxFilingInformation;
