import {TaxProfileConfig} from './taxProfile.constants';

export const getConfigForTaxProfileQuestions = (pathPrefixes: string[]) => {
  const filteredQuestionsConfig = TaxProfileConfig.filter((field) => {
    const configFieldPathTokens = field.path.split('.');
    const isValid = pathPrefixes.some((path) => {
      const prefixPathTokens = path.split('.');
      return prefixPathTokens.every((prefixPathToken, index) => {
        if (index < configFieldPathTokens.length) {
          const configPathToken = configFieldPathTokens[index];
          return prefixPathToken === configPathToken;
        }
        return false;
      });
    });
    return isValid;
  });

  return filteredQuestionsConfig;
};
