import React, {ComponentProps, useEffect, useState} from 'react';
import {getDocument} from 'src/appApi';

interface ImageProps extends React.ComponentPropsWithoutRef<'img'> {
  filename: string;
  uri: string;
}

const Image = ({uri, filename, ...rest}: ImageProps) => {
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    let ignore = false;
    const fetchDocumentLink = async () => {
      if (uri.includes('get_read_url')) {
        // unsigned url
        const response = await getDocument(uri);
        if (!ignore) {
          setLink(response.data);
        }
        return;
      }
      // signed url
      if (!ignore) {
        setLink(uri);
      }
    };
    fetchDocumentLink();
    return () => {
      ignore = true;
    };
  }, [uri]);
  if (link === null) {
    return null;
  }
  return <img src={link} alt={filename} {...rest} />;
};

export default Image;
