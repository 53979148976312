import React, {ReactNode, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import Box from 'src/DesignSystem/Box/Box';
import {Form} from 'src/DesignSystem/Form/Form';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {patchTaxForm} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {
  BOOLEAN_OPTIONS,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import {usePartnerCredits} from './usePartnerCredits';
import Skeleton from 'src/icons/Skeleton';
import {PartnerCreditsSubsectionConfig} from '../PartnerCredits.constants';

interface PartnerCreditsContentProp {
  title: string;
  subsection:
    | TaxProfileSubsection.DependentCredits
    | TaxProfileSubsection.EducationalCredits
    | TaxProfileSubsection.AdoptionCredits;
  children?: ReactNode | ReactNode[];
}

const PartnerCreditsContent = ({
  title,
  subsection,
  children,
}: PartnerCreditsContentProp) => {
  const field = PartnerCreditsSubsectionConfig[subsection].field;
  const creditName = PartnerCreditsSubsectionConfig[subsection].creditName;
  const {dependents, isLoading, onChange, taxFormsLoaded} =
    usePartnerCredits(field);

  return (
    <SubSectionBox name={title} taxprofileSubsection={subsection}>
      <FullScreenLoading open={isLoading} />
      {!taxFormsLoaded ? (
        <Skeleton width={'100%'} height={300} />
      ) : (
        <>
          {children}
          {dependents.map((dep) => (
            <Box
              style={{
                marginBottom: 16,
              }}>
              <Form.RadioField
                title={`${
                  dep.formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME]
                } (${
                  dep.formData[TaxFormFieldIds.DEPENDENT_RELATION]
                }) eligible for ${creditName} credits?`}
                options={BOOLEAN_OPTIONS}
                value={dep.formData[field]}
                onChangeValue={(newValue) => onChange(dep.taxFormId, newValue)}
                validators={[VALIDATOR.NON_NULL_VALUE]}
              />
            </Box>
          ))}
        </>
      )}
    </SubSectionBox>
  );
};

export default PartnerCreditsContent;
