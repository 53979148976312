import React, {ComponentProps, useCallback, useMemo, useState} from 'react';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import {
  BOOLEAN_OPTIONS,
  TaxProfileSection,
  TaxProfileSubsection,
} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {
  CreateQueryDrawerTab,
  NotificationType,
} from 'src/store/app/app.reducer';
import PartnerCreditsContent from './components/PartnerCreditsContent';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {
  FILING_STATUS_ANSWER,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';
import {Form} from 'src/DesignSystem/Form/Form';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {bulkSaveTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';

const PartnerCredits = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const selfEducationCredits = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
      activeYear,
    ),
  );
  const spouseEducationCredits = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT,
      activeYear,
    ),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const onChangeTaxProfileAnswer = useCallback(
    async (
      qId:
        | TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT
        | TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT,
      newValue: any,
    ) => {
      try {
        setIsLoading(true);
        await dispatch(
          bulkSaveTaxProfileQuestions(
            userId,
            [{question_id: qId, answer: newValue}],
            activeYear,
          ),
        );
        notify('Updated', NotificationType.success);
      } catch (e) {
        notify(`Error ${e}`, NotificationType.error);
      } finally {
        setIsLoading(false);
      }
    },
    [userId, activeYear],
  );

  const fields = useMemo(() => {
    const _fields: ComponentProps<typeof Form.RadioField>[] = [
      {
        title: `Self eligible for education credits?`,
        value: selfEducationCredits,
        options: BOOLEAN_OPTIONS,
        validators: [VALIDATOR.NON_NULL_VALUE],
        onChangeValue: (newValue) =>
          onChangeTaxProfileAnswer(
            TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
            newValue,
          ),
      },
    ];
    if (filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) {
      _fields.push({
        title: `Spouse eligible for education credits?`,
        value: spouseEducationCredits,
        options: BOOLEAN_OPTIONS,
        validators: [VALIDATOR.NON_NULL_VALUE],
        onChangeValue: (newValue) =>
          onChangeTaxProfileAnswer(
            TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT,
            newValue,
          ),
      });
    }
    return _fields;
  }, [
    filingStatus,
    selfEducationCredits,
    spouseEducationCredits,
    onChangeTaxProfileAnswer,
  ]);

  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          sectionName="Credits"
          section={TaxProfileSection.Credits}
        />
        <FullScreenLoading open={isLoading} />
        <div
          style={{
            overflowY: 'auto',
          }}>
          <PartnerCreditsContent
            title="Dependents credits"
            subsection={TaxProfileSubsection.DependentCredits}
          />
          <PartnerCreditsContent
            title="Education credits"
            subsection={TaxProfileSubsection.EducationalCredits}>
            {fields.map((field) => (
              <div style={{marginBottom: 12}}>
                <Form.RadioField {...field} />
              </div>
            ))}
          </PartnerCreditsContent>
          <PartnerCreditsContent
            title="Adoption credits"
            subsection={TaxProfileSubsection.AdoptionCredits}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.25,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <ActionRequired
          title="Comments"
          dontAllowAdd
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          taxProfileSection={TaxProfileSection.StateTaxes}
        />
      </div>
    </div>
  );
};

export default PartnerCredits;
