import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import {
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from '../hooks/useActiveYear';
import {parseFormConfigToFormData} from 'src/DesignSystem/Form/Form.utils';
import {TaxProfileConfig} from 'src/store/taxProfile/taxProfile.constants';
import _, {isObject} from 'lodash';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import {
  getCountyNameAndCodeMapForState,
  getPath,
  isCountyRequired,
} from '../utils';
import {isSchoolDistrictCodeRequired} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {bulkSaveTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {getConfigForTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.utils';
import {overWriteTaxProfileFields} from './EditTaxProfile.utils';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';

type TaxProfileType = {[key in TaxProfileQuestion]?: any};

export const useEditTaxProfile = ({
  pathPrefixes,
}: {
  pathPrefixes: string[];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState<TaxProfileType>({});

  const {activeYear} = useActiveYear();
  const {[activeYear]: taxProfile} = useSelector(selectTaxProfile);

  const {userId} = useCurrentUserId();
  const {notify} = useNotify();

  const dispatch = useDispatch();

  const {taxProfileMap} = useTaxProfile();
  const config = useMemo(() => {
    return getConfigForTaxProfileQuestions(pathPrefixes);
  }, [pathPrefixes]);

  const defaultData = useMemo(() => {
    return parseFormConfigToFormData(config, taxProfileMap);
  }, [config, taxProfileMap]);

  useEffect(() => {
    setAnswers(defaultData);
  }, [defaultData]);

  const {fields, areAllFieldsValid} = useFormData({
    config,
    setData: setAnswers,
    data: answers,
    overWriteFields: overWriteTaxProfileFields,
  });

  const onSaveChanges = async () => {
    try {
      setIsLoading(true);
      const backendAnswerMap = parseFormConfigToFormData(fields, answers);
      const data = Object.entries(backendAnswerMap).map(([key, answer]) => {
        const qId = parseInt(key);
        let ans = answer;
        if (isObject(ans)) {
          ans = {
            // @ts-ignore,
            ...taxProfileMap[qId],
            ...ans,
          };
        }
        return {
          question_id: qId,
          answer: ans,
        };
      });
      await dispatch(bulkSaveTaxProfileQuestions(userId, data, activeYear));
      notify('Updated successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to update tax profile ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    answers,
    setAnswers,
    fields,
    areAllFieldsValid,
    onSaveChanges,
    isLoading,
  };
};
