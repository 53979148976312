import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import {format} from 'date-fns';
import JSONPretty from 'react-json-pretty';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';

interface Props {
  totalTaxAmount: number;
  calculationDate: string;
  federalTaxData: {
    federal_annual_tax: number;
    federal_quarterly_tax: number;
    federal_payments_this_qtr: number;
    calculation_defs: any;
    taxes_paid: any;
    input_data: {
      agi_last_year: number;
      filing_status_last_year: FILING_STATUS_ANSWER;
      total_tax_last_year: number;
    };
  };
  stateTaxData: {
    state_abbreviation: string;
    state_annual_tax: number;
    state_quarterly_tax: number;
    input_data: {
      agi_last_year: number;
      filing_status_last_year: FILING_STATUS_ANSWER;
      total_tax_last_year: number;
    };
    taxes_paid: any;
    zero_tax_state: boolean;
    state_payments_this_qtr: number;
  }[];
}

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const AIQtcInput = ({
  totalTaxAmount,
  calculationDate,
  federalTaxData,
  stateTaxData,
}: Props) => {
  return (
    <Accordion>
      <AccordionSummary
        sx={{backgroundColor: themmeColor.offGrey}}
        expandIcon={<ExpandMore />}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <div style={{marginRight: 20}}>
            <Typography style={{fontWeight: '600', fontSize: 20}}>
              Calculated by AI
            </Typography>
            <Typography style={{fontWeight: '400', fontSize: 13}}>
              {`${format(new Date(calculationDate), 'd MMM YYY')}`}
            </Typography>
          </div>
          <Typography style={{fontWeight: '600', fontSize: 16}}>
            {CurrencyNumberFormat(totalTaxAmount)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            padding: 16,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: 20,
            }}>
            <div>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: 13,
                  color: themmeColor.black60,
                }}>
                Federal
              </Typography>
              <Typography
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                }}>
                {CurrencyNumberFormat(federalTaxData.federal_quarterly_tax)}
              </Typography>
            </div>
            {stateTaxData.map((stateData) => {
              return (
                <div>
                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: 13,
                      color: themmeColor.black60,
                    }}>
                    {stateAbbreviationNameMap[
                      stateData.state_abbreviation ?? ''
                    ] ?? ''}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: '600',
                      fontSize: 18,
                    }}>
                    {CurrencyNumberFormat(stateData.state_quarterly_tax ?? 0)}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography
              style={{
                fontWeight: '600',
                fontSize: 16,
                color: themmeColor.black60,
              }}>
              Federal calc breakdown
            </Typography>
            <JSONPretty
              id={`json-pretty-federal`}
              data={federalTaxData}
              theme={JSONPrettyMon}
              style={{fontSize: 12, overflowX: 'scroll', width: 500}}
            />
          </div>
          {stateTaxData.map((stateData) => {
            return (
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography
                  style={{
                    fontWeight: '600',
                    fontSize: 16,
                    color: themmeColor.black60,
                  }}>
                  {`${
                    stateAbbreviationNameMap[
                      stateData.state_abbreviation ?? ''
                    ] ?? ''
                  } calc breakdown`}
                </Typography>
                <JSONPretty
                  id={`json-pretty-state-${
                    stateData.state_abbreviation ?? ''
                  }`}
                  data={stateData}
                  theme={JSONPrettyMon}
                  style={{fontSize: 12, overflowX: 'scroll', width: 500}}
                />
              </div>
            );
          })}
          <Divider style={{marginBottom: 20, marginTop: 20}} />
          <div>
            <Typography style={{fontWeight: '600', fontSize: 18}}>
              Last year return info
            </Typography>
            <div style={{paddingLeft: 20}}>
              <SimpleKeyValueField
                style={{marginTop: 12}}
                name="Filing status"
                value={federalTaxData.input_data?.filing_status_last_year}
              />
              <Divider style={{marginBottom: 20, marginTop: 20}} />
              <div>
                <Typography style={{fontWeight: '600', fontSize: 16}}>
                  Federal last year return info
                </Typography>
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="AGI last year"
                  value={CurrencyNumberFormat(
                    federalTaxData.input_data?.agi_last_year ?? 0,
                  )}
                />
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="Total tax last year"
                  value={CurrencyNumberFormat(
                    federalTaxData.input_data?.total_tax_last_year ?? 0,
                  )}
                />
              </div>
              {stateTaxData.map((stateData) => {
                return (
                  <>
                    <Divider style={{marginBottom: 20, marginTop: 20}} />
                    <div>
                      <Typography style={{fontWeight: '600', fontSize: 16}}>
                        {`${
                          stateAbbreviationNameMap[
                            stateData.state_abbreviation ?? ''
                          ] ?? ''
                        } last year return info`}
                      </Typography>
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name="AGI last year"
                        value={CurrencyNumberFormat(
                          stateData.input_data?.agi_last_year ?? 0,
                        )}
                      />
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name="Total tax last year"
                        value={CurrencyNumberFormat(
                          stateData.input_data?.total_tax_last_year ?? 0,
                        )}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AIQtcInput;
