import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import DrivingLicense from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/DrivingLicense';
import StatesLived from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/StatesLived';
import StatesTaxFilingCategory from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/StatesTaxFilingCategory';
import StatesWithIncome from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/StatesWithIncome';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import DSButton from 'src/DesignSystem/Button/Button';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {StatesInfo} from 'src/appApi.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  GenericTaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import {selectTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.selector';

const StatesSubsection = () => {
  const {activeYear} = useActiveYear();

  const {loaded, documents} = useSelector(selectDocuments);

  const {onAddTaxForm, onEditTaxForm} = usePartnerTaxProfileReview();

  const {
    loaded: statesInfoLoaded,
    statesLivedInfo: statesTaxesInfo,
    businessStateReturnInfo,
    isDrivingLicenseRequired,
  } = useSelector(selectTaxProfileStatesInfo);

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const {navigateToDocumentReview} = usePartnershipNavigation();

  const drivingLicenseTaxForms = taxForms.filter(
    (tf) => tf.formType === TaxFormType.DRIVING_LICENSE,
  );
  const selfDLTaxform = drivingLicenseTaxForms.find(
    (tf) => !tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );
  const spouseDLTaxform = drivingLicenseTaxForms.find(
    (tf) => tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );

  const onClickDL = (tf: GenericTaxForm | undefined) => {
    console.log(tf);
    if (tf === undefined) {
      return;
    }
    if (Number.isInteger(tf.docId)) {
      navigateToDocumentReview(tf.docId);
    } else {
      onEditTaxForm({
        taxFormId: tf.taxFormId,
        taxFormType: tf.formType,
        title: 'Edit Tax form',
      });
    }
  };

  const isLoading = !statesInfoLoaded || !loaded;

  const statesWithIncomeDocs = isLoading
    ? []
    : statesTaxesInfo.filter((state) => state.related_income_forms.length > 0);

  const getState = (row: StatesInfo) => {
    const {is_current_address, state} = row;
    if (is_current_address) {
      return `${state} (Present address)`;
    }
    return state;
  };

  return (
    <SubSectionBox
      name="State tax filing information"
      taxprofileSubsection={TaxProfileSubsection.StatesTaxFilingInformation}>
      <StatesLived allowEdit />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit,minmax(400px,1fr))',
          gap: 20,
          marginTop: 20,
        }}>
        <div>
          <Typography
            style={{
              fontSize: 13,
              color: themmeColor.black60,
              marginBottom: 8,
            }}>
            States with income in {activeYear}
          </Typography>
          <StatesWithIncome
            statesWithIncomeDocs={statesWithIncomeDocs}
            isLoading={isLoading}
            documents={documents}
          />
        </div>
        <StatesTaxFilingCategory
          stateReturnsInfo={statesTaxesInfo}
          businessStateReturnInfo={businessStateReturnInfo}
          isLoading={isLoading}
          getState={getState}
        />
      </div>
      {isDrivingLicenseRequired && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 12,
            }}>
            <Typography
              style={{
                color: themmeColor.black60,
                marginTop: 20,
                marginBottom: 8,
                fontWeight: 600,
              }}>
              Driving License Information
            </Typography>
            <DSButton
              type="secondary"
              text="Add manually"
              onClick={() => {
                onAddTaxForm({
                  taxFormType: TaxFormType.DRIVING_LICENSE,
                  title: 'Add Driving license',
                });
              }}
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit,minmax(400px,1fr))',
              gap: 20,
            }}>
            <DrivingLicense
              taxForm={selfDLTaxform}
              onClickDocument={() => {
                console.log('a');
                onClickDL(selfDLTaxform);
              }}
              isSpouse={false}
            />
            <DrivingLicense
              taxForm={spouseDLTaxform}
              onClickDocument={() => {
                onClickDL(spouseDLTaxform);
              }}
              isSpouse
            />
          </div>
        </>
      )}
    </SubSectionBox>
  );
};

export default StatesSubsection;
