import React, {useMemo, useState} from 'react';
import {getCpaTemplateList, rephraseTextWithGPT} from 'src/appApi';
import {LocalStorageKey, themmeColor} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import AiIcon from 'src/icons/AiIcon';
import SendIcon from 'src/icons/SendIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import RephrasedTextPreview from './RephrasedTextPreview';
import PlanNameTag from './components/PlanNameTag';
import UploadDocumentIcon from 'src/icons/UploadDocumentIcon';
import ReplyIcon from 'src/icons/ReplyIcon';
import MultiDocumentUpload from '../UploadDocument/MultiDocumentUpload';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {TextField, Typography} from '@mui/material';
import CrossIcon from 'src/icons/CrossIcon';
import CPAContentTile from './components/CPAContentTile';

enum MessageTextBoxInputMode {
  SEND_MESSAGE = 'SEND_MESSAGE',
  EDIT_DRAFT = 'EDIT_DRAFT',
  SEND_MESSAGE_WITH_NO_DRAFT = 'SEND_MESSAGE_WITH_NO_DRAFT',
}

interface MessageInputCommonProps {
  isLoading: boolean;
  message: string;
  setMessage: (newString: string) => void;
  height: number;
  placeholder: string;
  inputMode: MessageTextBoxInputMode;
  files?: any[];
  setFiles?: (newFiles: any[]) => any;
}

interface MessageInputSendMessageProps {
  inputMode: MessageTextBoxInputMode.SEND_MESSAGE;
  onSend: () => void;
  showDraftButton?: boolean;
  onDraft: () => void;
  sendDisabled?: boolean;
  draftDisabled?: boolean;
}

interface MessageInputEditDraftMode {
  inputMode: MessageTextBoxInputMode.EDIT_DRAFT;
  onDoneEditing: () => void;
}

interface MessageInputSendMessageNoDraftProps {
  inputMode: MessageTextBoxInputMode.SEND_MESSAGE_WITH_NO_DRAFT;
  onSend: () => void;
  sendDisabled?: boolean;
}

type MessageBoxProps = MessageInputCommonProps &
  (
    | MessageInputSendMessageProps
    | MessageInputEditDraftMode
    | MessageInputSendMessageNoDraftProps
  );

const MessageTextBox = ({
  placeholder,
  height,
  message,
  setMessage,
  isLoading,
  files,
  setFiles,
  ...props
}: MessageBoxProps) => {
  const [isRephrasing, setIsRephrasing] = useState(false);
  const [filesPopup, setFilesPopup] = useState(false);
  const [cpaQuestionTemplatePopup, setCpaQuestionTemplatePopup] =
    useState(false);
  const [rephrasedMessage, setRephrasedMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [showContent, setShowContent] = useState(
    localStorage.getItem(LocalStorageKey.CpaTemplateShowContent)
      ? localStorage.getItem(LocalStorageKey.CpaTemplateShowContent) === 'true'
      : true,
  );
  const [templateSearchText, setTemplateSearchText] = useState('');
  const [templateContent, setTemplateContent] = useState([]);
  const {notify} = useNotify();

  const toggleContent = () => {
    localStorage.setItem(
      LocalStorageKey.CpaTemplateShowContent,
      (!showContent).toString(),
    );
    setShowContent(!showContent);
  };

  const cpaTemplateContentToShow = useMemo(() => {
    const newTemplateContent = templateContent.filter((data) => {
      const curStr: string = data.title;
      return curStr.indexOf(templateSearchText) !== -1;
    });
    return newTemplateContent;
  }, [templateContent, templateSearchText]);

  const fetchCpaTemplateContent = async () => {
    try {
      const resp = await getCpaTemplateList();
      setTemplateContent(resp.data);
    } catch (e) {
      notify(
        `Failed to fetch cpa message template list ${e}`,
        NotificationType.error,
      );
    }
  };

  const onClickRephrase = async () => {
    try {
      setIsRephrasing(true);
      const {data} = await rephraseTextWithGPT({message});
      setRephrasedMessage(data);
      setIsVisible(true);
    } catch (e) {
      notify(`Failed to rephrase ${e}`, NotificationType.error);
    } finally {
      setIsRephrasing(false);
    }
  };

  const getMessageInputBottomActions = () => {
    switch (props.inputMode) {
      case MessageTextBoxInputMode.SEND_MESSAGE:
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <DSButton
              text="Draft"
              onClick={props.onDraft}
              disabled={
                isLoading || !message || isRephrasing || props.draftDisabled
              }
              type="secondary"
              style={{marginRight: 8}}
            />
            <DSButton
              text="Send"
              onClick={props.onSend}
              disabled={
                isLoading || !message || isRephrasing || props.sendDisabled
              }
              type="primary"
              endIcon={<SendIcon />}
            />
          </div>
        );
      case MessageTextBoxInputMode.SEND_MESSAGE_WITH_NO_DRAFT:
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <DSButton
              text="Send"
              onClick={props.onSend}
              disabled={
                isLoading || !message || isRephrasing || props.sendDisabled
              }
              type="primary"
              endIcon={<SendIcon />}
            />
          </div>
        );
      case MessageTextBoxInputMode.EDIT_DRAFT:
        return (
          <DSButton
            text="Done editing"
            onClick={props.onDoneEditing}
            disabled={isLoading || !message || isRephrasing}
            type="primary"
          />
        );
    }
  };

  const isRephraseDisabled = isLoading || isRephrasing || !message;
  return (
    <>
      <div
        style={{
          backgroundColor: themmeColor.cpaCenterV2Bg,
          border: 'solid 1px silver',
          borderRadius: 12,
        }}>
        <div style={{position: 'relative'}}>
          <RichTextEditor
            message={message}
            setMessage={setMessage}
            disabled={isLoading || isRephrasing}
            placeholder={placeholder}
            height={height}
          />
          {message?.length <= 20 && <PlanNameTag />}
          <div
            onClick={() => {
              // TODO: Check how to handle loading here
              fetchCpaTemplateContent();
              setCpaQuestionTemplatePopup(true);
            }}
            style={{position: 'absolute', top: 4, left: 132}}>
            <ReplyIcon />
          </div>
          {files && setFiles && (
            <div
              onClick={() => {
                setFilesPopup(true);
              }}
              style={{position: 'absolute', top: 4, right: 4}}>
              <UploadDocumentIcon />
              {files?.length > 0 && (
                <div
                  style={{
                    backgroundColor: themmeColor.errorRed,
                    borderRadius: 12,
                    paddingInline: 4,
                    position: 'absolute',
                    right: -6,
                    top: -6,
                  }}>
                  <Typography style={{fontSize: 13, color: themmeColor.white}}>
                    {files.length}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
        {isVisible && (
          <RephrasedTextPreview
            rephrasedMessage={rephrasedMessage}
            setIsVisible={setIsVisible}
            isLoading={isLoading || isRephrasing}
            setMessage={setMessage}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 52,
            paddingInline: 8,
          }}>
          <DSButton
            onClick={onClickRephrase}
            text="Rephrase"
            startIcon={<AiIcon />}
            disabled={isRephraseDisabled}
            type="secondary"
          />

          {getMessageInputBottomActions()}
        </div>
      </div>
      {files && setFiles && (
        <PopUp
          style={{
            width: 600,
            height: 400,
          }}
          isOpen={filesPopup}
          onClose={() => {
            setFilesPopup(false);
          }}>
          <MultiDocumentUpload files={files} setFiles={setFiles} />
        </PopUp>
      )}
      <PopUp
        style={{
          width: 712,
          height: 579,
          overflow: 'auto',
        }}
        isOpen={cpaQuestionTemplatePopup}
        onClose={() => {
          setCpaQuestionTemplatePopup(false);
        }}>
        <div
          style={{display: 'flex', flexDirection: 'column', minHeight: 530}}>
          <div style={{height: 76, display: 'flex', alignItems: 'center'}}>
            <Typography style={{fontSize: 20, height: 28, width: 250}}>
              Message templates
            </Typography>
            <div style={{marginLeft: 236}} />
            <DSButton
              text={showContent ? 'Hide content' : 'Show content'}
              onClick={toggleContent}
              type="secondary"
            />
            <div style={{marginLeft: 24}} />
            <div
              onClick={() => {
                setCpaQuestionTemplatePopup(false);
              }}>
              <CrossIcon color="black" />
            </div>
          </div>
          <div style={{display: 'flex', width: 663, alignItems: 'center'}}>
            <TextField
              autoFocus
              label="Enter template title"
              value={templateSearchText}
              type="text"
              fullWidth={true}
              // onKeyUp={handleOnPressEnter}
              onChange={(e) => {
                setTemplateSearchText(e.target.value);
              }}
            />
          </div>
          <div style={{marginTop: 24}} />
          {cpaTemplateContentToShow.length === 0 && (
            <div style={{flexGrow: 1}}>
              <div
                style={{
                  marginTop: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                No results
                <DSButton
                  text="Show all topics"
                  onClick={() => {
                    setTemplateSearchText('');
                  }}
                  type="secondary"
                />
              </div>
            </div>
          )}
          {cpaTemplateContentToShow.map((data) => {
            return (
              <CPAContentTile
                title={data.title}
                body={data.body}
                masterShowContent={showContent}
                setMessage={setMessage}
                setCpaQuestionTemplatePopup={setCpaQuestionTemplatePopup}
              />
            );
          })}
        </div>
      </PopUp>
    </>
  );
};

export default Object.assign(MessageTextBox, {MessageTextBoxInputMode});
