import React, {ComponentProps, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {DOCUMENT_TYPES} from 'src/constants/constants';
import {Form} from 'src/DesignSystem/Form/Form';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';

const BUSINESS_ASSIGNABLE_DOC_TYPES = [
  DOCUMENT_TYPES.FORM_1065,
  DOCUMENT_TYPES.FORM_1120,
  DOCUMENT_TYPES.FORM_1120_S,
  DOCUMENT_TYPES.BUSINESS_REGISTRATION_DOC,
];

interface AssignBusinessToDocProps {
  selectedBusiness: number | null;
  setSelectedBusiness: (newId: number) => void;
  docType: DOCUMENT_TYPES;
}

const AssignBusinessToDoc = ({
  docType,
  selectedBusiness,
  setSelectedBusiness,
}: AssignBusinessToDocProps) => {
  const {businesses} = useSelector(selectBusinessDetails);

  const options: ComponentProps<typeof Form.RadioField>['options'] =
    useMemo(() => {
      return businesses.map((biz) => {
        return {
          label: `${biz[BusinessDetailsField.name]} (${
            biz[BusinessDetailsField.entity_type]
          })`,
          value: biz[BusinessDetailsField.id],
        };
      });
    }, [businesses]);

  if (!BUSINESS_ASSIGNABLE_DOC_TYPES.includes(docType)) {
    return null;
  }

  return (
    <div style={{paddingLeft: 8, marginBottom: 8}}>
      <Form.RadioField
        title="Select business"
        options={options}
        value={selectedBusiness}
        onChangeValue={setSelectedBusiness}
      />
    </div>
  );
};

export default AssignBusinessToDoc;
