import React from 'react';
import {TaxProfileSubsection} from 'src/constants/constants';

export const TaxProfileSubsectionContext = React.createContext<{
  loaded: boolean;
  isSubsectionReviewComplete: (subsection: TaxProfileSubsection) => boolean;
  isSubSectionPendingFromUser: (subsection: TaxProfileSubsection) => boolean;
  isSubSectionVisible: (subsection: TaxProfileSubsection) => boolean;
  isSubsectionComplete: (subsection: TaxProfileSubsection) => boolean;
  // @ts-ignore
}>(null);
