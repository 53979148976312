import React, {useMemo, useState, useEffect} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {
  CREATE_RETURN_STATUS,
  ReturnFields,
  UserRelationType,
} from '../CreateReturn.types';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {MultipleUsersFieldConfig} from '../CreateReturn.constants';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import ListUserCards from './ListUserCards';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {useCreateReturn} from '../useCreateReturn';
import {transformReturnStateForBackend} from '../CreateReturn.utils';
import {createBulkPartnerReturns} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {isEmpty, isString} from 'lodash';

interface CreateReturnFromDocContentProps {
  userDetailsForm: {
    users: UserRelationType[];
  };
  setUserDetailsForm: React.Dispatch<
    React.SetStateAction<{
      users: UserRelationType[];
    }>
  >;
}

const CreateReturnFromDocContent = ({
  userDetailsForm,
  setUserDetailsForm,
}: CreateReturnFromDocContentProps) => {
  const {
    setStatus,
    setCreatedReturns,
    pendingDocs,
    status,
    fetchExistingUsersData,
    checkDuplicateExists,
  } = useCreateReturn();
  const [validUsers, setValidUsers] = useState<{[key: number]: boolean}>({});
  const {fields} = useFormData({
    data: userDetailsForm,
    setData: setUserDetailsForm,
    config: MultipleUsersFieldConfig,
  });
  const [validationDisabled, setValidationDisabled] = useState(true);
  const {notify} = useNotify();

  useEffect(() => {
    return () => setValidationDisabled(true);
  }, []);

  const userCount = useMemo(() => {
    return userDetailsForm[ReturnFields.users].length;
  }, [userDetailsForm]);

  const isLoading =
    status === CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS;

  const areAllUsersValid = useMemo(() => {
    return Object.entries(validUsers).every(([_, value]) => value);
  }, [validUsers]);

  const ssns = useMemo(() => {
    return userDetailsForm[ReturnFields.users]
      ?.filter((u) => {
        return isString(u[TaxProfileField.SSN]);
      })
      .map((u) => u[TaxProfileField.SSN]);
  }, [userDetailsForm]);

  const onCreateReturns = async ({
    checkDuplicates = true,
  }: {
    checkDuplicates: boolean;
  }) => {
    if (validationDisabled) {
      setValidationDisabled(false); // enable validations
      if (!areAllUsersValid) {
        return;
      }
    }
    try {
      setStatus(CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS);
      let existingUsers: Awaited<ReturnType<typeof fetchExistingUsersData>> =
        {};
      if (checkDuplicates) {
        existingUsers = await fetchExistingUsersData(ssns);
      }
      if (isEmpty(existingUsers)) {
        const usersRequestBody = userDetailsForm[ReturnFields.users].map(
          transformReturnStateForBackend,
        );
        if (usersRequestBody.length === 1) {
          // only single user, assign all pending docs to him
          usersRequestBody[0][ReturnFields.ocr_job_ids].push(
            ...pendingDocs.map((doc) => doc[ReturnFields.ocr_job_id]),
          );
        }
        const response = await createBulkPartnerReturns({
          tax_returns: usersRequestBody,
        });
        setCreatedReturns(response.data);
        if (pendingDocs.length === 0 || usersRequestBody.length === 1) {
          // document assignment not needed (no pending docs or single user)
          setStatus(CREATE_RETURN_STATUS.RETURNS_CREATED);
        } else {
          // document assignment needed
          setStatus(CREATE_RETURN_STATUS.ASSIGN_DOCUMENTS);
        }
        notify('Created returns successfully', NotificationType.success);
      } else {
        setStatus(CREATE_RETURN_STATUS.CREATE_RETURNS_FROM_DOC);
        notify('Found duplicate users', NotificationType.warning);
      }
    } catch (e) {
      notify(`Failed to create return: ${e}`, NotificationType.error);
    } finally {
    }
  };

  const isCreateReturnDisabled = useMemo(() => {
    if (isLoading || userCount === 0) {
      return true;
    }
    if (validationDisabled) {
      return false;
    }
    return !areAllUsersValid;
  }, [isLoading, areAllUsersValid, validationDisabled, userCount]);

  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}>
        <DSButton
          text="Go back"
          onClick={() => setStatus(CREATE_RETURN_STATUS.CONFIRM_USER_RELATION)}
          type="primary"
        />
        {fields.map((field) => {
          if (
            field.inputType === FORM_INPUT_TYPE.Array &&
            field.path === ReturnFields.users
          ) {
            return field.value.map((obj, index) => {
              return (
                <div
                  style={{
                    padding: 12,
                    borderRadius: 12,
                    border: 'solid 1px grey',
                    marginBottom: 16,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Text
                      type={TEXT_TYPES.L}
                      text={`User ${index + 1}`}
                      fontWeight={FONT_WEIGHTS.SemiBold}
                      containerStyle={{marginBottom: 12}}
                    />
                    <DSButton
                      type="primary"
                      text="Remove"
                      onClick={() => {
                        setUserDetailsForm((prev) => {
                          return {
                            [ReturnFields.users]: prev[
                              ReturnFields.users
                            ].filter((_, i) => i !== index),
                          };
                        });
                      }}
                    />
                  </div>
                  <ListUserCards
                    array={field.value}
                    onChangeValue={field.onChangeValue}
                    arrayObjectIndex={index}
                    childProps={field.childProps}
                    users={userDetailsForm[ReturnFields.users]}
                    setValidUsers={setValidUsers}
                    validationDisabled={validationDisabled}
                  />
                </div>
              );
            });
          }
          return null;
        })}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}>
        {checkDuplicateExists(ssns) ? (
          <DSButton
            style={{paddingInline: 20, paddingBlock: 12}}
            text="Create return with duplicates"
            type="secondary"
            onClick={() => onCreateReturns({checkDuplicates: false})}
            textType={TEXT_TYPES.BASE}
            disabled={isCreateReturnDisabled}
          />
        ) : null}
        <DSButton
          style={{paddingInline: 20, paddingBlock: 12}}
          text="Create returns (if no duplicates)"
          type="primary"
          onClick={() => onCreateReturns({checkDuplicates: true})}
          textType={TEXT_TYPES.BASE}
          disabled={isCreateReturnDisabled}
        />
      </div>
    </>
  );
};

export default CreateReturnFromDocContent;
