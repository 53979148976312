import React, {ReactNode, useRef} from 'react';
import {DEFAULT_SUPPORTED_FILE_EXTENSIONS} from '../Dropzone/Dropzone.constants';
import {
  convertFileExtensionsToAcceptMimeType,
  getSupportedFiles,
} from '../Dropzone/Dropzone.utils';

interface FileSystemProps {
  children: ReactNode | ReactNode[];
  supportedExts?: string[];
  onSelectFiles: (supportedFiles: File[]) => void;
}

const FileSystem = ({
  children,
  supportedExts = DEFAULT_SUPPORTED_FILE_EXTENSIONS,
  onSelectFiles,
}: FileSystemProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFileSystem = () => {
    fileInputRef.current?.click();
  };

  const _onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const {supportedFiles} = getSupportedFiles(
        e.target.files,
        supportedExts,
      );
      if (supportedFiles.length > 0) {
        onSelectFiles(supportedFiles);
      }
    }
  };

  return (
    <div onClick={openFileSystem}>
      {children}
      <input
        id="file-picker"
        ref={fileInputRef}
        type="file"
        multiple
        style={{display: 'none'}}
        onChange={_onSelectFiles}
        accept={convertFileExtensionsToAcceptMimeType(supportedExts)}
      />
    </div>
  );
};

export default FileSystem;
