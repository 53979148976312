import {useSelector} from 'react-redux';
import {
  TaxProfileSection,
  TaxProfileSectionSequence,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {
  TaxProfileReviewStatus,
  TaxProfileUserSideStatus,
} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {
  selectTaxProfileCpaSideStatus,
  selectTaxProfileUserSideStatus,
} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {getTaxProfileSectionFromSubsection} from '../common/CpaCenterV2.utils';

const useTaxProfileReview = () => {
  const stepsSequence = TaxProfileSectionSequence;
  const {taxProfileCpaSideStatus, loaded} = useSelector(
    selectTaxProfileCpaSideStatus,
  );
  const {taxProfileUserSideStatus} = useSelector(
    selectTaxProfileUserSideStatus,
  );

  const isSubSectionVisible = (subsection: TaxProfileSubsection) => {
    const section = getTaxProfileSectionFromSubsection(subsection);
    if (section === TaxProfileSection.Income) {
      return (
        loaded &&
        ![
          TaxProfileUserSideStatus.NOT_APPLICABLE,
          TaxProfileUserSideStatus.NOT_INITIATED,
        ].includes(
          taxProfileUserSideStatus[subsection]?.status ??
            TaxProfileUserSideStatus.NOT_APPLICABLE,
        )
      );
    }

    return true;
  };

  const isSubSectionPendingFromUser = (subsection: TaxProfileSubsection) => {
    return (
      loaded &&
      [
        TaxProfileUserSideStatus.PENDING,
        TaxProfileUserSideStatus.REVIEW_PENDING,
      ].includes(
        taxProfileUserSideStatus[subsection]?.status ??
          TaxProfileUserSideStatus.NOT_APPLICABLE,
      )
    );
  };

  const isSubsectionVisibleAndComplete = (
    subsection: TaxProfileSubsection,
  ) => {
    return (
      isSubSectionVisible(subsection) &&
      taxProfileCpaSideStatus[subsection] === TaxProfileReviewStatus.COMPLETE
    );
  };

  const isSubsectionReviewComplete = (subsection: TaxProfileSubsection) => {
    return (
      isSubsectionVisibleAndComplete(subsection) ||
      !isSubSectionVisible(subsection)
    );
  };

  const isSectionReviewPending = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    const isPending = allSubsections.some(
      (subsection) => !isSubsectionReviewComplete(subsection),
    );
    return isPending;
  };

  const isSomeSubsectionComplete = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    return allSubsections.some(isSubsectionVisibleAndComplete);
  };

  const isTaxProfileReviewComplete = () => {
    const allSections = Object.values(TaxProfileSection);
    if (allSections.some(isSectionReviewPending)) {
      return false;
    }
    return true;
  };

  const getNextSection = (section: TaxProfileSection) => {
    const sectionIndex = stepsSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === stepsSequence.length - 1) {
      return null;
    }
    return stepsSequence[sectionIndex + 1];
  };

  const getPreviousSection = (section: TaxProfileSection) => {
    const sectionIndex = stepsSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === 0) {
      return null;
    }
    return stepsSequence[sectionIndex - 1];
  };

  return {
    loaded,
    isSectionReviewPending,
    isSubsectionReviewComplete,
    isTaxProfileReviewComplete,
    getNextSection,
    getPreviousSection,
    isSubSectionVisible,
    isSubSectionPendingFromUser,
    isSomeSubsectionComplete,
  };
};

export default useTaxProfileReview;
