import React from 'react';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import StatesLived from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/StatesLived';
import StatesSubsection from './components/StatesSubsection';

const PartnerStateTaxes = () => {
  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          sectionName="States"
          section={TaxProfileSection.StateTaxes}
        />
        <div
          style={{
            overflowY: 'auto',
          }}>
          {/* Subsections here */}
          <StatesSubsection />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.25,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <ActionRequired
          title="Comments"
          dontAllowAdd
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          taxProfileSection={TaxProfileSection.StateTaxes}
        />
      </div>
    </div>
  );
};

export default PartnerStateTaxes;
