import {VALID_CPA_EMAILS} from 'src/constants/constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';

export enum BOI_FILING_COLUMNS {
  ASSIGNED_REVIEWER = 'assigned_reviewer',
  FLY_USER_NAME = 'fly_user_name',
  STATUS = 'status',
  SUBMIT_TYPE = 'submit_type',
  FLY_USER_ID = 'fly_user_id',
  USER_SUBMITTED_AT = 'user_submitted_at',
  PAYMENT_STATUS = 'payment_status',
}

export enum BOI_FILTER_PARAMS {
  STATUS = 'status',
  SUBMIT_TYPE = 'submit_type',
  FLY_USER_ID = 'fly_user_id',
  NAME = 'name',
  ASSIGNED_REVIEWER = 'assigned_reviewer',
  EMAIL = 'email',
  PAYMENT_STATUS = 'payment_status',
}

export enum BOI_STATUS {
  FLOW_INITIATED = 'FLOW_INITIATED',
  BOI_NOT_REQUIRED = 'BOI_NOT_REQUIRED',
  DOCS_SUBMITTED = 'DOCS_SUBMITTED',
  DETAILS_SUBMITTED = 'DETAILS_SUBMITTED',
  PREP_STARTED = 'PREP_STARTED',
  REPORT_SENT_TO_USER = 'REPORT_SENT_TO_USER',
  USER_REVIEW_COMPLETE = 'USER_REVIEW_COMPLETE',
  BOI_FILED = 'BOI_FILED',
  REJECTED_BY_FINCEN = 'REJECTED_BY_FINCEN',
  APPROVED_BY_FINCEN = 'APPROVED_BY_FINCEN',
}

export enum SUBMIT_TYPE {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  CPA_QUERY = 'CPA_QUERY',
  BOI_FORM = 'BOI_FORM',
}

export interface BoiFilingData {
  [BOI_FILING_COLUMNS.ASSIGNED_REVIEWER]: VALID_CPA_EMAILS | null;
  [BOI_FILING_COLUMNS.FLY_USER_NAME]: string;
  [BOI_FILING_COLUMNS.STATUS]: BOI_STATUS;
  [BOI_FILING_COLUMNS.SUBMIT_TYPE]: SUBMIT_TYPE | null;
  [BOI_FILING_COLUMNS.FLY_USER_ID]: number;
  [BOI_FILING_COLUMNS.USER_SUBMITTED_AT]: string | null;
  [BOI_FILING_COLUMNS.PAYMENT_STATUS]: string;
  year: number;
  id: number;
  created_at: string;
  updated_at: string;
  fly_user_email: string;
}

export interface BoiBusinessForm {
  [TaxFormFieldIds.BUSINESS_NAME]: string;
  [TaxFormFieldIds.ALTERNATE_BUSINESS_NAME]: string | null;
  [TaxFormFieldIds.BUSINESS_EIN]: string | null;
  [TaxFormFieldIds.BUSINESS_SSN_OR_TIN]: string | null;
  [TaxFormFieldIds.BUSINESS_ADDRESS]: {
    [TaxFormFieldIds.NUMBER_AND_STREET]: string;
    [TaxFormFieldIds.APARTMENT_NUMBER]: null;
    [TaxFormFieldIds.CITY]: string;
    [TaxFormFieldIds.STATE]: string;
    [TaxFormFieldIds.ZIP_CODE]: string;
  };
  [TaxFormFieldIds.REQUEST_FINCEN_ID]: boolean;
  [TaxFormFieldIds.IS_COMPANY_FOREIGN_POOLED_INVESTMENT_VEHICLE]: boolean;
  ein_doc_link: string | null;
  ein_file_name: string | null;
  id?: number;
}

export enum SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER {
  MORE_THAN_20_USA_FT_EMPLOYEES = 'MORE_THAN_20_USA_FT_EMPLOYEES',
  OPERATING_PHYSICAL_OFFICE_IN_USA = 'OPERATING_PHYSICAL_OFFICE_IN_USA',
  MORE_THAN_5_MILLION_USA_TAX_PREV_YEAR = 'MORE_THAN_5_MILLION_USA_TAX_PREV_YEAR',
}

export interface BeneficialOwnersForm {
  [TaxFormFieldIds.IS_BENEFICIARY_OWNER_MINOR]: boolean;
  [TaxFormFieldIds.IS_FINCEN_ID_PRESENT]: boolean;
  [TaxFormFieldIds.FINCEN_ID]: string | null;
  [TaxFormFieldIds.INDIVIDUAL_LAST_NAME_OR_ENTITY_NAME]: string | null;
  [TaxFormFieldIds.DEPENDENT_FIRST_NAME]: string | null;
  [TaxFormFieldIds.DOB]: string | null;
  [TaxFormFieldIds.ADDRESS]: {
    [TaxFormFieldIds.NUMBER_AND_STREET]: string | null;
    [TaxFormFieldIds.APARTMENT_NUMBER]: string | null;
    [TaxFormFieldIds.CITY]: string | null;
    [TaxFormFieldIds.STATE]: string | null;
    [TaxFormFieldIds.ZIP_CODE]: string | null;
  };
  [TaxFormFieldIds.ADDRESS_OUTSIDE_USA]: string | null;
}

export enum BOI_SECTION_NAMES {
  BOI_ELIGIBILIITY_QUESTIONS = 'BOI_ELIGIBILIITY_QUESTIONS',
  BOI_BUSINESS_DETAILS = 'BOI_BUSINESS_DETAILS',
  BOI_BENEFICIAL_OWNER_DETAILS = 'BOI_BENEFICIAL_OWNER_DETAILS',
  BOI_DOCUMENT_REVIEW = 'BOI_DOCUMENT_REVIEW',
}
