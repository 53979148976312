import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import SimpleArrayField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleArrayField';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import Box from 'src/DesignSystem/Box/Box';
import Tag from 'src/DesignSystem/Tag/Tag';
import {BUSINESS_DETAILS_INPUT_TYPE} from 'src/store/businessDetails/businessDetails.reducer';
import {getCorrectFieldsForBusiness} from 'src/store/businessDetails/businessDetails.utils';
import {selectDocuments} from 'src/store/documents/documents.selector';
import ShareHoldersDetails from './ShareHoldersDetails';
import {BusinessDetail} from 'src/store/businessDetails/businessDetails.types';
import EditBusinessDetails from 'src/CpaCenterV2/common/EditBusinessDetails/EditBusinessDetails';
import DSButton from 'src/DesignSystem/Button/Button';
import EditIcon from 'src/icons/EditIcon';

interface SingleBusinessDetailsProps {
  business: BusinessDetail;
  index: number;
  showDeleteBiz?: boolean;
}

const SingleBusinessDetail = ({
  business,
  index,
  showDeleteBiz,
}: SingleBusinessDetailsProps) => {
  const fields = getCorrectFieldsForBusiness(business);
  const [editBusinessDetails, setEditBusinessDetails] = useState(false);
  const {documents} = useSelector(selectDocuments);
  return (
    <>
      <Accordion>
        <AccordionSummary
          style={{backgroundColor: themmeColor.cpaCenterV2Bg}}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
              paddingRight: 8,
            }}>
            <Tag
              text={`Business ${index + 1}`}
              textColor={themmeColor.white}
              backgroundColor={themmeColor.grey}
            />

            <DSButton
              type="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setEditBusinessDetails(true);
              }}
              text="Edit"
              endIcon={<EditIcon />}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {fields?.map((field) => {
            const {name, type, value} = field;
            if (type === BUSINESS_DETAILS_INPUT_TYPE.DEFAULT) {
              return <SimpleKeyValueField name={name} value={value} />;
            }
            if (type === BUSINESS_DETAILS_INPUT_TYPE.ARRAY) {
              return (
                <SimpleArrayField name={name} value={(value ?? []) as []} />
              );
            }
            if (type === BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS) {
              return (
                <Box
                  borderColor={themmeColor.silver}
                  backgroundColor={themmeColor.cpaCenterV2Bg}
                  style={{padding: 12, paddingBottom: 0, marginBottom: 12}}
                  col>
                  <Typography style={{marginBottom: 8}} fontWeight={600}>
                    Business Address
                  </Typography>
                  {value?.map((l2Field) => {
                    return (
                      <SimpleKeyValueField
                        name={l2Field.name}
                        value={l2Field.value}
                      />
                    );
                  })}
                </Box>
              );
            }
            if (type === BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS) {
              return <ShareHoldersDetails {...field} />;
            }
            if (type === BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS) {
              return (
                <Box
                  col
                  style={{padding: 12, marginTop: 12}}
                  backgroundColor={themmeColor.cpaCenterV2Bg}
                  borderColor={themmeColor.silver}>
                  <Typography style={{fontWeight: 600}}>
                    Related Documents
                  </Typography>
                  {value?.map((docItem) => (
                    <DocumentPointer
                      border
                      style={{marginBottom: 12}}
                      doc={documents.find(
                        (doc) => doc.docId === docItem.value,
                      )}
                      dontShowStatus
                    />
                  ))}
                </Box>
              );
            }
            return null;
          })}
        </AccordionDetails>
      </Accordion>
      <EditBusinessDetails
        business={business}
        isOpen={editBusinessDetails}
        showDelete={showDeleteBiz}
        onClose={() => {
          setEditBusinessDetails(false);
        }}
      />
    </>
  );
};

export default SingleBusinessDetail;
