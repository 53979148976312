import {AttachMoney} from '@mui/icons-material';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {
  QuarterlyPaymentType,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {Transaction} from 'src/store/transactions/transactions.reducer';

const QtcPayments = ({
  forms,
  transactionIdMap,
}: {
  forms: GenericTaxForm[];
  transactionIdMap: {[key: number]: Transaction};
}) => {
  const taxPaymentForms = forms.filter(
    (taxForm) =>
      taxForm.formType === TaxFormType.QUARTERLY_TAX_PAYMENTS &&
      [QuarterlyPaymentType.FEDERAL, QuarterlyPaymentType.STATE].includes(
        taxForm.formData[TaxFormFieldIds.PAYMENT_TYPE],
      ),
  );

  const getTxnId = (rowData: GenericTaxForm) =>
    rowData.formData[TaxFormFieldIds.TXN_ID];

  const getPaymentType = (rowData: GenericTaxForm) => {
    let paymentType = rowData.formData[TaxFormFieldIds.PAYMENT_TYPE];
    const state = rowData.formData[TaxFormFieldIds.STATE];
    if (paymentType === QuarterlyPaymentType.STATE) {
      paymentType = `${paymentType}(${state})`;
    }
    return paymentType;
  };

  const getAmount = (rowData: GenericTaxForm) => {
    let amount: any = false;
    let isFromTxns = false;
    const txnId = getTxnId(rowData);
    if (!txnId) {
      amount = rowData.formData[TaxFormFieldIds.AMOUNT];
    } else {
      amount = transactionIdMap[txnId]?.currentAmount;
      isFromTxns = true;
    }
    if (typeof amount !== 'number') {
      return amount;
    }
    let amountString = CurrencyNumberFormat(amount);
    if (isFromTxns) {
      amountString = `${amountString}(T)`;
    }
    return amountString;
  };

  const getPaymentDate = (rowData: GenericTaxForm) => {
    const txnId = getTxnId(rowData);
    if (!txnId) {
      return rowData.formData[TaxFormFieldIds.PAYMENT_DATE];
    }
    return transactionIdMap[txnId]?.txnDate;
  };

  const keys = [
    {
      getValue: getPaymentType,
      getIcon: (rowData: GenericTaxForm) => {
        const txnId = getTxnId(rowData);
        if (txnId != null) {
          return <AttachMoney />;
        }
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },

    {
      getValue: getPaymentDate,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      getValue: getAmount,
      textStyle: {
        fontWeight: 600,
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ];

  return <KeyValueTable data={taxPaymentForms} keys={keys} />;
};

export default QtcPayments;
