import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {patchTaxForm} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';

export const usePartnerCredits = (formField: TaxFormFieldIds) => {
  const {taxForms, loaded: taxFormsLoaded} = useSelector(
    selectTaxFormsReducer,
  );
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();

  const dependents = useMemo(() => {
    return taxForms.filter(
      (tf) => tf.formType === TaxFormType.DEPENDENT,
    ) as TaxForm<TaxFormType.DEPENDENT>[];
  }, [taxForms]);

  const onChange = async (depTaxFormId: number, newValue: any) => {
    try {
      setIsLoading(true);
      await patchTaxForm(depTaxFormId, {form_data: {[formField]: newValue}});
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
      notify('Updated', NotificationType.success);
    } catch (e) {
      notify(`Error ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    onChange,
    isLoading,
    dependents,
    taxFormsLoaded,
  };
};
