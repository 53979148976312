import React, {useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {useUploadPartnerReturnDocument} from '../components/UploadPartnerReturnDocumentProvider';
import FileSystem from 'src/DesignSystem/FileSystem/FileSystem';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import {BOTTOM_PRIMARY_ACTION_HEIGHT} from '../Partnership.constants';
import FilingOptionsPopup from './components/FilingOptionsPopup';
import PartnerTaxProfileSteps from './components/PartnerTaxProfileSteps';

const PartnerTaxProfileReview = () => {
  const {onUploadFiles} = useUploadPartnerReturnDocument();
  const [filingOptionsPopup, setFilingOptionsPopup] = useState(false);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}>
      <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
        <div
          style={{
            display: 'flex',
            flex: 0.6,
            flexDirection: 'column',
            padding: 12,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text
              text={'Tax profile review'}
              type={TEXT_TYPES.L}
              fontWeight={FONT_WEIGHTS.SemiBold}
            />
            <div style={{display: 'flex', alignItems: 'center'}}>
              <DSButton
                type="secondary"
                text="Filing setting"
                onClick={() => {
                  setFilingOptionsPopup(true);
                }}
                style={{marginRight: 12}}
              />
              <FileSystem onSelectFiles={onUploadFiles}>
                <DSButton
                  type="secondary"
                  text="Upload docs"
                  onClick={() => {}}
                />
              </FileSystem>
            </div>
          </div>
          <PartnerTaxProfileSteps />
        </div>
        <div style={{display: 'flex', flex: 0.4, flexDirection: 'column'}}>
          <ActionRequired
            title="Comments"
            types={[
              CreateQueryDrawerTab.TAX_PROFILE,
              CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
              CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
            ]}
          />
        </div>
      </div>
      <div
        style={{
          height: BOTTOM_PRIMARY_ACTION_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingInline: 20,
        }}>
        <Text
          type={TEXT_TYPES.BASE}
          text={
            'Once you have reviewed all the sections you will be able to proceed to next section'
          }
        />
        <DSButton
          type="primary"
          onClick={() => {
            // markStepComplete(TAX_FILING_STATUS.TAX_DOCS_REVIEWED);
          }}
          disabled={true}
          text="Review complete"
          style={{paddingInline: 100, paddingBlock: 20}}
          textType={TEXT_TYPES.BASE}
          textFontWeight={FONT_WEIGHTS.SemiBold}
        />
      </div>
      <FilingOptionsPopup
        isOpen={filingOptionsPopup}
        onClose={() => setFilingOptionsPopup(false)}
      />
    </div>
  );
};

export default PartnerTaxProfileReview;
