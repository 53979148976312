import _, {noop} from 'lodash';
import {FORM_INPUT_TYPE, FormConfigProps, FormInputProps} from './Form.types';
import {OverWriteFormFieldsFunctionType} from './useFormData';
import {getValidatorFunctions} from './Form.validators';

export const parseFormConfigToFormField = ({
  config: {path, ...props},
  data,
  setData,
}: {
  config: FormConfigProps;
  data: any;
  setData: React.Dispatch<any>;
}) => {
  let field: FormInputProps & {path: string} = {} as any;
  const onChangeValue = (newValue: any) => {
    setData((prev: any) => {
      const updated = {..._.set(prev, path, newValue)};
      return updated;
    });
  };

  const value = _.get(data, path);

  if (props.inputType === FORM_INPUT_TYPE.String) {
    field = {
      title: props.title,
      inputType: props.inputType,
      value,
      onChangeValue,
      validators: props.validators,
      path,
      transformers: props.transformers,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.Number) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      path,
      transformers: props.transformers,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.SingleSelect) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      options: props.options,
      path,
      allowClear: props.allowClear,
      transformers: props.transformers,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.Profession) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      isSingle: props.isSingle,
      path,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.Date) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      path,
      transformers: props.transformers,
      min: props.min,
      max: props.max,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.MultiSelect) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      options: props.options,
      path,
      transformers: props.transformers,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.Boolean) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      path,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.State) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      validators: props.validators,
      isSingle: props.isSingle,
      useAbbrevation: props.useAbbrevation,
      path,
      excludeStates: props.excludeStates,
    };
  }

  if (props.inputType === FORM_INPUT_TYPE.Array) {
    field = {
      title: props.title,
      inputType: props.inputType,
      onChangeValue,
      value,
      childProps: props.childProps,
      childTitle: props.childTitle,
      path,
    };
  }

  return field;
};

export const createEmptyFormDataFromFormConfig = (
  formConfig: FormConfigProps[],
  defaultArrayElement = 1,
): any => {
  const emptyForm = {};
  formConfig.forEach((field) => {
    let value = null;
    if (field.inputType === FORM_INPUT_TYPE.Array) {
      value = [];
      for (var i = 0; i < defaultArrayElement; i++) {
        value.push(
          createEmptyFormDataFromFormConfig(
            field.childProps,
            defaultArrayElement,
          ),
        );
      }
    }
    if (field.inputType === FORM_INPUT_TYPE.MultiSelect) {
      value = [];
    }
    _.set(emptyForm, field.path, value);
  });
  return emptyForm;
};

// Not being used anywhere right now
export const parseFormConfigToFormData = (
  formConfig: FormConfigProps[],
  data: any,
) => {
  const dataConfig = {};
  let value: any;
  formConfig.forEach((config) => {
    if (config.inputType === FORM_INPUT_TYPE.Array) {
      // recursively get the value for each object of array
      const childArray: Array<any> = _.get(data, config.path);
      const newArray: any[] = [];
      childArray?.forEach((childObject, index) => {
        const childParsedObject = parseFormConfigToFormData(
          config.childProps,
          childObject,
        );
        newArray.push(childParsedObject);
      });
      value = newArray;
    } else {
      value = _.get(data, config.path);
    }
    _.set(dataConfig, config.path, value);
  });

  return dataConfig;
};

export const getParsedFields = (
  config: FormConfigProps[],
  data: any,
  setData: React.Dispatch<React.SetStateAction<any>>,
  overWriteFields?: OverWriteFormFieldsFunctionType,
) => {
  let parsedFields = config.map((configItem) => {
    return parseFormConfigToFormField({
      config: configItem,
      data,
      setData,
    });
  });
  if (overWriteFields) {
    parsedFields = overWriteFields({fields: parsedFields, data, setData});
  }
  return parsedFields;
};

export const checkFormDataValidity = (fields: FormInputProps[]): boolean => {
  return fields.every((field) => {
    if (field.inputType === FORM_INPUT_TYPE.Array) {
      const arrayObjectProps = field.value.map((data) =>
        field.childProps.map((childProp) =>
          parseFormConfigToFormField({
            config: childProp,
            data,
            setData: noop, // doesnt matter as it will not be
          }),
        ),
      );
      return arrayObjectProps.every(checkFormDataValidity);
    }
    const validators = field.validators;
    if (validators) {
      const validatorFunctions = getValidatorFunctions(validators);
      return validatorFunctions.every(
        (isValid) => isValid(field.value).isValid,
      );
    }
    return true;
  });
};
