import React from 'react';
import {ExpandMore} from '@mui/icons-material';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import Tag from 'src/DesignSystem/Tag/Tag';
import DSButton from 'src/DesignSystem/Button/Button';
import {TaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import EditIcon from 'src/icons/EditIcon';

interface DependentCardProps {
  taxFormId: number;
  index: number;
  data: TaxForm<TaxFormType.DEPENDENT>['formData'];
  fields: any;
  onEdit?: (taxformId: number) => void;
}

const DependentCard = ({
  index,
  data,
  fields,
  onEdit,
  taxFormId,
}: DependentCardProps) => {
  return (
    <Accordion
      style={{
        backgroundColor: themmeColor.cpaCenterV2Bg,
        margin: 0,
        marginBottom: 20,
      }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
          }}>
          <Tag
            textColor={themmeColor.white}
            backgroundColor={themmeColor.grey}
            text={`Dependent ${index + 1}`}
          />

          {onEdit && (
            <DSButton
              type="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(taxFormId);
              }}
              text="Edit"
              endIcon={<EditIcon />}
              style={{marginRight: 8}}
            />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <MultiKeyValueField data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};

export default DependentCard;
