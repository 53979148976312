import _ from 'lodash';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';

export enum SEPERATE_OFFICE_ANSWER {
  yes_lease = 'yes_lease',
  yes_own = 'yes_own',
  no = 'no',
}

export const TAX_PROFILE_FIELD_DISPLAY_NAME = {
  [TaxProfileField.FIRST_NAME]: 'first_name_and_middle_initial',
  [TaxProfileField.LAST_NAME]: 'last_name',
  [TaxProfileField.SSN]: 'ssn',
  [TaxProfileField.STREET_NUMBER]: 'number_and_street',
  [TaxProfileField.APARTMENT_NUMBER]: 'apartment_number',
  [TaxProfileField.CITY]: 'city',
  [TaxProfileField.STATE]: 'state',
  [TaxProfileField.ZIP_CODE]: 'zip_code',
  [TaxProfileField.DEP_FIRST_NAME]: 'dependent_first_name',
  [TaxProfileField.DEP_LAST_NAME]: 'dependent_last_name',
  [TaxProfileField.DEP_SSN]: 'dependent_social_security_number',
  [TaxProfileField.DEP_RELATION]: 'relationship_to_you',
  [TaxProfileField.BUSINESS_NAME]: 'business_name',
  // [FIELD_ID.BUSINESS_REGISTER]: 'is_your_business_registered',
  [TaxProfileField.EIN]: 'EIN',
  [TaxProfileField.FREELANCE_INCOME]: 'freelance_income',
  [TaxProfileField.W2_INCOME]: 'w2_income',
  [TaxProfileField.CAPITAL_GAINS_INCOME]: 'capital_gains_income',
  [TaxProfileField.INTEREST_INCOME]: 'interest_income',
  [TaxProfileField.DIVIDENDS_INCOME]: 'dividends_income',
  [TaxProfileField.GOV_BENEFITS_INCOME]: 'government_benefits_income',
  [TaxProfileField.OTHER_SOURCE_INCOME]: 'other_sources_income',
  [TaxProfileField.HOME_AREA]: 'home_area',
  [TaxProfileField.OFFICE_AREA]: 'office_area',
  [TaxProfileField.TOTAL_MILES]: 'total_miles',
  [TaxProfileField.BUSINESS_MILES]: 'business_miles',
  [TaxProfileField.FIRST_QUARTER_TAX]: 'first_quarter_tax',
  [TaxProfileField.SECOND_QUARTER_TAX]: 'second_quarter_tax',
  [TaxProfileField.THIRD_QUARTER_TAX]: 'third_quarter_tax',
  [TaxProfileField.FOURTH_QUARTER_TAX]: 'fourth_quarter_tax',
  [TaxProfileField.WORK_TYPE]: 'work_type',
  [TaxProfileField.PROFESSION]: 'profession',
  [TaxProfileField.FILING_STATUS]: 'filing_status',
  [TaxProfileField.SPOUSE_SSN]: 'spouse_ssn',
  [TaxProfileField.DEPENDENT]: 'dependent',
  [TaxProfileField.WFH]: 'wfh',
  [TaxProfileField.TRAVEL]: 'travel',
  [TaxProfileField.PHONE_PERCENTAGE]: 'phone_percentage',
  [TaxProfileField.INTERNET_PERCENTAGE]: 'internet_percentage',
  [TaxProfileField.MEAL]: 'meal',
  [TaxProfileField.PERSONAL_DEDUCTION]: 'personal_deductions',
  [TaxProfileField.EARNING_STATE]: 'earning_state',
  [TaxProfileField.INCOME_SOURCES]: 'income_sources',
  [TaxProfileField.FEDERAL_TAX]: 'federal_tax',
  [TaxProfileField.STATE_TAX]: 'state_tax',
  [TaxProfileField.SPOUSE_FREELANCE_INCOME]: 'spouse_freelance_income',
  [TaxProfileField.SPOUSE_W2_INCOME]: 'spouse_w2_income',
  [TaxProfileField.W2_WITHHELD]: 'w2_withheld,',
};

export enum ANSWER_TYPE {
  // Key-value, value will be string or number
  OBJECT_KEY_VALUE = 'OBJECT_KEY_VALUE',

  // Key-value, value could be string, number or list
  OBJECT_KEY_VALUE_LIST = 'OBJECT_KEY_VALUE_LIST',

  // List of string or number
  LIST = 'LIST',

  // Single number
  INTEGER = 'INTEGER',

  // Single string
  STRING = 'STRING',

  // Single boolean
  BOOLEAN = 'BOOLEAN',

  // List of objects, value of objects are string, number, or boolean (primitives)
  LIST_OBJECT_KEY_VALUE = 'LIST_OBJECT_KEY_VALUE',

  // Nested Objects key value
  NESTED_OBJECT_KEY_VALUE = 'NESTED_OBJECT_KEY_VALUE',
}

export enum TAX_PROFILE_SECTION {
  BASIC_DETAILS = 'Basic details',
  PROFESSION = 'Profession',
  TAX_FILING_INFO = 'Tax filing info',
  INCOME_DETAILS = 'Income details',
  EXPENSES_AND_DEDUCTION = 'Expenses and deduction',
  TAX_PAYMENTS = 'Tax Payments',
  OTHER = 'Other',
}

export const sectionOrdering: Array<TAX_PROFILE_SECTION> = [
  TAX_PROFILE_SECTION.BASIC_DETAILS,
  TAX_PROFILE_SECTION.PROFESSION,
  TAX_PROFILE_SECTION.TAX_FILING_INFO,
  TAX_PROFILE_SECTION.INCOME_DETAILS,
  TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
  TAX_PROFILE_SECTION.TAX_PAYMENTS,
  TAX_PROFILE_SECTION.OTHER,
];

export const TAX_PROFILE_STRUTURE = {
  [TaxProfileQuestion.LEGAL_NAME]: {
    question_id: TaxProfileQuestion.LEGAL_NAME,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'LEGAL_NAME',
    answered: false,
    answer: {
      [TaxProfileField.FIRST_NAME]: '',
      [TaxProfileField.LAST_NAME]: '',
    },
  },
  [TaxProfileQuestion.SSN]: {
    question_id: TaxProfileQuestion.SSN,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SSN',
    display_name: 'ssn',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.HOME_ADDRESS]: {
    question_id: TaxProfileQuestion.HOME_ADDRESS,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE_LIST,
    tax_profile_fields_key_name: 'HOME_ADDRESS',
    answered: false,
    answer: {
      [TaxProfileField.STREET_NUMBER]: '',
      [TaxProfileField.APARTMENT_NUMBER]: '',
      [TaxProfileField.STATE]: '',
      [TaxProfileField.CITY]: '',
      [TaxProfileField.ZIP_CODE]: '',
    },
  },
  [TaxProfileQuestion.CLAIM_YOU_DEPENDENT]: {
    question_id: TaxProfileQuestion.CLAIM_YOU_DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'CLAIM_YOU_DEPENDENT',
    display_name: 'CLAIM_YOU_DEPENDENT',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.FILING_STATUS]: {
    question_id: TaxProfileQuestion.FILING_STATUS,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FILING_STATUS',
    display_name: TaxProfileField.FILING_STATUS,
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.SPOUSE_LEGAL_NAME]: {
    question_id: TaxProfileQuestion.SPOUSE_LEGAL_NAME,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'SPOUSE_LEGAL_NAME',
    answered: false,
    answer: {
      [TaxProfileField.FIRST_NAME]: '',
      [TaxProfileField.LAST_NAME]: '',
    },
  },
  [TaxProfileQuestion.SPOUSE_SSN]: {
    question_id: TaxProfileQuestion.SPOUSE_SSN,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_SSN',
    display_name: TaxProfileField.SSN,
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT]: {
    question_id: TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_CLAIMED_DEPENDENT',
    display_name: 'SPOUSE_CLAIMED_DEPENDENT',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.DEPENDENT_DETAILS]: {
    question_id: TaxProfileQuestion.DEPENDENT_DETAILS,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.LIST_OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'DEPENDENT_DETAILS',
    display_name: 'DEPENDENT_DETAILS',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.EARNING_STATES]: {
    question_id: TaxProfileQuestion.EARNING_STATES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'EARNING_STATES',
    display_name: 'EARNING_STATES',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.INCOME_SOURCES]: {
    question_id: TaxProfileQuestion.INCOME_SOURCES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'INCOME_SOURCES',
    display_name: 'INCOME_SOURCES',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.YOUR_N_SPOUSE_INCOME_SOURCES]: {
    question_id: TaxProfileQuestion.YOUR_N_SPOUSE_INCOME_SOURCES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'YOUR_N_SPOUSE_INCOME_SOURCES',
    display_name: 'YOUR_N_SPOUSE_INCOME_SOURCES',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.FREELANCE_EARNINGS]: {
    question_id: TaxProfileQuestion.FREELANCE_EARNINGS,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'FREELANCE_EARNINGS',
    display_name: 'FREELANCE_EARNINGS',
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.RETIREMENT_PLAN_CONTRIBUTIONS]: {
    question_id: TaxProfileQuestion.RETIREMENT_PLAN_CONTRIBUTIONS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'RETIREMENT_PLAN_CONTRIBUTIONS',
    display_name: 'RETIREMENT_PLAN_CONTRIBUTIONS',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.DEDUCTION_OPTION]: {
    question_id: TaxProfileQuestion.DEDUCTION_OPTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'DEDUCTION_OPTION',
    display_name: 'DEDUCTION_OPTION',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.ITEMIZE_DEDUCTION]: {
    question_id: TaxProfileQuestion.ITEMIZE_DEDUCTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'ITEMIZE_DEDUCTION',
    display_name: 'ITEMIZE_DEDUCTION',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.STANDARD_DEDUCTION]: {
    question_id: TaxProfileQuestion.STANDARD_DEDUCTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'STANDARD_DEDUCTION',
    display_name: 'STANDARD_DEDUCTION',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.ESTIMATED_TAX_PAYMENTS]: {
    question_id: TaxProfileQuestion.ESTIMATED_TAX_PAYMENTS,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'ESTIMATED_TAX_PAYMENTS',
    display_name: 'ESTIMATED_TAX_PAYMENTS',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.RECEIVED_ADV_CHILD_TAX_CREDITS]: {
    question_id: TaxProfileQuestion.RECEIVED_ADV_CHILD_TAX_CREDITS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
    display_name: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.BUSINESS_DETAILS]: {
    question_id: TaxProfileQuestion.BUSINESS_DETAILS,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'BUSINESS_DETAILS',
    answered: false,
    answer: {
      [TaxProfileField.BUSINESS_NAME]: '',
      [TaxProfileField.BUSINESS_REGISTER]: '',
      [TaxProfileField.EIN]: '',
    },
  },
  [TaxProfileQuestion.WORK_TYPE]: {
    question_id: TaxProfileQuestion.WORK_TYPE,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'WORK_TYPE',
    display_name: TaxProfileField.WORK_TYPE,
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.PROFESSION]: {
    question_id: TaxProfileQuestion.PROFESSION,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'PROFESSION',
    display_name: TaxProfileField.PROFESSION,
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.PHONE_INTERNET_PCT]: {
    question_id: TaxProfileQuestion.PHONE_INTERNET_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'PHONE_INTERNET_PCT',
    display_name: 'PHONE_INTERNET_PCT',
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.WFH]: {
    question_id: TaxProfileQuestion.WFH,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'WFH',
    display_name: TaxProfileField.WFH,
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.HOME_OFFICE_AREA]: {
    question_id: TaxProfileQuestion.HOME_OFFICE_AREA,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'HOME_OFFICE_AREA',
    answered: false,
    answer: {
      [TaxProfileField.HOME_AREA]: 0,
      [TaxProfileField.OFFICE_AREA]: 0,
    },
  },
  [TaxProfileQuestion.PHONE_PCT]: {
    question_id: TaxProfileQuestion.PHONE_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'PHONE_PCT',
    display_name: TaxProfileField.PHONE_PERCENTAGE,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.INTERNET_PCT]: {
    question_id: TaxProfileQuestion.INTERNET_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'INTERNET_PCT',
    display_name: TaxProfileField.INTERNET_PERCENTAGE,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.TRAVEL]: {
    question_id: TaxProfileQuestion.TRAVEL,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'TRAVEL',
    display_name: TaxProfileField.TRAVEL,
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.MILES]: {
    question_id: TaxProfileQuestion.MILES,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'MILES',
    answered: false,
    answer: {
      [TaxProfileField.TOTAL_MILES]: 0,
      [TaxProfileField.BUSINESS_MILES]: 0,
    },
  },
  [TaxProfileQuestion.MEALS]: {
    question_id: TaxProfileQuestion.MEALS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'MEALS',
    display_name: TaxProfileField.MEAL,
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.PERSONAL_DEDUCTIONS]: {
    question_id: TaxProfileQuestion.PERSONAL_DEDUCTIONS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'PERSONAL_DEDUCTIONS',
    display_name: TaxProfileField.PERSONAL_DEDUCTION,
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.FEDERAL_TAX]: {
    question_id: TaxProfileQuestion.FEDERAL_TAX,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'FEDERAL_TAX',
    answered: false,
    answer: {},
  },
  [TaxProfileQuestion.STATE_TAX]: {
    question_id: TaxProfileQuestion.STATE_TAX,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.NESTED_OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'STATE_TAX',
    answered: false,
    answer: {},
  },
  [TaxProfileQuestion.DEPENDENT]: {
    question_id: TaxProfileQuestion.DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'DEPENDENT',
    display_name: TaxProfileField.DEPENDENT,
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.HOME_OFFICE_TXNS]: {
    question_id: TaxProfileQuestion.HOME_OFFICE_TXNS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'HOME_OFFICE_TXNS',
    display_name: 'HOME_OFFICE_TXNS',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.FREELANCE_TIME]: {
    question_id: TaxProfileQuestion.FREELANCE_TIME,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FREELANCE_TIME',
    display_name: 'FREELANCE_TIME',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.W2_INCOME]: {
    question_id: TaxProfileQuestion.W2_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'W2_INCOME',
    display_name: TaxProfileField.W2_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.SPOUSE_FREELANCE_INCOME]: {
    question_id: TaxProfileQuestion.SPOUSE_FREELANCE_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'SPOUSE_FREELANCE_INCOME',
    display_name: TaxProfileField.SPOUSE_FREELANCE_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.SPOUSE_W2_INCOME]: {
    question_id: TaxProfileQuestion.SPOUSE_W2_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'SPOUSE_W2_INCOME',
    display_name: TaxProfileField.SPOUSE_W2_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.FILED_TAXES]: {
    question_id: TaxProfileQuestion.FILED_TAXES,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'FILED_TAXES',
    display_name: 'FILED_TAXES',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.INTENT]: {
    question_id: TaxProfileQuestion.INTENT,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'INTENT',
    display_name: 'INTENT',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.OTHER_SOURCE_INCOME]: {
    question_id: TaxProfileQuestion.OTHER_SOURCE_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'OTHER_SOURCE_INCOME',
    display_name: TaxProfileField.OTHER_SOURCE_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.FILED_TAX_EXTENSION]: {
    question_id: TaxProfileQuestion.FILED_TAX_EXTENSION,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FILED_TAX_EXTENSION',
    display_name: 'FILED_TAX_EXTENSION',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.TAX_DEADLINE_REMINDER]: {
    question_id: TaxProfileQuestion.TAX_DEADLINE_REMINDER,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'TAX_DEADLINE_REMINDER',
    display_name: 'TAX_DEADLINE_REMINDER',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.CAPITAL_GAINS_INCOME]: {
    question_id: TaxProfileQuestion.CAPITAL_GAINS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'CAPITAL_GAINS_INCOME',
    display_name: TaxProfileField.CAPITAL_GAINS_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.INTEREST_INCOME]: {
    question_id: TaxProfileQuestion.INTEREST_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'INTEREST_INCOME',
    display_name: TaxProfileField.INTEREST_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.DIVIDENTS_INCOME]: {
    question_id: TaxProfileQuestion.DIVIDENTS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'DIVIDENTS_INCOME',
    display_name: TaxProfileField.DIVIDENDS_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.GOV_BENEFITS_INCOME]: {
    question_id: TaxProfileQuestion.GOV_BENEFITS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'GOV_BENEFITS_INCOME',
    display_name: TaxProfileField.GOV_BENEFITS_INCOME,
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.FEDERAL_W2_WITHHELD]: {
    question_id: TaxProfileQuestion.FEDERAL_W2_WITHHELD,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'FEDERAL_W2_WITHHELD',
    display_name: 'FEDERAL_W2_WITHHELD',
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.STATE_W2_WITHHELD]: {
    question_id: TaxProfileQuestion.STATE_W2_WITHHELD,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'STATE_W2_WITHHELD',
    display_name: 'STATE_W2_WITHHELD',
    answered: false,
    answer: 0,
  },
  [TaxProfileQuestion.SEPARATE_OFFICE]: {
    question_id: TaxProfileQuestion.SEPARATE_OFFICE,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'SEPARATE_OFFICE',
    display_name: 'SEPARATE_OFFICE',
    answered: false,
    answer: false,
  },
  [TaxProfileQuestion.OTHER_PERSONAL_DEDUCTIONS]: {
    question_id: TaxProfileQuestion.OTHER_PERSONAL_DEDUCTIONS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'OTHER_PERSONAL_DEDUCTIONS',
    display_name: 'OTHER_PERSONAL_DEDUCTIONS',
    answered: false,
    answer: [],
  },
  [TaxProfileQuestion.HOME_ADDRESS_AS_BUSINESS_ADDRESS]: {
    question_id: TaxProfileQuestion.HOME_ADDRESS_AS_BUSINESS_ADDRESS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'HOME_ADDRESS_AS_BUSINESS_ADDRESS',
    display_name: 'HOME_ADDRESS_AS_BUSINESS_ADDRESS',
    answered: false,
    answer: false,
  },
  [TaxProfileQuestion.BUSINESS_ADDRESS]: {
    question_id: TaxProfileQuestion.BUSINESS_ADDRESS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE_LIST,
    tax_profile_fields_key_name: 'BUSINESS_ADDRESS',
    display_name: 'BUSINESS_ADDRESS',
    answered: false,
    answer: {},
  },
  [TaxProfileQuestion.ANNUAL_INCOME_RANGE]: {
    question_id: TaxProfileQuestion.ANNUAL_INCOME_RANGE,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'ANNUAL_INCOME_RANGE',
    display_name: 'ANNUAL_INCOME_RANGE',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.CLAIM_DEPRECATION]: {
    question_id: TaxProfileQuestion.CLAIM_DEPRECATION,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'CLAIM_DEPRECATION',
    display_name: 'CLAIM_DEPRECATION',
    answered: false,
    answer: false,
  },
  [TaxProfileQuestion.SPOUSE_EMAIL]: {
    question_id: TaxProfileQuestion.SPOUSE_EMAIL,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_EMAIL',
    display_name: 'SPOUSE_EMAIL',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.SPOUSE_DOB]: {
    question_id: TaxProfileQuestion.SPOUSE_DOB,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_DOB',
    display_name: 'SPOUSE_DOB',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.PHONE_NUMBER]: {
    question_id: TaxProfileQuestion.PHONE_NUMBER,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'PHONE_NUMBER',
    display_name: 'PHONE_NUMBER',
    answered: false,
    answer: '',
  },
  [TaxProfileQuestion.BUSINESS_TYPE]: {
    question_id: TaxProfileQuestion.BUSINESS_TYPE,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'BUSINESS_TYPE',
    display_name: 'BUSINESS_TYPE',
    answered: false,
    answer: '',
  },
};

export const parseTaxProfileBackendResponse = (
  backendResponse: Array<{question_id: TaxProfileQuestion; answer: any}>,
) => {
  const clonedState: typeof TAX_PROFILE_STRUTURE = _.cloneDeep(
    TAX_PROFILE_STRUTURE,
  );

  try {
    backendResponse.forEach(({question_id, answer}) => {
      // @ts-ignore
      const questionObject = clonedState[question_id];
      if (questionObject === undefined) {
        // return alert(
        //   `ReviewDB frontend is not updated with latest question, this answer to some questions wont be visible (qid: ${question_id})`,
        // );
        return '';
      }
      questionObject.answered = true;
      questionObject.answer = answer;
      return questionObject;
    });
  } catch (e) {
    alert(e);
  }

  return _.groupBy(clonedState, 'section');
};
