import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {
  selectTaxForms,
  selectTaxFormsReducer,
} from 'src/store/taxForms/taxForms.selector';

const basicDetailsRelatedTaxForms = [
  TaxFormType.FORM_1040_NORMAL,
  TaxFormType.FORM_1040_SCHEDULE_C,

  TaxFormType.EIN_LETTER,

  TaxFormType.FORM_1065,
  TaxFormType.FORM_1040_SCH_K1_1065,

  TaxFormType.FORM_1120,
  TaxFormType.FORM_1120_S,

  TaxFormType.DL,
];

interface RelatedDocumentsProps {
  onClick?: (docId: number) => void;
}

const RelatedDocuments = ({onClick}: RelatedDocumentsProps) => {
  const {documents, loaded: documentsLoaded} = useSelector(selectDocuments);
  const {taxForms, loaded: taxformsLoaded} = useSelector(
    selectTaxFormsReducer,
  );

  const relatedTaxForms = taxForms.filter((taxform) =>
    basicDetailsRelatedTaxForms.includes(taxform.formType),
  );

  const linkedDocumentIds = relatedTaxForms.map((taxform) => taxform.docId);

  const relatedDocuments = documents.filter((doc) =>
    linkedDocumentIds.includes(doc.docId),
  );

  const isLoading = !documentsLoaded || !taxformsLoaded;

  return (
    <Box style={{padding: 20, marginInline: 16}} col>
      <Typography style={{fontSize: 16, fontWeight: 600}}>
        Related documents
      </Typography>
      {isLoading ? (
        <Skeleton
          height={40}
          width={'100%'}
          radius={8}
          style={{marginTop: 12}}
        />
      ) : (
        <div style={{display: 'flex', overflowX: 'auto'}}>
          {relatedDocuments.map((doc) => (
            <div style={{marginRight: 8}}>
              <DocumentPointer
                onDocumentClick={
                  onClick ? () => onClick(doc.docId) : undefined
                }
                border
                doc={doc}
                dontShowStatus
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default RelatedDocuments;
