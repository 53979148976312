import React from 'react';
import TaxProfileStep from 'src/CpaCenterV2/TaxProfileReview/components/TaxProfileStep';
import Box from 'src/DesignSystem/Box/Box';
import {PartnerTaxProfileSectionSequence} from 'src/constants/constants';
import {usePartnerTaxProfileReview} from '../../hooks/usePartnerTaxProfileReview';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const PartnerTaxProfileSteps = () => {
  const {
    isSectionReviewPending,
    isSomeSubsectionReviewComplete,
    isSectionComplete,
  } = usePartnerTaxProfileReview();
  const {navigateToTaxProfileSection} = usePartnershipNavigation();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  return (
    <div
      style={{
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 20,
      }}>
      {PartnerTaxProfileSectionSequence.map(({section, stepName}) => {
        const reviewedCompletely = !isSectionReviewPending(section);
        const reviewedPartially =
          !reviewedCompletely && isSomeSubsectionReviewComplete(section);
        const isInfoComplete = isSectionComplete(section);
        return (
          <div style={{marginBottom: 20}}>
            <TaxProfileStep
              status={
                reviewedCompletely
                  ? TaxProfileStep.Step.COMPLETED
                  : reviewedPartially
                  ? TaxProfileStep.Step.IN_PROGRESS
                  : TaxProfileStep.Step.NOT_STARTED
              }
              onClick={() => navigateToTaxProfileSection(section)}
              stepName={stepName}
              tagText={isInfoComplete ? undefined : 'Incomplete'}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PartnerTaxProfileSteps;
