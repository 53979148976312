import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {
  DocumentAssignmentStatus,
  ReturnFields,
  UserRelationType,
} from './CreateReturn.types';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {ManualReturnFieldsConfig} from './CreateReturn.constants';
import {
  PartnersDocOcrDataResponse,
  PartnershipReturnCreationItem,
  PartnershipReturnFields,
} from 'src/appApi.types';
import {cloneDeep, get, isEmpty, keyBy, uniqueId} from 'lodash';
import _ from 'lodash';
import {
  OverWriteFormFieldsFunctionArgsType,
  OverWriteFormFieldsFunctionType,
} from 'src/DesignSystem/Form/useFormData';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {ReduxStateType} from 'src/store/store';
import {
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {OCRBox} from 'src/DesignSystem/OCR/OCR.types';
import {DOCUMENT_TYPES} from 'src/constants/constants';
import {getPath} from 'src/common/utils';

export const createEmptyManualReturnState = () => {
  const form = createEmptyFormDataFromFormConfig(ManualReturnFieldsConfig);
  if (
    typeof form?.[ReturnFields.should_file_individual_return] !== 'boolean'
  ) {
    form[ReturnFields.should_file_individual_return] = true;
  }
  const bizs = form[ReturnFields.businesses];
  if (Array.isArray(bizs)) {
    bizs.forEach((biz) => {
      if (typeof biz?.[ReturnFields.should_file_return] !== 'boolean') {
        biz[ReturnFields.should_file_return] = true;
      }
      biz[ReturnFields.unique_key] = uniqueId('manual-biz');
    });
  }
  return form;
};

export const transformManualReturnStateForBackend = (form: any) => {
  const taxProfile: {question_id: number; answer: any}[] = [];
  taxProfile.push({
    question_id: TaxProfileQuestion.LEGAL_NAME,
    answer: {
      [TaxProfileField.FIRST_NAME]: form[TaxProfileField.FIRST_NAME],
      [TaxProfileField.LAST_NAME]: form[TaxProfileField.LAST_NAME],
    },
  });
  taxProfile.push({
    question_id: TaxProfileQuestion.SSN,
    answer: form[TaxProfileField.SSN],
  });
  taxProfile.push({
    question_id: TaxProfileQuestion.FILING_STATUS,
    answer: form[TaxProfileField.FILING_STATUS],
  });
  if (
    form[TaxProfileField.FILING_STATUS] ===
    FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY
  ) {
    taxProfile.push({
      question_id: TaxProfileQuestion.SPOUSE_SSN,
      answer: form[TaxProfileField.SPOUSE_SSN],
    });
  }
  const bizs = form[ReturnFields.businesses].map((biz: any) => {
    const parsedBiz = {
      ...biz,
    };
    if (ReturnFields.extra_data in parsedBiz) {
      const extraFields = _.get(parsedBiz, ReturnFields.extra_data, {});
      delete parsedBiz[ReturnFields.extra_data];
      Object.assign(parsedBiz, extraFields);
    }
    return parsedBiz;
  });

  const soleBizs = form[ReturnFields.sole_prop_businesses];

  const bizsWithSoleProp =
    Array.isArray(soleBizs) && soleBizs.length > 0
      ? [...bizs, ...soleBizs]
      : bizs;

  return {
    tax_profile: taxProfile,
    years: form[ReturnFields.years],
    partner: form[ReturnFields.partner],
    should_file_individual_return:
      form[ReturnFields.should_file_individual_return],
    businesses: bizsWithSoleProp,
  };
};

export const transformReturnStateForBackend = (form: any) => {
  const user = transformManualReturnStateForBackend(form);
  return {
    [ReturnFields.ocr_job_ids]: [form[ReturnFields.id]],
    ...user,
  };
};

const parsePersonalDetailsFromOCRData = (personalDetails: any) => {
  const fields = [
    TaxProfileField.FILING_STATUS,
    TaxProfileField.FIRST_NAME,
    TaxProfileField.LAST_NAME,
    TaxProfileField.SSN,
    TaxProfileField.SPOUSE_SSN,
  ] as const;
  type Fields = (typeof fields)[number];
  const valueDict = {} as {[key in Fields]: any};
  const valueLocation = {} as {[key in Fields]: any};
  fields.forEach((field) => {
    valueDict[field] = _.get(personalDetails, `${field}.value`, null);
    valueLocation[field] = _.get(personalDetails, `${field}`, null);
  });

  return {...valueDict, [ReturnFields.other_fields_location]: valueLocation};
};

const parseBizDetails = (biz: any) => {
  const fields = [
    BusinessDetailsField.name,
    BusinessDetailsField.ein,
    BusinessDetailsField.entity_type,
  ] as const;
  type Fields = (typeof fields)[number];
  const valueDict = {} as {[key in Fields]: any};
  const valueLocation = {} as {[key in Fields]: any};
  fields.forEach((field) => {
    valueDict[field] = _.get(biz, `${field}.value`, null);
    valueLocation[field] = _.get(biz, `${field}`, null);
  });
  return {
    valueDict,
    valueLocation,
  };
};

const parseOcrDocDataToUserData = (
  docData: PartnersDocOcrDataResponse['tax_docs_data'][0],
): UserRelationType => {
  const bizs: UserRelationType[ReturnFields.businesses] = [];
  const bizsFieldLocation: UserRelationType['biz_fields_location'] = {};
  if (Array.isArray(docData.businesses)) {
    docData.businesses.forEach((biz) => {
      if (
        biz[BusinessDetailsField.entity_type]?.value ===
        BusinessType.SOLE_PROPRIETORSHIP
      ) {
        return;
      }
      const {valueDict, valueLocation} = parseBizDetails(biz);
      bizs.push({
        [ReturnFields.unique_key]: uniqueId('automatic-biz'),
        [ReturnFields.id]: biz[ReturnFields.id],
        ...valueDict,
        [ReturnFields.should_file_return]: true,
        [ReturnFields.extra_data]: _.get(biz, ReturnFields.extra_data, {}),
      });
      bizsFieldLocation[biz[ReturnFields.id]] = valueLocation;
    });
  }

  const {personal_details} = docData;
  return {
    [ReturnFields.id]: docData[ReturnFields.ocr_job_id],
    [ReturnFields.businesses]: bizs,
    [ReturnFields.should_file_individual_return]: true,
    ...parsePersonalDetailsFromOCRData(personal_details),
    [ReturnFields.has_spouse]: false,
    // @ts-ignore
    [ReturnFields.spouse_id]: null,
    [ReturnFields.biz_fields_location]: bizsFieldLocation,
    [ReturnFields.read_url]: docData.read_url,
    [ReturnFields.sole_prop_businesses]:
      docData[ReturnFields.sole_prop_businesses],
  };
};

export const tranformOcrDataToUserRelationsData = (
  data: PartnersDocOcrDataResponse,
): UserRelationType[] => {
  const cloneData = cloneDeep(data.tax_docs_data);
  return cloneData
    .filter(
      (docData) =>
        docData.doc_type === DOCUMENT_TYPES.LAST_YEAR_TAX_RETURN_FORM_1040 &&
        docData.personal_details !== null &&
        !isEmpty(docData.personal_details),
    )
    .map((docData) => {
      const parsedData = parseOcrDocDataToUserData(docData);
      return parsedData;
    });
};

export const tranformRelationsDataToUserDetailsForm = (
  primaryFilers: UserRelationType[],
  backendData: UserRelationType[],
) => {
  const userDetailsForm: {[ReturnFields.users]: UserRelationType[]} = {
    [ReturnFields.users]: [],
  };

  const backendDataById = _.keyBy(backendData, ReturnFields.id);

  for (var i = 0; i < primaryFilers.length; i++) {
    const currFiler = primaryFilers[i];
    const currCopy: UserRelationType = {
      ...currFiler,
    };
    if (
      currFiler[ReturnFields.has_spouse] === true &&
      Number.isInteger(currFiler[ReturnFields.spouse_id])
    ) {
      currCopy[TaxProfileField.FILING_STATUS] =
        FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY;
      currCopy[TaxProfileField.SPOUSE_SSN] =
        backendDataById[currFiler[ReturnFields.spouse_id]][
          TaxProfileField.SSN
        ];
    }
    userDetailsForm[ReturnFields.users].push(currCopy);
  }
  return userDetailsForm;
};

export const overWriteCreateReturnFields = ({
  fields,
  partners,
}: OverWriteFormFieldsFunctionArgsType & {
  partners: ReduxStateType['partners']['partners'];
}) => {
  const fieldsDict = _.keyBy(fields, 'path');

  if (
    fieldsDict[ReturnFields.partner].inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    fieldsDict[ReturnFields.partner].options = partners.map((parnter) => ({
      label: parnter.partner_name,
      value: parnter.id,
    }));
  }

  const fieldsToRemove: string[] = [];
  if (
    fieldsDict[TaxProfileField.FILING_STATUS].value !==
    FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY
  ) {
    fieldsToRemove.push(TaxProfileField.SPOUSE_SSN);
  }
  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

const isValidValueField = (value: any) => {
  const isNotValid = value === undefined || value === null || isEmpty(value);
  return !isNotValid;
};

export const getOcrBoxes = (
  user: UserRelationType | null,
  fields: string[],
): OCRBox[] => {
  const boxes: OCRBox[] = [];
  if (user === null) {
    return boxes;
  }

  fields.forEach((field) => {
    const ocrBox = user?.[ReturnFields.other_fields_location]?.[field];
    if (isValidValueField(ocrBox)) {
      boxes.push({...ocrBox.key_bounding_box, pageNumber: ocrBox.field_page});
      boxes.push({
        ...ocrBox.value_bounding_box,
        pageNumber: ocrBox.field_page,
      });
    }
  });

  return boxes;
};

export const getOcrBoxesForBizDetails = (
  user: UserRelationType | null,
  bizId: string,
  fields: string[],
) => {
  const boxes: OCRBox[] = [];
  if (user === null) {
    return boxes;
  }
  fields.forEach((field) => {
    const ocrBox = user?.[ReturnFields.biz_fields_location]?.[bizId]?.[field];
    if (isValidValueField(ocrBox)) {
      boxes.push({...ocrBox.key_bounding_box, pageNumber: ocrBox.field_page});
      boxes.push({
        ...ocrBox.value_bounding_box,
        pageNumber: ocrBox.field_page,
      });
    }
  });
  return boxes;
};

export const getUsersToAssignDoc = (
  createdReturns: PartnershipReturnCreationItem[],
) => {
  const requiredTPQ = [
    TaxProfileQuestion.SSN,
    TaxProfileQuestion.FILING_STATUS,
  ] as const;
  type TPQKey = (typeof requiredTPQ)[number];
  type TaxProfileMapType = {[key in TPQKey]?: any};

  const groupedByUserId = _.groupBy(
    createdReturns,
    PartnershipReturnFields.fly_user_id,
  );
  return Object.entries(groupedByUserId).map(([userId, userObjs]) => {
    const firstObj = userObjs[0];
    const tpqMap: TaxProfileMapType = {};
    const relevantQuestion = firstObj[
      ReturnFields.tax_profile_questions
    ].filter((q) => requiredTPQ.includes(q.question_id));
    relevantQuestion.forEach((q) => {
      tpqMap[q.question_id as TPQKey] = q.answer;
    });
    return {
      [PartnershipReturnFields.fly_user_id]: parseInt(userId),
      [PartnershipReturnFields.name]: firstObj[PartnershipReturnFields.name],
      [ReturnFields.years]: userObjs.map(
        (user) => user[PartnershipReturnFields.year],
      ),
      [ReturnFields.tax_profile_questions]: tpqMap,
    };
  });
};

export const tranformPendingDocsDataToFormState = (
  pendingDocs: PartnersDocOcrDataResponse['tax_docs_data'],
  users: ReturnType<typeof getUsersToAssignDoc>,
) => {
  const ssnToUserMap = keyBy(
    users,
    getPath(ReturnFields.tax_profile_questions, TaxProfileQuestion.SSN),
  );
  return {
    [ReturnFields.documents]: pendingDocs.map((doc) => {
      let fly_user_id: number | null = null;
      const docSSN = get(
        doc,
        getPath(ReturnFields.personal_details, TaxProfileField.SSN, 'value'),
        null,
      );
      const matchingUserId = get(
        ssnToUserMap,
        getPath(docSSN, PartnershipReturnFields.fly_user_id),
        null,
      ) as number | null;
      if (typeof matchingUserId === 'number') {
        fly_user_id = matchingUserId;
      }
      return {
        [ReturnFields.ocr_job_id]: doc[ReturnFields.ocr_job_id],
        [PartnershipReturnFields.fly_user_id]: fly_user_id,
        [ReturnFields.years]: [],
        [ReturnFields.belongs_to_spouse]: false,
        [ReturnFields.document_assignment_status]:
          fly_user_id === null
            ? DocumentAssignmentStatus.PENDING
            : DocumentAssignmentStatus.REVIEW_PENDING,
      };
    }),
  };
};

export const transformDocAssignStateToBackend = (
  docs: ReturnType<
    typeof tranformPendingDocsDataToFormState
  >[ReturnFields.documents],
) => {
  return docs.map((doc) => {
    return {
      [PartnershipReturnFields.fly_user_id]: doc[
        PartnershipReturnFields.fly_user_id
      ] as number,
      [ReturnFields.ocr_job_id]: doc[ReturnFields.ocr_job_id],
      [ReturnFields.years]: doc[ReturnFields.years] as number[],
      [ReturnFields.belongs_to_spouse]: doc[ReturnFields.belongs_to_spouse],
    };
  });
};
