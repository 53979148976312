import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DSButton from 'src/DesignSystem/Button/Button';
import Skeleton from 'src/icons/Skeleton';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {Integrator} from './CCHAndDrake.types';
import {Autocomplete, TextField, Typography} from '@mui/material';
import FullScreenLoading from 'src/common/FullScreenLoading';
import axios from 'axios';
import {getDrakeConfig, getDrakeFile, postCCHStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import CCHSections from './CCHSections';
import SendToCCHErrorOverlay from './SendToCCHErrorOverlay';

interface CCHAndDrakeProps {
  integrator: Integrator;
  setIntegrator: React.Dispatch<React.SetStateAction<Integrator>>;
}

const CCHAndDrake = ({integrator, setIntegrator}: CCHAndDrakeProps) => {
  const {loaded: cchStatusLoaded, sectionWiseSubsection} =
    useSelector(selectCCHStatus);
  const {loaded: taxReturnsLoaded} = useSelector(selectTaxReturnsReducer);
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const [drakeSelectedSections, setDrakeSelectedSections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSections, setSelectedSections] = useState({});
  const [drakeNameSections, setDrakeNameSections] = useState<{} | null>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [errorOverlay, setErrorOverlay] = useState<{
    error: string;
    isVisible: boolean;
  }>({error: '', isVisible: false});
  const [drakeSections, setDrakeSections] = useState<{} | null>(null);
  const {notify} = useNotify();
  const dispatch = useDispatch();

  const isLoaded = taxReturnsLoaded && cchStatusLoaded;
  const handleOnChange = (e: any, v: any) => {
    setIntegrator(v);
  };

  const fetchDrakeSections = async () => {
    try {
      setIsLoading(true);
      const resp = await getDrakeConfig(userId, activeYear);
      const drakeSectionConfig = resp.data?.config ?? {};
      const drakeNameSectionConfig = Object.assign({}, drakeSectionConfig);
      Object.keys(drakeSectionConfig).forEach((key) => {
        const sectionNameList = drakeSectionConfig[key].map((section) => {
          return `${section.screen_nick} - ${section.screen_desc}`;
        });
        drakeNameSectionConfig[key] = sectionNameList;
      });
      setDrakeSections(drakeSectionConfig);
      setDrakeNameSections(drakeNameSectionConfig);
    } catch (e) {
      notify(`Failed to fetch drake sections ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDrakeSections();
  }, []);

  const downloadDrakeFile = async (bizReturnId?: number) => {
    try {
      setShowLoading(true);
      const drakeSelectedSectionsConfigList = Object.keys(
        drakeSelectedSections,
      ).map((section) => {
        const subSectionNickList = drakeSections[section].map((subSection) => {
          return subSection.screen_nick;
        });
        return [section, subSectionNickList];
      });
      const drakeSelectedSectionsConfig = Object.fromEntries(
        drakeSelectedSectionsConfigList,
      );
      const response = await getDrakeFile(
        userId,
        activeYear,
        bizReturnId,
        drakeSelectedSectionsConfig,
      );

      // Get the filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download.csv'; // Default filename
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        );
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }
      }

      // Create blob URL
      const blob = new Blob([response.data], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);

      // Create temporary link and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup
      window.URL.revokeObjectURL(url);

      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.error('Download failed:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
      }
    }
  };

  const sendToCCH = async () => {
    try {
      setIsLoading(true);
      await postCCHStatus({
        fly_user_id: userId,
        year: activeYear,
        config: selectedSections,
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        setErrorOverlay({
          error: e?.response?.data?.error_msg ?? '',
          isVisible: true,
        });
      }
      notify(`Failed to send to cch ${e}`, NotificationType.error);
    } finally {
      dispatch(fetchCCHStatus(userId, activeYear));
      setIsLoading(false);
    }
  };

  return (
    <div>
      {!isLoaded ? (
        <>
          <Skeleton style={{marginBottom: 24}} height={40} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography style={{fontWeight: 800, fontSize: 18}}>
              Send to CCH/Drake
            </Typography>
            <div style={{display: 'flex'}}>
              <DSButton
                type="secondary"
                disabled={isLoading}
                onClick={() =>
                  integrator === Integrator.DRAKE
                    ? setDrakeSelectedSections({...drakeNameSections})
                    : setSelectedSections({...sectionWiseSubsection})
                }
                text="Select all"
                style={{marginRight: 8}}
              />
              <DSButton
                type="secondary"
                disabled={isLoading}
                onClick={() =>
                  integrator === Integrator.DRAKE
                    ? setDrakeSelectedSections({})
                    : setSelectedSections({})
                }
                text="Clear all"
                style={{marginRight: 8}}
              />
              <Autocomplete
                onChange={handleOnChange}
                disablePortal
                id="combo-box-demo"
                options={Object.values(Integrator)}
                value={integrator}
                fullWidth
                sx={{width: 136}}
                renderInput={(params) => <TextField {...params} />}
              />
              <DSButton
                type="primary"
                disabled={isLoading}
                onClick={
                  integrator === Integrator.DRAKE
                    ? () => downloadDrakeFile()
                    : sendToCCH
                }
                text={
                  integrator === Integrator.DRAKE
                    ? 'Download Drake file'
                    : 'Sync to CCH'
                }
                style={{width: '166px', marginLeft: '8px'}}
              />
              <FullScreenLoading open={showLoading} />
            </div>
          </div>
          <div
            style={{
              overflowY: 'auto',
              height: 'calc(100% - 50px)',
              marginTop: 20,
            }}>
            {drakeNameSections && (
              <CCHSections
                drakeNameSections={drakeNameSections}
                drakeSelectedSections={drakeSelectedSections}
                integrator={integrator}
                setDrakeSelectedSections={setDrakeSelectedSections}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
                isLoading={isLoading}
              />
            )}
          </div>
        </>
      )}
      <SendToCCHErrorOverlay
        isOpen={errorOverlay.isVisible}
        error={errorOverlay.error}
        onClose={() => setErrorOverlay({error: '', isVisible: false})}
      />
    </div>
  );
};

export default CCHAndDrake;
