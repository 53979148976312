import React from 'react';
import {GridRenderEditCellParams} from '@mui/x-data-grid';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import DSButton from 'src/DesignSystem/Button/Button';
import {PartnershipReturnFields} from 'src/appApi.types';
const SeeReturnDetails = ({row}: GridRenderEditCellParams<any, any, any>) => {
  const {navigateToReturnDetails} = usePartnershipNavigation();

  return (
    <DSButton
      type="primary"
      text="View"
      onClick={() => {
        navigateToReturnDetails({
          userId: row[PartnershipReturnFields.fly_user_id],
          year: row[PartnershipReturnFields.year],
          status: row[PartnershipReturnFields.return_status],
        });
      }}
    />
  );
};

export default SeeReturnDetails;
