import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {useCreateReturn} from './useCreateReturn';
import CreateReturnDrawer from './common/CreateReturnDrawer';
import {
  BusinessReturnFieldsConfig,
  ManualReturnFieldsConfig,
} from './CreateReturn.constants';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import _, {isEmpty, uniqueId} from 'lodash';
import {CREATE_RETURN_STATUS, ReturnFields} from './CreateReturn.types';
import {useSelector} from 'react-redux';
import {selectPartners} from 'src/store/partners/partners.selector';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  createEmptyManualReturnState,
  overWriteCreateReturnFields,
  transformManualReturnStateForBackend,
} from './CreateReturn.utils';
import {createPartnerReturns} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import DrawerWhiteContainer from './common/DrawerWhiteContainer';
import UserCard from './common/UserCard';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';

const CreateReturnManuallyDrawer = () => {
  const {
    setCreatedReturns,
    status,
    setStatus,
    fetchExistingUsersData,
    checkDuplicateExists,
  } = useCreateReturn();

  const [validationDisabled, setValidationDisabled] = useState(true);

  const isOpen = [
    CREATE_RETURN_STATUS.CREATE_MANUAL_RETURN,
    CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS,
  ].includes(status);

  useEffect(() => {
    if (!isOpen) {
      setValidationDisabled(true);
    }
  }, [isOpen]);

  const isLoading = [CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS].includes(
    status,
  );

  const {notify} = useNotify();

  const [formData, setFormData] = useState(() => {
    return createEmptyManualReturnState();
  });

  const {partners} = useSelector(selectPartners);

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    (props) => {
      return overWriteCreateReturnFields({...props, partners});
    },
    [partners],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: ManualReturnFieldsConfig,
    data: formData,
    setData: setFormData,
    overWriteFields,
  });

  const onCreateReturns = async ({
    checkDuplicates = true,
  }: {
    checkDuplicates: boolean;
  }) => {
    if (validationDisabled) {
      setValidationDisabled(false); // enable validations
      if (!areAllFieldsValid) {
        return;
      }
    }
    try {
      setStatus(CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS);
      let existingUsers: Awaited<ReturnType<typeof fetchExistingUsersData>> =
        {};
      if (checkDuplicates) {
        existingUsers = await fetchExistingUsersData([
          formData[TaxProfileField.SSN],
        ]);
      }
      if (isEmpty(existingUsers)) {
        const manualTaxReturn = transformManualReturnStateForBackend(formData);
        const response = await createPartnerReturns(manualTaxReturn);
        setFormData(createEmptyManualReturnState());
        setCreatedReturns(response.data);
        setStatus(CREATE_RETURN_STATUS.RETURNS_CREATED);
        notify('Created returns successfully', NotificationType.success);
      } else {
        setStatus(CREATE_RETURN_STATUS.CREATE_MANUAL_RETURN);
        notify('Found duplicate users', NotificationType.warning);
      }
    } catch (e) {
      notify(`Failed to create return: ${e}`, NotificationType.error);
    } finally {
      // remove loader state
    }
  };

  const onAddBusiness = () => {
    setFormData((prev: any) => {
      const newState = {...prev};
      const emptyBiz = createEmptyFormDataFromFormConfig(
        BusinessReturnFieldsConfig,
      );
      emptyBiz[ReturnFields.should_file_return] = true;
      emptyBiz[ReturnFields.unique_key] = uniqueId('manual-biz');
      newState[ReturnFields.businesses] = [
        ...newState[ReturnFields.businesses],
        emptyBiz,
      ];
      return newState;
    });
  };

  const isCreateReturnDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }
    if (validationDisabled) {
      return false;
    }
    return !areAllFieldsValid;
  }, [isLoading, areAllFieldsValid, validationDisabled]);

  return (
    <CreateReturnDrawer
      isOpen={isOpen}
      title="Add new returns"
      onClose={() => setStatus(CREATE_RETURN_STATUS.INIT)}>
      <DrawerWhiteContainer>
        <div
          style={{
            height: 'calc(100vh - 160px)',
            overflowY: 'auto',
          }}>
          <UserCard
            fields={fields}
            onAddBusiness={onAddBusiness}
            user={null}
            validationDisabled={validationDisabled}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}>
          {checkDuplicateExists([formData[TaxProfileField.SSN]]) ? (
            <DSButton
              style={{paddingInline: 20, paddingBlock: 12}}
              text="Create return with duplicates"
              type="secondary"
              onClick={() => onCreateReturns({checkDuplicates: false})}
              textType={TEXT_TYPES.BASE}
              disabled={isCreateReturnDisabled}
            />
          ) : null}
          <DSButton
            style={{paddingInline: 20, paddingBlock: 12}}
            text="Create returns (if no duplicates)"
            type="primary"
            onClick={() => onCreateReturns({checkDuplicates: true})}
            textType={TEXT_TYPES.BASE}
            disabled={isCreateReturnDisabled}
          />
        </div>
      </DrawerWhiteContainer>
    </CreateReturnDrawer>
  );
};

export default CreateReturnManuallyDrawer;
