import React, {useState} from 'react';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {postTaxProfileReviewStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  themmeColor,
} from 'src/constants/constants';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import BackIcon from 'src/icons/BackIcon';
import TickIcon from 'src/icons/TickIcon';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';

interface PartnerSectionHeaderProps {
  sectionName: string;
  section: TaxProfileSection;
}

const PartnerSectionHeader = ({
  sectionName,
  section,
}: PartnerSectionHeaderProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const {
    loaded,
    isSectionReviewPending,
    getNextSection,
    getPreviousSection,
    isSectionComplete,
  } = usePartnerTaxProfileReview();
  const {navigateToTaxProfileSection, navigateToTaxProfileReview} =
    usePartnershipNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const onClickBack = () => {
    navigateToTaxProfileReview();
  };

  const allSubsections = TaxProfileSectionToSubsectionMapping[section];

  const isAnyPending = isSectionReviewPending(section);

  const onClickReviewAll = async () => {
    try {
      setIsLoading(true);
      const completedSectionsBody = {};
      allSubsections.forEach((subsection) => {
        completedSectionsBody[subsection] = TaxProfileReviewStatus.COMPLETE;
      });
      await postTaxProfileReviewStatus(
        userId,
        activeYear,
        completedSectionsBody,
      );
      await dispatch(fetchTaxProfileStatusForGivenYear(userId, activeYear));
      notify(`Successfully reviewed ${sectionName}`, NotificationType.success);
    } catch (e) {
      notify(
        `Error while marking step as reviewed ${e}`,
        NotificationType.error,
      );
    } finally {
      setIsLoading(false);
    }
  };
  const nextSection = getNextSection(section);
  const previousSection = getPreviousSection(section);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBlock: 12,
        paddingRight: 12,
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          onClick={onClickBack}
          style={{
            backgroundColor: themmeColor.black,
            width: 36,
            height: 36,
            borderRadius: 100,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <BackIcon color={themmeColor.white} />
        </div>
        <Typography style={{marginLeft: 12, fontWeight: 800, fontSize: 20}}>
          {sectionName}
        </Typography>
        {previousSection && (
          <DSButton
            startIcon={<ArrowBackIos />}
            text={`${previousSection.stepName}`}
            type={'secondary'}
            style={{marginLeft: 8}}
            onClick={() =>
              navigateToTaxProfileSection(previousSection.section)
            }
          />
        )}
        {nextSection && (
          <DSButton
            endIcon={<ArrowForwardIos />}
            text={`${nextSection.stepName}`}
            type={'secondary'}
            style={{marginLeft: 8}}
            onClick={() => navigateToTaxProfileSection(nextSection.section)}
          />
        )}
      </div>
      <div style={{display: 'flex'}}>
        {isAnyPending ? (
          <DSButton
            type="primary"
            onClick={onClickReviewAll}
            text="Reviewed all"
            disabled={!loaded || isLoading || !isSectionComplete(section)}
          />
        ) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <TickIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerSectionHeader;
