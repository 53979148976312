import _ from 'lodash';
import {useMemo} from 'react';
import {FormConfigProps} from 'src/DesignSystem/Form/Form.types';
import {
  checkFormDataValidity,
  getParsedFields,
  parseFormConfigToFormField,
} from 'src/DesignSystem/Form/Form.utils';

export type OverWriteFormFieldsFunctionArgsType = {
  fields: ReturnType<typeof parseFormConfigToFormField>[];
  data: any;
  setData: any;
};

export type OverWriteFormFieldsFunctionType = (
  props: OverWriteFormFieldsFunctionArgsType,
) => ReturnType<typeof parseFormConfigToFormField>[];

export const useFormData = ({
  config,
  data,
  setData,
  overWriteFields,
}: {
  config: FormConfigProps[];
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  overWriteFields?: OverWriteFormFieldsFunctionType;
}) => {
  const fields = useMemo(() => {
    return getParsedFields(config, data, setData, overWriteFields);
  }, [data, setData, overWriteFields]);

  const areAllFieldsValid = useMemo(() => {
    return checkFormDataValidity(fields);
  }, [data, setData, fields]);

  return {
    fields,
    areAllFieldsValid,
  };
};
