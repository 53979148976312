import React, {useRef} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {themmeColor} from 'src/constants/constants';
import {useCreateReturn} from './useCreateReturn';
import {CREATE_RETURN_STATUS} from './CreateReturn.types';
import {
  convertFileExtensionsToAcceptMimeType,
  getSupportedFiles,
} from 'src/DesignSystem/Dropzone/Dropzone.utils';
import {DEFAULT_SUPPORTED_FILE_EXTENSIONS} from 'src/DesignSystem/Dropzone/Dropzone.constants';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

interface DragFilesOrManualReturnElementProps {}

const DragFilesOrManualReturnElement =
  ({}: DragFilesOrManualReturnElementProps) => {
    const {setStatus, onUploadDocument} = useCreateReturn();
    const inputFile = useRef<HTMLInputElement | null>(null);

    const {notify} = useNotify();

    const openFileExplorer = () => {
      inputFile.current?.click();
    };

    const onSelectFiles: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const files = e.target.files;
      if (files === null) {
        notify('No files were selected', NotificationType.warning);
        return;
      }
      const {supportedFiles} = getSupportedFiles(
        files,
        DEFAULT_SUPPORTED_FILE_EXTENSIONS,
      );
      onUploadDocument(supportedFiles);
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBlock: 14,
          backgroundColor: `${themmeColor.offWhite}4D`,
          marginBottom: 12,
          marginInline: 24,
          borderRadius: 12,
          borderStyle: 'dashed',
          borderWidth: 1,
          borderColor: themmeColor.grey,
        }}>
        <Text
          text="Drag and drop files here to create new user, "
          type={TEXT_TYPES.S}
          fontWeight={FONT_WEIGHTS.SemiBold}
          color={themmeColor.grey}
          containerStyle={{marginRight: 12}}
        />
        <DSButton
          text="Select files"
          type="primary"
          onClick={openFileExplorer}
        />
        <Text
          text=" or "
          type={TEXT_TYPES.S}
          fontWeight={FONT_WEIGHTS.SemiBold}
          color={themmeColor.grey}
          containerStyle={{marginInline: 12}}
        />
        <DSButton
          text="Add details manually"
          type="primary"
          onClick={() => setStatus(CREATE_RETURN_STATUS.CREATE_MANUAL_RETURN)}
        />
        <input
          type="file"
          id="file-picker"
          ref={inputFile}
          style={{display: 'none'}}
          accept={convertFileExtensionsToAcceptMimeType(
            DEFAULT_SUPPORTED_FILE_EXTENSIONS,
          )}
          multiple
          onChange={onSelectFiles}
        />
      </div>
    );
  };

export default DragFilesOrManualReturnElement;
