import React, {ComponentProps} from 'react';
import {useStatesLived} from 'src/CpaCenterV2/hooks/useStatesLived';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import EditIcon from 'src/icons/EditIcon';
import RightChveron from 'src/icons/RightChveron';
import {
  setEditTaxProfileQuestions,
  setStatesInfoDrawer,
} from 'src/store/app/app.actions';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import {useDispatch} from 'react-redux';
import {getPath} from 'src/common/utils';
import {
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {
  IncomeEarnerType,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';

interface StatesLivedProps {
  allowEdit?: boolean;
}

const StatesLived = ({allowEdit = false}: StatesLivedProps) => {
  const {
    selfStateLivedTableData,
    spouseStateLivedTableData,
    isSpouseApplicable,
    isSpousePeriodsSame,
    isLoading,
    onChangeSpousePeriodsAreSame,
  } = useStatesLived();
  const dispatch = useDispatch();
  const {onEditTaxForm, onAddTaxForm} = usePartnerTaxProfileReview();

  const columns: ComponentProps<typeof KeyValueTable>['keys'] = [
    {
      getValue: (row: (typeof selfStateLivedTableData)[0]) => row.displayName,
    },
    {
      getValue: (row: (typeof selfStateLivedTableData)[0]) =>
        `${row.daysCount} days`,
      cellStyle: {
        justifyContent: 'flex-end',
        display: 'flex',
      },
      textStyle: {
        fontWeight: 600,
      },
    },
    {
      getValue: () => ' ',
      onClick: (row: (typeof selfStateLivedTableData)[0]) => {
        if (!allowEdit) {
          dispatch(
            setStatesInfoDrawer({
              isVisible: true,
              isSpouse: row.isSpouse,
              isDefault: row.isDefault,
              taxFormId: row.taxFormId,
            }),
          );
          return;
        }

        if (row.isDefault) {
          // Edit tax profile
          const path = getPath(
            TaxProfileQuestion.HOME_ADDRESS,
            row.isSpouse
              ? TaxProfileField.SPOUSE_PERIODS
              : TaxProfileField.PERIODS,
          );
          dispatch(
            setEditTaxProfileQuestions({
              pathPrefixes: [path],
              title: `Edit ${row.displayName} Residency dates`,
            }),
          );
        } else {
          // Edit tax form
          if (row.taxFormId !== null) {
            onEditTaxForm({
              taxFormId: row.taxFormId,
              taxFormType: TaxFormType.STATES_LIVED,
              title: `Edit ${row.displayName}`,
            });
          }
        }
      },
      cellStyle: {
        justifyContent: 'flex-end',
        display: 'flex',
        flex: 0,
        marginLeft: 12,
      },
      noOnClickIcon: true,
      getIcon: () => (allowEdit ? <EditIcon /> : <RightChveron />),
    },
  ];

  const onAddStates = (isSpouse: boolean = false) => {
    onAddTaxForm({
      taxFormType: TaxFormType.STATES_LIVED,
      title: 'Add state',
      prefilledFormData: {
        [TaxFormFieldIds.INCOME_EARNER]: isSpouse
          ? IncomeEarnerType.MY_SPOUSE
          : IncomeEarnerType.MYSELF,
      },
    });
  };

  return (
    <>
      <FullScreenLoading open={isLoading} />
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text
          type={TEXT_TYPES.BASE}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={'Tax payer States'}
        />
        {allowEdit ? (
          <DSButton
            type="secondary"
            text="Add details"
            onClick={() => {
              // show add state lived tax form for tax payers
              onAddStates();
            }}
            style={{marginBottom: 8}}
          />
        ) : null}
      </div>
      <KeyValueTable keys={columns} data={selfStateLivedTableData} />
      {isSpouseApplicable ? (
        <div style={{marginTop: 12, display: 'flex', flexDirection: 'column'}}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text
              type={TEXT_TYPES.BASE}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={'Spouse States'}
            />
            {allowEdit ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 8,
                }}>
                <Form.SwitchField
                  title="Fill same details of Tax filer"
                  onChangeValue={onChangeSpousePeriodsAreSame}
                  value={isSpousePeriodsSame}
                />
                <DSButton
                  type="secondary"
                  text="Add details"
                  onClick={() => {
                    // show add state lived tax form for spouse payers
                    onAddStates(true);
                  }}
                  style={{marginLeft: 8}}
                  disabled={isSpousePeriodsSame}
                />
              </div>
            ) : null}
          </div>
          {isSpousePeriodsSame ? (
            <Text
              type={TEXT_TYPES.S}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={'Spouse periods are same as Tax payer'}
            />
          ) : (
            <KeyValueTable keys={columns} data={spouseStateLivedTableData} />
          )}
        </div>
      ) : null}
    </>
  );
};

export default StatesLived;
