import {VALID_DOCUMENT_STATUS, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {Button, Typography} from '@mui/material';
import {
  addTaxForm,
  deleteTaxForm,
  getDocumentOcrData,
  postDocumentStatus,
  updateTaxForm,
} from 'src/appApi';
import {AddOutlined, ArrowBackIos} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import AddMoreDocumentsOverlay, {
  SelectedDocTypeProps,
} from '../../components/AddMoreDocumentsOverlay';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {
  FormType,
  OCR_STATUS,
  backendFormsResponseMapper,
} from '../../CpaCenterDocumentOCR.utils';
import {makeStyles} from '@mui/styles';
import Form from './components/Form';
import {Document} from '../../DocumentsContext';

const useStyles = makeStyles({
  header: {
    padding: '10px',
    background: ({status}) =>
      status === VALID_DOCUMENT_STATUS.ACCEPTED
        ? themmeColor.offWhite
        : themmeColor.successGreen,
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    height: '16px',
    color: themmeColor.grey,
    alignSelf: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginTop: '10px',
  },
  addForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  reviewComplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '10px 0px',
  },
});

interface ReviewOcrDataProps {
  flyfinUserId: string;
  document: Document;
  fetchDocuments: () => Promise<void>;
}

const ReviewOcrData: React.FC<ReviewOcrDataProps> = ({
  flyfinUserId,
  document,
  fetchDocuments,
}) => {
  const [forms, setForms] = useState<{[id: number]: FormType}>({});
  const [ocrStatus, setOcrStatus] = useState<string>('');
  const [isLoading, setLoading] = useState(true);
  const [showAddFormOverlay, setShowAddFormOverlay] = useState(false);

  const addFormOptions = Object.values(TaxFormType).map((docType) => ({
    docType,
    label: docType,
  }));

  const getForms = async () => {
    const res = await getDocumentOcrData({
      fly_user_id: parseInt(flyfinUserId),
      tfd_info_id: document.documentId,
    });
    if (res.data.ocr_status === OCR_STATUS.DONE) {
      const formData = backendFormsResponseMapper(res.data.forms);
      setForms(formData);
    }
    setOcrStatus(res.data.ocr_status);
  };

  useEffect(() => {
    getForms();
    setLoading(false);
  }, [document]);

  const handleAddFormOverlayClose = () => {
    setShowAddFormOverlay(false);
  };

  const onBackIconClick = async () => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: document.documentId ?? -1,
        status: VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW,
      });
      await fetchDocuments();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const addForm = async (
    comment: string,
    selectedDocType: SelectedDocTypeProps | null,
  ) => {
    try {
      setLoading(true);
      const res = await addTaxForm({
        fly_user_id: parseInt(flyfinUserId),
        tfd_info_id: document.documentId,
        form_type: selectedDocType ? selectedDocType.docType : '',
      });
      const formData = backendFormsResponseMapper([res.data]);
      setForms((prev) => {
        return {...prev, ...formData};
      });
    } catch (e) {
      alert(`Error Occurred ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFormDelete = async (formId: number) => {
    try {
      await deleteTaxForm({tax_form_id: formId});
      setForms((prev) => {
        const prevState = prev;
        delete prevState[formId];
        return {...prevState};
      });
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  const handleFormValueChange = (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => {
    setForms((prev) => {
      let currForm = prev[formId];
      let currFormField = currForm.form_data[fieldKey];

      if (valueIndex !== -1) {
        currFormField.field_values[fieldValueIndex].value[valueIndex] = value;
      } else {
        currFormField.field_values[fieldValueIndex].value = value;
      }

      currForm[fieldKey] = currFormField;

      return {
        ...prev,
        [formId]: currForm,
      };
    });
  };

  const onReviewCompleteClick = async () => {
    setLoading(true);
    try {
      const formDataToSend = Object.keys(forms).map((formId) => {
        let formOcrData = {};
        Object.keys(forms[Number(formId)].form_data).forEach((fieldKey) => {
          formOcrData[fieldKey] = {
            field_values:
              forms[Number(formId)].form_data[fieldKey].field_values,
          };
        });
        return {
          id: Number(formId),
          form_data: formOcrData,
        };
      });

      await updateTaxForm({
        fly_user_id: parseInt(flyfinUserId),
        tfd_info_id: document.documentId,
        forms: formDataToSend,
      });
      await fetchDocuments();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const styles = useStyles({status: document.status});

  return (
    <>
      {isLoading && <FullScreenLoading open={isLoading} />}
      <div>
        <div className={styles.header}>
          {document.status === VALID_DOCUMENT_STATUS.ACCEPTED && (
            <div
              style={{display: 'flex', alignItems: 'center'}}
              onClick={() => onBackIconClick()}>
              <ArrowBackIos className={styles.headerIcon} />
            </div>
          )}
          <Typography fontSize={'16px'} fontWeight="600">
            {document.status === VALID_DOCUMENT_STATUS.ACCEPTED
              ? 'Step 2: Review Information'
              : 'OCR Review Complete'}
          </Typography>
        </div>
        <div className={styles.content}>
          {ocrStatus === OCR_STATUS.IN_PROGRESS ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              OCR IS IN PROGRESS....
            </div>
          ) : (
            <>
              <div style={{overflowY: 'scroll'}}>
                {Object.keys(forms).map((formId) => {
                  return (
                    <div style={{padding: '10px'}}>
                      <Form
                        form={forms[Number(formId)]}
                        isImage={document.isImage}
                        key={formId}
                        setForms={setForms}
                        setLoading={setLoading}
                        handleDelete={handleFormDelete}
                        handleFormValueChange={handleFormValueChange}
                      />
                    </div>
                  );
                })}
                {document.status === VALID_DOCUMENT_STATUS.ACCEPTED && (
                  <div className={styles.addForm}>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => setShowAddFormOverlay(true)}
                      endIcon={
                        <AddOutlined style={{color: themmeColor.darkBlue}} />
                      }
                      style={{
                        background: themmeColor.offWhite,
                        borderRadius: '64px',
                      }}>
                      <Typography
                        fontWeight={'500'}
                        fontSize={'13px'}
                        fontStyle={'Inter'}
                        color={themmeColor.darkBlue}>
                        Add Document
                      </Typography>
                    </Button>
                  </div>
                )}
              </div>
              {document.status === VALID_DOCUMENT_STATUS.ACCEPTED && (
                <div className={styles.reviewComplete}>
                  <Button
                    style={{
                      borderRadius: 8,
                      padding: '5px 30px',
                    }}
                    disabled={forms.length === 0}
                    onClick={onReviewCompleteClick}
                    variant="contained">
                    Review Complete
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <AddMoreDocumentsOverlay
        isOpen={showAddFormOverlay}
        onClose={handleAddFormOverlayClose}
        options={addFormOptions}
        title="Add Document Type"
        subTitle="Please select the document type that you want to add information about"
        showCommentInput={false}
        sendButtonText="Add"
        handleSend={addForm}
      />
    </>
  );
};

export default ReviewOcrData;
