import {useEffect, useMemo, useState} from 'react';
import {
  AccordionDetails,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {getQtcData, getTaxProfile, getTransactions} from 'src/appApi';
import {QtcInputData} from 'src/appApi.types';
import EndUserQtcInput from 'src/UserQueries/components/EndUserQtcInput';
import AIQtcInput from 'src/UserQueries/components/AIQtcInput';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {
  QuarterlyPaymentType,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import QtcPayments from 'src/UserQueries/components/QtcPayments';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import {parseSingleTransaction} from 'src/store/transactions/transactions.utils';

interface QtcInputsOverlayProps {
  onClose: () => void;
  isOpen: boolean;
  flyfinUserId: number;
  year: number;
}

const QtcInputsOverlay = ({
  onClose,
  isOpen,
  flyfinUserId,
  year,
}: QtcInputsOverlayProps) => {
  const [loading, setLoading] = useState(true);
  const [qtcData, setQtcData] = useState<QtcInputData | null>(null);
  const [taxProfile, setTaxProfile] = useState<{[key: string]: any}>({});
  const [txnData, setTxnData] = useState({});

  const fetchOnMount = async () => {
    try {
      const [qtcDataResponse, transactionsResponse, taxProfileResponse] =
        await Promise.all([
          getQtcData({
            fly_user_id: flyfinUserId,
          }),
          getTransactions({user_id: flyfinUserId, year: [year]}),
          await getTaxProfile({
            fly_user_id: flyfinUserId,
            years: year,
          }),
        ]);

      setQtcData(qtcDataResponse.data);

      const transactionIdMap: {[key: number]: Transaction} = {};
      transactionsResponse.data.forEach((backendTxn) => {
        const txn = parseSingleTransaction(backendTxn);
        transactionIdMap[txn.txnId] = txn;
      });
      setTxnData(transactionIdMap);

      const taxProfileData = taxProfileResponse.data[year];
      const taxProfileMap: {[key: string]: any} = {};
      taxProfileData.forEach((backendTPQ) => {
        taxProfileMap[backendTPQ.question_id] = backendTPQ.answer;
      });
      setTaxProfile(taxProfileMap);
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const groupedQtcPayments = useMemo(() => {
    const groupedForms: {[key: string]: GenericTaxForm[]} = {};
    if (qtcData != null) {
      qtcData.qt_payments.forEach((form) => {
        const {
          is_end_user_reviewed: isEndUserReviewed,
          doc_link: docLink,
          form_data: formData,
          form_type: formType,
          id: taxFormId,
          tfd_info_id: docId,
        } = form;
        const transformedForm = {
          isEndUserReviewed,
          docId,
          docLink,
          formData,
          formType,
          taxFormId,
        };
        if (
          form.form_data[TaxFormFieldIds.PAYMENT_TYPE] ===
          QuarterlyPaymentType.FEDERAL
        ) {
          groupedForms['Federal'] = [
            ...(groupedForms['Federal'] ?? []),
            transformedForm,
          ];
        } else if (
          form.form_data[TaxFormFieldIds.PAYMENT_TYPE] ===
          QuarterlyPaymentType.STATE
        ) {
          groupedForms[form.form_data[TaxFormFieldIds.STATE]] = [
            ...(groupedForms[form.form_data[TaxFormFieldIds.STATE]] ?? []),
            transformedForm,
          ];
        }
      });
    }

    return groupedForms;
  }, [qtcData]);

  useEffect(() => {
    if (isOpen) {
      fetchOnMount();
    }
  }, [isOpen]);

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          padding: 20,
          borderRadius: 12,
          width: '80%',
          height: '80%',
          overflow: 'scroll',
        }}>
        {!loading ? (
          <>
            {qtcData != null && (
              <>
                {/* CALCULATED BY USER */}
                {qtcData.end_user.map((endUserData) => {
                  return (
                    <div style={{marginTop: 20}}>
                      <EndUserQtcInput
                        totalTaxAmount={endUserData.total_tax_to_pay ?? 0}
                        calculationDate={endUserData.created_at ?? ''}
                        federalTaxData={endUserData.federal_tax_details ?? {}}
                        stateTaxData={Object.values(
                          endUserData.state_tax_details ?? {},
                        )}
                        qtcInputData={
                          endUserData.input_data?.tax_profile ?? {}
                        }
                        totalBizDeductions={
                          endUserData.input_data?.biz_deductions ?? 0
                        }
                        taxProfile={taxProfile}
                      />
                    </div>
                  );
                })}

                {/* CALCULATED BY AI */}
                {qtcData.tax_form?.calculated_by != null && (
                  <div style={{marginTop: 20}}>
                    <AIQtcInput
                      totalTaxAmount={qtcData.tax_form?.total_tax_to_pay ?? 0}
                      calculationDate={qtcData.tax_form?.calculated_date ?? ''}
                      federalTaxData={
                        qtcData.tax_form?.federal_tax_details ?? {}
                      }
                      stateTaxData={Object.values(
                        qtcData.tax_form?.state_tax_details ?? {},
                      )}
                    />
                  </div>
                )}

                {/* QUATERLY PAYMENTS */}
                {!_.isEmpty(groupedQtcPayments) && (
                  <div
                    style={{
                      marginTop: 20,
                      background: themmeColor.offGrey,
                      borderRadius: 20,
                      padding: 16,
                    }}>
                    <Typography style={{fontWeight: '600', fontSize: 20}}>
                      Quarterly tax payments
                    </Typography>
                    <div style={{marginTop: 20}}>
                      {Object.entries(groupedQtcPayments).map(
                        ([key, forms]) => {
                          return (
                            <div style={{marginTop: 12}}>
                              <Typography
                                style={{fontWeight: '600', fontSize: 16}}>
                                {`${
                                  stateAbbreviationNameMap[key] ?? 'Federal'
                                } tax payments`}
                              </Typography>
                              <div style={{marginTop: 4}}>
                                <QtcPayments
                                  forms={forms}
                                  transactionIdMap={txnData}
                                />
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QtcInputsOverlay;
