import {
  FORM_INPUT_TYPE,
  FormConfigProps,
} from 'src/DesignSystem/Form/Form.types';
import {EditTaxFormConfigMap} from './taxForms.constants';
import {
  GenericTaxForm,
  OverWriteFormDataExtraParams,
  QuarterlyPaymentType,
  TaxFormFieldIds,
  TaxFormType,
} from './taxForms.types';
import {
  OverWriteFormFieldsFunctionArgsType,
  OverWriteFormFieldsFunctionType,
} from 'src/DesignSystem/Form/useFormData';
import _, {cloneDeep, get} from 'lodash';
import {
  isSchoolDistrictCodeRequired,
  stateAbbreviationNameMap,
  stateNameToAbbreviationMap,
} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {
  getCountyNameAndCodeMapForState,
  getPath,
  isCountyRequired,
} from 'src/common/utils';
import {
  FILING_STATUS_ANSWER,
  TaxProfileField,
  TaxProfileQuestion,
} from '../taxProfile/taxProfile.types';
import {INCOME_EARNER_VALUES} from 'src/CpaCenterV2/TaxProfileSections/Income/Income.constants';

const overwriteNoop: OverWriteFormFieldsFunctionType = ({fields}) => {
  return fields;
};

const overwriteTaxPayment: OverWriteFormFieldsFunctionType = ({
  fields,
  data,
}) => {
  const fieldsToRemove: string[] = [];
  if (data[TaxFormFieldIds.PAYMENT_TYPE] !== QuarterlyPaymentType.STATE) {
    fieldsToRemove.push(TaxFormFieldIds.STATE);
  }
  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

const overwriteStateLivedTaxForm = (
  {fields, data, setData}: OverWriteFormFieldsFunctionArgsType,
  extraData: OverWriteFormDataExtraParams,
) => {
  const fieldsToRemove: string[] = [];
  const stateAbbrevation = data[TaxFormFieldIds.STATE];
  const stateName = stateAbbreviationNameMap[stateAbbrevation];
  const {taxprofileMap, taxforms} = extraData;

  const filingStatus = taxprofileMap[TaxProfileQuestion.FILING_STATUS];
  const isSpousePeriodsAreSame =
    taxprofileMap[TaxProfileQuestion.SPOUSE_PERIODS_ARE_SAME] !== false;

  const fieldByPath = _.keyBy(fields, 'path');

  const earner = data[TaxFormFieldIds.INCOME_EARNER];
  const isSelfEarner = earner === INCOME_EARNER_VALUES.MYSELF;
  const selectedState = data[TaxFormFieldIds.STATE];

  if (
    [INCOME_EARNER_VALUES.MYSELF, INCOME_EARNER_VALUES.MY_SPOUSE].includes(
      earner,
    )
  ) {
    const tpState = get(
      taxprofileMap,
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE, '[0]'),
      null,
    );
    let tpStateAbb: null | string = null;
    if (typeof tpState === 'string') {
      tpStateAbb = stateNameToAbbreviationMap[tpState] ?? null;
    }

    const earnerTaxFormStates = taxforms
      .filter((tf) => {
        if (tf.formType !== TaxFormType.STATES_LIVED) {
          return false;
        }
        if (
          isSelfEarner &&
          tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
            INCOME_EARNER_VALUES.MYSELF
        ) {
          return true;
        }
        if (
          !isSelfEarner &&
          tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
            INCOME_EARNER_VALUES.MY_SPOUSE
        ) {
          return true;
        }
        return false;
      })
      .map((tf) => tf.formData[TaxFormFieldIds.STATE]);

    const statesToExclude: string[] = [];
    if (tpStateAbb !== null && selectedState !== tpStateAbb) {
      // if state is same as tax profile and not currently selected, remove from options
      statesToExclude.push(tpStateAbb);
    }
    earnerTaxFormStates.forEach((tfState) => {
      if (typeof tfState === 'string' && tfState !== selectedState) {
        statesToExclude.push(tfState);
      }
    });
    const stateField = fieldByPath[TaxFormFieldIds.STATE];
    if (stateField && stateField.inputType === FORM_INPUT_TYPE.State) {
      stateField.excludeStates = statesToExclude;
    }
  }

  const stateField = fieldByPath[TaxFormFieldIds.STATE];
  if (stateField && stateField.inputType === FORM_INPUT_TYPE.State) {
    stateField.onChangeValue = (newValue) => {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.STATE]: newValue,
          [TaxFormFieldIds.COUNTY]: null,
        };
      });
    };
  }

  const incomeEarnerField = fieldByPath[TaxFormFieldIds.INCOME_EARNER];
  if (
    incomeEarnerField &&
    incomeEarnerField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    incomeEarnerField.onChangeValue = (newVal) => {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.STATE]: null,
          [TaxFormFieldIds.COUNTY]: null,
          [TaxFormFieldIds.INCOME_EARNER]: newVal,
        };
      });
    };
  }

  if (
    filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY ||
    isSpousePeriodsAreSame
  ) {
    if (
      incomeEarnerField &&
      incomeEarnerField.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      incomeEarnerField.options = [
        {
          label: INCOME_EARNER_VALUES.MYSELF,
          value: INCOME_EARNER_VALUES.MYSELF,
        },
      ];
    }
    if (data[TaxFormFieldIds.INCOME_EARNER] !== INCOME_EARNER_VALUES.MYSELF) {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.INCOME_EARNER]: INCOME_EARNER_VALUES.MYSELF,
        };
      });
    }
  }

  if (typeof stateName !== 'string' || !isCountyRequired(stateName)) {
    fieldsToRemove.push(TaxFormFieldIds.COUNTY);
  } else {
    const countyField = fieldByPath[TaxFormFieldIds.COUNTY];
    if (
      countyField &&
      countyField.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      countyField.options = Object.entries(
        getCountyNameAndCodeMapForState(stateName),
      ).map(([countyName, countyCode]) => {
        return {
          label: countyName,
          value: countyCode,
        };
      });
    }
  }

  if (
    typeof stateName !== 'string' ||
    !isSchoolDistrictCodeRequired(stateName)
  ) {
    fieldsToRemove.push(TaxFormFieldIds.SCHOOL_DISTRICT_CODE);
  }

  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

export const getEditTaxFormConfig = (
  taxFormType: TaxFormType,
): FormConfigProps[] => {
  if (EditTaxFormConfigMap[taxFormType] !== undefined) {
    return EditTaxFormConfigMap[taxFormType] ?? [];
  }
  return [];
};

export const getOverwriteTaxForm = (
  taxFormType: TaxFormType | null,
  extraData: OverWriteFormDataExtraParams,
): OverWriteFormFieldsFunctionType => {
  if (taxFormType === TaxFormType.QUARTERLY_TAX_PAYMENTS) {
    return overwriteTaxPayment;
  }
  if (taxFormType === TaxFormType.STATES_LIVED) {
    return (props) => overwriteStateLivedTaxForm(props, extraData);
  }
  return overwriteNoop;
};

export const cloneTaxFormForBackend = (
  taxform: GenericTaxForm,
  overwriteFormData: any = {},
): {
  id: number;
  form_type: TaxFormType;
  form_data: any;
  year: number;
} => {
  const clone = cloneDeep(taxform);

  return {
    id: clone.taxFormId,
    form_type: clone.formType,
    form_data: {...clone.formData, ...overwriteFormData},
    year: clone.year,
  };
};
