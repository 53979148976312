export enum TaxProfileQuestion {
  LEGAL_NAME = 1,
  SSN = 2,
  HOME_ADDRESS = 3,
  CLAIM_YOU_DEPENDENT = 4,
  FILING_STATUS = 5,
  SPOUSE_LEGAL_NAME = 6,
  SPOUSE_SSN = 7,
  SPOUSE_CLAIMED_DEPENDENT = 8,
  DEPENDENT_DETAILS = 9,
  EARNING_STATES = 10,
  INCOME_SOURCES = 11,
  YOUR_N_SPOUSE_INCOME_SOURCES = 12,
  FREELANCE_EARNINGS = 13,
  RETIREMENT_PLAN_CONTRIBUTIONS = 14,
  DEDUCTION_OPTION = 15,
  ITEMIZE_DEDUCTION = 16,
  STANDARD_DEDUCTION = 17,
  ESTIMATED_TAX_PAYMENTS = 18,
  RECEIVED_ADV_CHILD_TAX_CREDITS = 19,
  BUSINESS_DETAILS = 20,
  WORK_TYPE = 21,
  PROFESSION = 22,
  WFH = 23,
  HOME_OFFICE_AREA = 24,
  PHONE_PCT = 25,
  INTERNET_PCT = 26,
  TRAVEL = 27,
  MILES = 28,
  MEALS = 29,
  PERSONAL_DEDUCTIONS = 30,
  FEDERAL_TAX = 31,
  STATE_TAX = 32,
  DEPENDENT = 33,
  HOME_OFFICE_TXNS = 34,
  PHONE_INTERNET_PCT = 35,
  FREELANCE_TIME = 36,
  SPOUSE_FREELANCE_INCOME = 37,
  SPOUSE_W2_INCOME = 38,
  FILED_TAXES = 39,
  INTENT = 40,
  OTHER_SOURCE_INCOME = 41,
  FILED_TAX_EXTENSION = 42,
  TAX_DEADLINE_REMINDER = 43,
  W2_INCOME = 44,
  CAPITAL_GAINS_INCOME = 45,
  INTEREST_INCOME = 46,
  DIVIDENTS_INCOME = 47,
  GOV_BENEFITS_INCOME = 48,
  W2_WORK = 49,
  CPA_ADVICE_ON_CATEGORIES = 50,
  CPA_HELP = 51,
  SCHEDULE_A_DEDUCTIONS = 52,
  W2_WITHHELD = 53,
  SEPARATE_OFFICE = 54,
  OTHER_PERSONAL_DEDUCTIONS = 55,
  HOME_ADDRESS_AS_BUSINESS_ADDRESS = 56,
  BUSINESS_ADDRESS = 57,
  ANNUAL_INCOME_RANGE = 58,
  CLAIM_DEPRECATION = 59,
  SPOUSE_EMAIL = 60,
  SPOUSE_DOB = 61,
  PHONE_NUMBER = 62,
  BUSINESS_TYPE = 63,
  FEDERAL_W2_WITHHELD = 64,
  STATE_W2_WITHHELD = 65,
  FEDERAL_TAX_PAID = 66,
  STATE_TAX_PAID = 67,
  PREV_MONTH_INCOMES = 68,
  LIVING_OUTSIDE_USA = 69,
  MAILING_ADDRESS_OUTSIDE_USA = 70,
  SPOUSE_FREELANCER = 71,
  DOB = 72,
  REASON_FOR_NO_FREELANCE_INCOME = 73,
  SUGGESTED_INCOMES = 74,
  TAX_PROFILE_INCOME_SOURCES = 75,
  INCOMES_TO_ADD_LATER = 76,
  SKIP_LAST_YEAR_1040_REASON = 77,
  LAST_YEAR_1040_DE_CATEGORIES = 78,
  SPOUSE_QUARTERLY_TAX_PAYMENT = 79,
  APPLICABLE_CHILD_CREDITS = 80,
  HAVE_MORTGAGE_FOR_OFFICE = 81,
  PAID_STUDENT_LOAN = 82,
  TOOK_BUSINESS_LOAN = 83,
  TOOK_BNPL_SERVICES = 84,
  PAID_WAGES_EMP_BENEFITS = 85,
  PAID_SELF = 86,
  IRA_CONTRIBUTIONS = 87,
  PAID_HEALTH_INS_PREMIUM = 88,
  IS_HEALTH_INS_COMBINED = 89,
  HEALTH_INS_COMBINED_MONTHS = 90,
  IS_HEALTH_INS_MARKET_PURCHASED = 91,
  HOME_OFFICE_EXPENSE_CHOICE = 92,
  USED_PHONE_INTERNET_FOR_BUSINESS = 93,
  SELF_EDUCATIONAL_CREDITS = 94,
  SPOUSE_EDUCATIONAL_CREDITS = 95,
  IS_STUDENT = 96,
  IS_SPOUSE_STUDENT = 97,
  CLAIMED_HSA_CONTRIBUTIONS = 98,
  CLAIMED_TRADITIONAL_IRA = 99,
  CLAIMED_ROTH_IRA = 100,
  CLAIMED_SEP_SIMPLE_PLANS = 101,
  VEHICLE_USED_FOR_BUSINESS = 102,
  DEPRECIATION_FOUND_IN_LAST_YEAR_1040 = 103,
  CASUALTY_N_THEFT_LOSSES = 104,
  INTEREST_ON_INVESTMENT_LOANS = 105,
  GAMBLING_LOSSES = 106,
  LIVED_IN_USA_IN_TAX_YEAR = 107,
  EARNED_FROM_DIGITAL_ASSETS = 108,
  DONT_HAVE_BUSINESS_DETAILS = 109,
  SPOUSE_FEDERAL_W2_WITHHELD = 111,
  SPOUSE_STATE_W2_WITHHELD = 112,
  BUSINESS_FORMED_OR_REGISTERED_IN_USA = 114,
  BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024 = 115,
  BUSINESS_LLC_OR_C_CORP = 116,
  BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY = 117,
  SELECT_POINTS_APPLICABLE_FOR_BUSINESS = 118,
  BUSINESS_COMES_UNDER_GIVEN_CATEGORIES = 119,
  IS_BOI_REQUIRED = 122,

  SELF_IP_PIN = 124,
  SPOUSE_IP_PIN = 125,
  HAS_IP_PIN = 126,

  SPOUSE_PERIODS_ARE_SAME = 127,
  ST_HAVE_HEALTH_INS_COVERAGE = 128,
  ST_HEALTH_INS_PEOPLE_COVERED = 129,
  ST_HEALTH_INS_MONTHS_COVERED = 130,
  ST_IS_HEALTH_INS_MARKETPLACE = 131,
  ST_HEALTH_INS_TYPE = 132,
  ST_HEALTH_INS_GOV_PLAN = 133,
  WHY_MFT_NEEDED = 134,

  IS_ELIGIBLE_FOR_EDUCATION_CREDIT = 135,
  IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT = 136,
}

export enum TaxProfileField {
  FIRST_NAME = 'first_name_and_middle_initial',
  LAST_NAME = 'last_name',
  SSN = 'ssn',
  STREET_NUMBER = 'number_and_street',
  APARTMENT_NUMBER = 'apartment_number',
  CITY = 'city',
  STATE = 'state',
  COUNTY = 'county',
  ZIP_CODE = 'zip_code',
  SCHOOL_DISTRICT_CODE = 'school_district_code',
  DEP_FIRST_NAME = 'dependent_first_name',
  DEP_LAST_NAME = 'dependent_last_name',
  DEP_SSN = 'dependent_social_security_number',
  DEP_RELATION = 'relationship_to_you',
  DEP_BIRTHDATE = 'dependent_birthdate',
  DEP_US_CITIZEN = 'dependent_us_citizen',
  DEP_ITIN_NUMBER = 'dependent_itin_number',
  DEP_HAVE_SSN = 'dependent_have_ssn',
  BUSINESS_NAME = 'business_name',
  BUSINESS_REGISTERED = 'is_your_business_registered',
  EIN = 'EIN',
  FREELANCE_INCOME = 'freelance_income',
  W2_INCOME = 'w2_income',
  CAPITAL_GAINS_INCOME = 'capital_gains_income',
  INTEREST_INCOME = 'interest_income',
  DIVIDENDS_INCOME = 'dividends_income',
  GOV_BENEFITS_INCOME = 'government_benefits_income',
  OTHER_SOURCE_INCOME = 'other_sources_income',
  HOME_AREA = 'home_area',
  OFFICE_AREA = 'office_area',
  TOTAL_MILES = 'total_miles',
  BUSINESS_MILES = 'business_miles',
  FIRST_QUARTER_TAX = 'first_quarter_tax',
  SECOND_QUARTER_TAX = 'second_quarter_tax',
  THIRD_QUARTER_TAX = 'third_quarter_tax',
  FOURTH_QUARTER_TAX = 'fourth_quarter_tax',
  WORK_TYPE = 'work_type',
  PROFESSION = 'profession',
  FILING_STATUS = 'filing_status',
  SPOUSE_SSN = 'spouse_ssn',
  DEPENDENT = 'dependent',
  WFH = 'wfh',
  TRAVEL = 'travel',
  PHONE_PERCENTAGE = 'phone_percentage',
  INTERNET_PERCENTAGE = 'internet_percentage',
  MEAL = 'meal',
  PERSONAL_DEDUCTION = 'personal_deductions',
  EARNING_STATE = 'earning_state',
  INCOME_SOURCES = 'income_sources',
  FEDERAL_TAX = 'federal_tax',
  STATE_TAX = 'state_tax',
  SPOUSE_FREELANCE_INCOME = 'spouse_freelance_income',
  SPOUSE_W2_INCOME = 'spouse_w2_income',
  W2_WITHHELD = 'w2_withheld',
  DEPENDENT_DETAILS = 'dependent_details',
  MILES = 'miles',
  HOME_OFFICE_AREA = 'home_office_area',
  SPOUSE_EMAIL = 'spouse_email',
  SEPARATE_OFFICE = 'separate_office',
  HOME_ADDRESS_AS_BUSINESS_ADDRESS = 'home_address_as_business_address',
  SPOUSE_DOB = 'spouse_dob',
  PHONE_NUMBER = 'phone_number',
  BUSINESS_TYPE = 'business_type',
  FEDERAL_MONTHLY_W2_WITHHELD = 'federal_monthly_w2_withheld',
  STATE_MONTHLY_W2_WITHHELD = 'state_monthly_w2_withheld',
  FEDERAL_TAX_PAID = 'federal_tax_paid',
  STATE_TAX_PAID = 'state_tax_paid',
  LIVING_OUTSIDE_USA = 'living_outside_usa',
  MAILING_ADDRESS_OUTSIDE_USA = 'mailing_address_outside_usa',
  LIVED_FOR = 'lived_for',
  PERIODS = 'periods',
  SPOUSE_PERIODS = 'spouse_periods',
  FROM = 'from',
  TO = 'to',
}
export enum FILING_STATUS_ANSWER {
  SINGLE = 'Single',
  MARRIED_FILING_JOINTLY = 'Married filing jointly',
  MARRIED_FILING_SEPARAELY = 'Married filing separately',
  HEAD_OF_HOUSEHOLD = 'Head of household',
  QUALIFYING_WIDOWER = 'Qualifying widower',
}
