import {bulkUpdateTaxProfile, getTaxProfile} from 'src/appApi';
import {SET_TAX_PROFILE, UPDATE_TAX_PROFILE} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {TaxProfileQuestion} from './taxProfile.types';

const setTaxProfile = (data: any) => {
  return {
    type: SET_TAX_PROFILE,
    payload: data,
  };
};

const updateTaxProfile = (data: {
  questions: {question_id: TaxProfileQuestion; answer: any}[];
  year: number;
}) => {
  return {
    type: UPDATE_TAX_PROFILE,
    payload: data,
  };
};

export const fetchTaxProfileForGivenYear = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getTaxProfile({
        years: year,
        fly_user_id: userId,
      });
      dispatch(setTaxProfile(response.data));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch tax profile (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};

export const bulkSaveTaxProfileQuestions = (
  userId: number,
  questionsData: {question_id: TaxProfileQuestion; answer: any}[],
  year: number,
) => {
  return async (dispatch: any) => {
    await bulkUpdateTaxProfile({
      fly_user_id: userId,
      year,
      questions_data: questionsData,
    });
    await dispatch(updateTaxProfile({questions: questionsData, year}));
  };
};
