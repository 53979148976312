import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import Skeleton from 'src/icons/Skeleton';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessDetail from './components/SingleBusinessDetail';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import NotApplicableView from 'src/CpaCenterV2/common/NotApplicableView/NotApplicableView';
import {Typography} from '@mui/material';
import {ReduxStateType} from 'src/store/store';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import DSButton from 'src/DesignSystem/Button/Button';
import {Add} from '@mui/icons-material';

interface BusinessDetailsProps {
  style?: React.CSSProperties;
  onAddBusiness?: () => void;
  showDeleteBiz?: boolean;
}

const BusinessDetails = ({
  style,
  onAddBusiness,
  showDeleteBiz,
}: BusinessDetailsProps) => {
  const {loaded, businesses} = useSelector(selectBusinessDetails);
  const {activeYear} = useActiveYear();

  const dontHaveBusiness = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.DONT_HAVE_BUSINESS_DETAILS,
      activeYear,
    ),
  );

  return (
    <SubSectionBox
      name={'Business details'}
      style={style}
      customSectionActions={
        <div>
          {onAddBusiness && (
            <DSButton
              type="secondary"
              onClick={(e) => {
                onAddBusiness();
              }}
              text="Add"
              endIcon={<Add />}
              style={{marginRight: 8}}
            />
          )}
        </div>
      }
      taxprofileSubsection={TaxProfileSubsection.BusinessDetails}>
      {!loaded ? (
        <Skeleton width={'100%'} height={40} />
      ) : dontHaveBusiness ? (
        <NotApplicableView>
          <Typography style={{fontSize: '13', fontWeight: 'normal'}}>
            I don’t have freelance/business
          </Typography>
          <Typography style={{fontSize: '13', fontWeight: 'bold'}}>
            Not a freelancer/Business owner
          </Typography>
        </NotApplicableView>
      ) : (
        businesses.map((business, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 8,
            }}>
            <SingleBusinessDetail
              business={business}
              index={index}
              showDeleteBiz={showDeleteBiz}
            />
          </div>
        ))
      )}
    </SubSectionBox>
  );
};

export default BusinessDetails;
