import React from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import Box from 'src/DesignSystem/Box/Box';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {getTotalIncomeForFormType} from './TaxFormIncomeCard.utils';
import {TaxFormTypeToPayerNameMap} from '../../Income.constants';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTransactionIdMap} from 'src/store/transactions/transaction.selector';

interface TaxFormIncomeCardProps {
  taxform: GenericTaxForm;
}

const TaxFormIncomeCard = ({taxform}: TaxFormIncomeCardProps) => {
  const {documents} = useSelector(selectDocuments);
  const transactionIdMap = useSelector(selectTransactionIdMap);

  const getPayerName = () => {
    return taxform.formData[TaxFormTypeToPayerNameMap[taxform.formType]];
  };

  const getAmount = () => {
    if (Object.keys(TaxFormType).includes(taxform.formType)) {
      const areTransactionsLinked =
        (taxform.formData[TaxFormFieldIds.TXN_IDS] ?? []).length !== 0;
      if (areTransactionsLinked) {
        const amount = taxform.formData[TaxFormFieldIds.TXN_IDS].reduce(
          (acc, txnId) => {
            return acc + Math.abs(transactionIdMap[txnId]?.currentAmount ?? 0);
          },
          0,
        );
        const finalAmountString = CurrencyNumberFormat(amount);

        return `${finalAmountString} (transactions)`;
      } else {
        const {amount, calculation} = getTotalIncomeForFormType(taxform);
        if (calculation.length === 0) {
          return undefined;
        }
        const finalAmountString = CurrencyNumberFormat(amount);
        return `${finalAmountString} (${calculation.join(' ')})`;
      }
    }
    return undefined;
  };

  const doc = documents.find((doc) => doc.docId === taxform.docId);
  const payerName = getFieldValue(getPayerName());
  const amount = getFieldValue(getAmount());

  return (
    <Box
      col
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{padding: 12, margin: 8}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}>
        <Typography style={{marginRight: 24}}>{payerName}</Typography>
        <Typography style={{fontWeight: 600}}>{amount}</Typography>
      </div>
      <DocumentPointer
        paddingBlock={12}
        border
        doc={doc}
        taxform={taxform}
        dontShowStatus
      />
    </Box>
  );
};

export default TaxFormIncomeCard;
