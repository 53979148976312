import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import FileViewer from 'src/DesignSystem/File/FileViewer';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {stringClipper} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';
import NotesIcon from 'src/icons/NotesIcon';
import {selectDocuments} from 'src/store/documents/documents.selector';

const FILE_TYPE_CONTAINER_HEIGHT = 4;

const FileTypeContainer = ({text}: {text: string}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E6E6E6',
        height: `${FILE_TYPE_CONTAINER_HEIGHT}%`,
        border: 'solid 1px black',
      }}>
      <Text
        type={TEXT_TYPES.S}
        text={text}
        fontWeight={FONT_WEIGHTS.SemiBold}
      />
    </div>
  );
};

interface DuplicateFilesDrawerProps {
  duplicateFiles: File[];
  setDuplicateFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onUploadFiles: () => void;
}

const DuplicateFilesDrawer = ({
  duplicateFiles,
  setDuplicateFiles,
  onUploadFiles,
}: DuplicateFilesDrawerProps) => {
  const isOpen = duplicateFiles.length > 0;
  const {documents} = useSelector(selectDocuments);
  const [currSelectedFilename, setCurrSelectedFilename] = useState<
    string | null
  >(null);

  const documentByFilename = useMemo(() => {
    return _.keyBy(documents, 'filename');
  }, [documents]);

  const duplicateFileByNames = useMemo(() => {
    return _.keyBy(duplicateFiles, 'name');
  }, [duplicateFiles]);

  const {
    newFile: newFile,
    uri,
    alreadyUploadedFile,
    alreadyUploadedFileUri,
  } = useMemo(() => {
    if (currSelectedFilename === null || duplicateFiles.length === 0) {
      return {
        newFile: null,
        uri: null,
        alreadyUploadedFile: null,
        alreadyUploadedFileUri: null,
      };
    }
    const newFile = duplicateFileByNames[currSelectedFilename];
    const alreadyUploadedFile = documentByFilename[currSelectedFilename];
    const alreadyUploadedFileUri = alreadyUploadedFile.docLink;
    return {
      newFile,
      uri: URL.createObjectURL(newFile),
      alreadyUploadedFile,
      alreadyUploadedFileUri,
    };
  }, [currSelectedFilename, duplicateFileByNames]);

  return (
    <Drawer
      style={{
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        alignSelf: 'center',
      }}
      shadowDistance={10000}
      width={'95vw'}
      height={'95vh'}
      direction="up"
      backgroundColor={themmeColor.cpaCenterV2Bg}
      isOpen={isOpen}>
      <div
        style={{
          padding: 20,
          display: 'flex',
          flexFlow: 'column',
          height: '100%',
        }}>
        <Text
          text={`Files with same name already exists (${duplicateFiles.length})`}
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
        />
        <div style={{display: 'flex', flex: 1, height: '100%'}}>
          <div style={{display: 'flex', flexFlow: 'column', flex: 0.7}}>
            {newFile !== null ? (
              <>
                <FileTypeContainer text="NEW FILE" />
                <FileViewer
                  uri={uri}
                  filename={newFile.name}
                  imgContainerHeight={`${50 - FILE_TYPE_CONTAINER_HEIGHT}%`}
                  pdfHeight={`${50 - FILE_TYPE_CONTAINER_HEIGHT}%`}
                />
              </>
            ) : null}
            {alreadyUploadedFile !== null ? (
              <>
                <FileTypeContainer text="EXISTING FILE" />
                <FileViewer
                  uri={alreadyUploadedFileUri}
                  filename={alreadyUploadedFile.filename}
                  imgContainerHeight={`${50 - FILE_TYPE_CONTAINER_HEIGHT}%`}
                  pdfHeight={`${50 - FILE_TYPE_CONTAINER_HEIGHT}%`}
                />
              </>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              flex: 0.3,
              backgroundColor: themmeColor.white,
              padding: 16,
            }}>
            <Text
              type={TEXT_TYPES.L}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={'Files'}
            />
            <Text
              type={TEXT_TYPES.S}
              text={'Review and remove the files not needed before uploading'}
              color={themmeColor.black60}
              containerStyle={{marginTop: 4, marginBottom: 12}}
            />
            <div style={{overflowY: 'auto', height: '80%'}}>
              {duplicateFiles.map((file, index) => {
                const isSelected = file.name === currSelectedFilename;
                return (
                  <div style={{display: 'flex', marginBottom: 12}}>
                    <Box
                      hoverColor={themmeColor.focusedBlue}
                      hoverEffect
                      onClick={() => {
                        setCurrSelectedFilename(file.name);
                      }}
                      borderColor={
                        isSelected
                          ? themmeColor.darkBlue
                          : themmeColor.offWhite
                      }
                      style={{padding: 12, flex: 1, display: 'flex'}}>
                      <NotesIcon />
                      <Text
                        type={TEXT_TYPES.BASE}
                        text={stringClipper(file.name, 25)}
                        containerStyle={{
                          marginLeft: 8,
                          flexShrink: 1,
                        }}
                        color={
                          isSelected ? themmeColor.darkBlue : themmeColor.black
                        }
                      />
                    </Box>
                    <Box
                      hoverColor={`${themmeColor.errorRed}33`}
                      hoverEffect
                      onClick={() => {
                        if (isSelected) {
                          setCurrSelectedFilename(null);
                        }
                        setDuplicateFiles((prev) =>
                          prev.filter((_, i) => i !== index),
                        );
                      }}
                      borderColor={themmeColor.errorRed}
                      style={{padding: 12, marginLeft: 12}}>
                      <CrossIcon color={themmeColor.errorRed} />
                    </Box>
                  </div>
                );
              })}
            </div>
            <DSButton
              onClick={onUploadFiles}
              text="Upload"
              style={{paddingInline: 40, paddingBlock: 16, marginTop: 12}}
              type={'primary'}
              textType={TEXT_TYPES.BASE}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DuplicateFilesDrawer;
