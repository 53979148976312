import React, {useMemo, useState} from 'react';
import ReturnContainerCard from './ReturnContainerCard';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import TaxReturnFieldGridWrapper from './TaxReturnFieldGridWrapper';
import {Form} from 'src/DesignSystem/Form/Form';
import {ReturnFields, UserRelationType} from '../CreateReturn.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';
import _ from 'lodash';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import PDFWithOcrBoxes from '../../../../../DesignSystem/OCR/PDFWithOcrBoxes';
import {getOcrBoxesForBizDetails} from '../CreateReturn.utils';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';

interface BusinessTaxReturnFieldProps extends ArrayChildProps {
  user: UserRelationType | null;
  validationDisabled: boolean;
}

const BusinessTaxReturnField = ({
  childProps,
  array,
  arrayObjectIndex,
  onChangeValue,
  user,
  validationDisabled,
}: BusinessTaxReturnFieldProps) => {
  const [showDocument, setShowDocument] = useState(false);
  const {updateObject, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );
  const {fields} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
  });

  const switcHField = fields.find(
    (field) => field.path === ReturnFields.should_file_return,
  );

  const ocrBoxes = useMemo(() => {
    return getOcrBoxesForBizDetails(user, arrayObject[ReturnFields.id], [
      BusinessDetailsField.name,
      BusinessDetailsField.ein,
      BusinessDetailsField.entity_type,
    ]);
  }, [user]);

  return (
    <ReturnContainerCard
      switchComponent={switcHField ? <Form {...switcHField} /> : null}
      tagName={`Business ${arrayObjectIndex + 1}`}>
      <TaxReturnFieldGridWrapper style={{marginTop: 16}}>
        {fields.map((field) => {
          // @ts-ignore
          if (field.path === ReturnFields.should_file_return) {
            return null;
          }
          return <Form {...field} disableValidation={validationDisabled} />;
        })}
      </TaxReturnFieldGridWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}>
        <DSButton
          type="primary"
          text="Delete business"
          onClick={() =>
            onChangeValue(
              _.cloneDeep(
                array.filter(
                  (item: any, idx: number) => idx !== arrayObjectIndex,
                ),
              ),
            )
          }
        />
        {user !== null && ocrBoxes.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 12,
              marginBottom: showDocument ? 12 : 0,
            }}>
            <DSButton
              type="secondary"
              text={showDocument ? 'Hide document' : 'Show document'}
              onClick={() => {
                setShowDocument(!showDocument);
              }}
              startIcon={showDocument ? <ArrowUpward /> : <ArrowDownward />}
            />
          </div>
        )}
      </div>
      {showDocument && user !== null && (
        <PDFWithOcrBoxes
          pdfUrl={user[ReturnFields.read_url]}
          ocrData={ocrBoxes}
          onlyRenderPagesWithOcrFields
        />
      )}
    </ReturnContainerCard>
  );
};

export default BusinessTaxReturnField;
