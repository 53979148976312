import React from 'react';

const PartnerIncome = () => {
  return (
    <div>
      <h1>PartnerIncome</h1>
    </div>
  );
};

export default PartnerIncome;
