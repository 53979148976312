import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {GenericTaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import {
  getEditTaxFormConfig,
  getOverwriteTaxForm,
} from 'src/store/taxForms/taxForms.utils';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import _, {noop} from 'lodash';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {deleteTaxForm, patchTaxForm, postTaxForm} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from '../hooks/useActiveYear';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';

export const useEditTaxForm = ({
  taxFormId,
  taxFormType,
  prefilledFormData,
}: {
  taxFormId: number | null;
  taxFormType: TaxFormType | null;
  prefilledFormData: any;
}) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const taxFormById = useMemo(() => {
    return _.keyBy(taxForms, 'taxFormId');
  }, [taxForms]);
  const currentReduxTaxForm = useMemo(() => {
    if (
      typeof taxFormId === 'number' &&
      taxFormById[taxFormId] !== undefined
    ) {
      return taxFormById[taxFormId];
    }
    return null;
  }, [taxFormById, taxFormId]);

  const {notify} = useNotify();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {taxProfileMap} = useTaxProfile();

  const isEditingTaxForm = currentReduxTaxForm !== null;

  const [taxFormData, setTaxFormData] = useState<GenericTaxForm['formData']>(
    {},
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const config = useMemo(() => {
    if (taxFormType === null) {
      return [];
    }
    return getEditTaxFormConfig(taxFormType);
  }, [taxFormType]);

  useEffect(() => {
    if (currentReduxTaxForm) {
      const formData = {
        ..._.cloneDeep(currentReduxTaxForm['formData']),
        ...prefilledFormData,
      };
      setTaxFormData(formData);
      return;
    }
    const emptyTaxForm = {
      ...createEmptyFormDataFromFormConfig(config),
      ...prefilledFormData,
    };
    setTaxFormData(emptyTaxForm);
  }, [currentReduxTaxForm, taxFormType, config, prefilledFormData]);

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    (props) => {
      const overwrite = getOverwriteTaxForm(taxFormType, {
        taxforms: taxForms,
        taxprofileMap: taxProfileMap,
      });
      return overwrite(props);
    },
    [taxFormType, taxForms, taxProfileMap],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config,
    data: taxFormData,
    setData: setTaxFormData,
    overWriteFields,
  });

  const onCreate = async (callback = noop) => {
    if (taxFormType === null) {
      return;
    }
    try {
      setIsLoading(true);
      await postTaxForm({
        fly_user_id: userId,
        year: activeYear,
        form_type: taxFormType,
        form_data: taxFormData,
      });
      callback();
      notify('Create Tax form successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to create tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (callback = noop) => {
    if (typeof taxFormId !== 'number') {
      return;
    }
    try {
      setIsLoading(true);
      // add update api here
      await patchTaxForm(taxFormId, {form_data: taxFormData});
      callback();
      notify('Updated Tax form successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to update tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (callback = noop) => {
    if (typeof taxFormId !== 'number') {
      return;
    }
    try {
      setIsLoading(true);
      await deleteTaxForm({tax_form_id: taxFormId});
      callback();
      notify('Tax form deleted successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to delete tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fields,
    areAllFieldsValid,
    isEditingTaxForm,
    onCreate,
    onUpdate,
    onDelete,
    isLoading,
  };
};
