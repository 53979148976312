import React, {useState, ReactNode} from 'react';
import Text from '../Form/common/Text/Text';
import {FONT_WEIGHTS, TEXT_TYPES} from '../Form/common/Text/Text.types';
import {getSupportedFiles} from './Dropzone.utils';
import useNotify from '../Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {DEFAULT_SUPPORTED_FILE_EXTENSIONS} from './Dropzone.constants';

interface DropzoneProps {
  children: ReactNode | ReactNode[];
  style?: React.CSSProperties;
  onDropFiles: (files: File[]) => void;
  disabled?: boolean;
  supportedExtensions?: string[];
}

const Dropzone: React.FC<DropzoneProps> = ({
  children,
  style = {},
  onDropFiles,
  disabled,
  supportedExtensions = DEFAULT_SUPPORTED_FILE_EXTENSIONS,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const {notify} = useNotify();

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      const {supportedFiles, notSupprtedFiles} = getSupportedFiles(
        files,
        supportedExtensions,
      );
      if (notSupprtedFiles.length > 0) {
        notify(
          `Unsupported files (${
            notSupprtedFiles.length
          }) has been dropped, file names are ${notSupprtedFiles
            .map((file) => file.name)
            .join(', ')}`,
          NotificationType.warning,
        );
      }
      onDropFiles(supportedFiles);
      e.dataTransfer.clearData();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexFlow: 'column',
        flexGrow: 1,
        border: isDragging ? '8px dashed #000000' : undefined,
        borderRadius: 8,
        transition: 'border-color 0.3s ease',
        ...style,
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      {isDragging ? (
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
            flexGrow: 1,
          }}>
          <Text
            type={TEXT_TYPES.H6}
            fontWeight={FONT_WEIGHTS.SemiBold}
            text="Drop your files here..."
          />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Dropzone;
