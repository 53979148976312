export enum TRANSFORMERS {
  NONE = 'NONE',
  STRING_TO_NUMBER = 'STRING_TO_NUMBER',
  LIST_OF_STRING_TO_NUMBER = 'LIST_OF_STRING_TO_NUMBER',
  EMPTY_ARRAY_TO_NULL = 'EMPTY_ARRAY_TO_NULL',
  SSN = 'SSN',
  EIN = 'EIN',
  ZIP = 'ZIP',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

const noTransform = (value: any) => {
  return value;
};

const stringToNumber = (value: any) => {
  if (typeof value === 'string') {
    const parsed = parseInt(value, 10);
    if (!Number.isNaN(parsed)) {
      return parsed;
    }
  }
  return value;
};

const listStringToNumber = (value: any) => {
  if (!Array.isArray(value)) {
    return value;
  }
  return value.map((v) => {
    return stringToNumber(v);
  });
};

const emptyArrayToNull = (value: any) => {
  if (Array.isArray(value) && value.length === 0) {
    return null;
  }
  return value;
};

const formatSSN = (ssn: any) => {
  let formattedSSN = ssn.replace(/[^\d-]/g, ''); // remove all non-dash and non-numerals
  formattedSSN = formattedSSN.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2'); // add the first dash if number from the second group appear
  formattedSSN = formattedSSN.replace(
    /^(\d{3})-?(\d{2})-?(\d{1,4})/,
    '$1-$2-$3',
  ); // add the second dash if numbers from the third group appear

  formattedSSN = formattedSSN
    .split('')
    .filter((newVal, index) => {
      if (index === formattedSSN.length - 1 && newVal === '-') {
        return false;
      }

      return newVal !== '-' || index === 3 || index === 6;
    })
    .join(''); // remove misplaced dashes

  return formattedSSN.substring(0, 11);
};

const formatEIN = (ein) => {
  let formattedEIN = ein.replace(/[^\d-]/g, ''); // remove all non-dash and non-numerals
  formattedEIN = formattedEIN.replace(/^(\d{2})-?(\d{1,7})/, '$1-$2'); // add the first dash if number from the second group appear

  formattedEIN = formattedEIN
    .split('')
    .filter((newVal, index) => {
      if (index === formattedEIN.length - 1 && newVal === '-') {
        return false;
      }

      return newVal !== '-' || index === 2;
    })
    .join(''); // remove misplaced dashes

  return formattedEIN.substring(0, 10);
};

const formatZipCode = (zipCode) => {
  let formattedZipCode = zipCode.replace(/[^\d-]/g, ''); // remove all non-dash and non-numerals
  formattedZipCode = formattedZipCode.replace(/^(\d{5})-?(\d{1,4})/, '$1-$2'); // add the first dash if number from the second group appear

  formattedZipCode = formattedZipCode
    .split('')
    .filter((newVal, index) => {
      if (index === formattedZipCode.length - 1 && newVal === '-') {
        return false;
      }

      return newVal !== '-' || index === 5;
    })
    .join(''); // remove misplaced dashes

  return formattedZipCode.substring(0, 10);
};

const formatPhoneNumber = (input) => {
  // Converts a given text into US phone number format (987) 654-3210.
  // Removes chars other than digits and formats the digits
  let output = '';
  if (input == null) {
    return output;
  }
  input.replace(
    /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
    (match, g1, g2, g3) => {
      if (g1.length) {
        output += `(${g1}`;
        if (g1.length == 3) {
          // output += ') ';
          if (g2.length) {
            output += `) ${g2}`;
            if (g2.length == 3) {
              // output += '-';
              if (g3.length) {
                output += `-${g3}`;
              }
            }
          }
        }
      }
    },
  );
  return output;
};

const TRANSFORMERS_MAP = {
  [TRANSFORMERS.NONE]: noTransform,
  [TRANSFORMERS.STRING_TO_NUMBER]: stringToNumber,
  [TRANSFORMERS.LIST_OF_STRING_TO_NUMBER]: listStringToNumber,
  [TRANSFORMERS.EMPTY_ARRAY_TO_NULL]: emptyArrayToNull,
  [TRANSFORMERS.SSN]: formatSSN,
  [TRANSFORMERS.EIN]: formatEIN,
  [TRANSFORMERS.ZIP]: formatZipCode,
  [TRANSFORMERS.PHONE_NUMBER]: formatPhoneNumber,
};

export const transform = (value: any, type: TRANSFORMERS | TRANSFORMERS[]) => {
  if (typeof type !== 'string' && !Array.isArray(type)) {
    return value;
  }
  if (Array.isArray(type)) {
    let v = value;
    type.forEach((t) => {
      v = TRANSFORMERS_MAP[t](v);
    });
    return v;
  }
  return TRANSFORMERS_MAP[type](value);
};
