import React, {useState} from 'react';
import {Typography} from '@mui/material';
import JSONPretty from 'react-json-pretty';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import RightChveron from 'src/icons/RightChveron';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {useDispatch} from 'react-redux';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

interface TaxFormDataPopupProps {
  taxform: GenericTaxForm;
  backgroundColor?: string;
  style?: React.CSSProperties;
  borderColor?: string;
  onClick?: () => void;
}

const TaxFormDataPopup = ({
  taxform,
  style = {},
  backgroundColor,
  borderColor,
  onClick,
}: TaxFormDataPopupProps) => {
  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const onClose = () => {
  //   setIsPopupVisible(false);
  // };
  const dispatch = useDispatch();
  return (
    <div>
      <Box
        // onClick={() => setIsPopupVisible(true)}
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          dispatch(
            setAppState(AppReducerStates.taxformDrawer, {
              isVisible: true,
              taxformId: taxform.taxFormId,
            }),
          );
        }}
        hoverColor={themmeColor.focusedBlue}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        hoverEffect
        style={{
          padding: 16,
          paddingBlock: 12,
          alignItems: 'center',
          justifyContent: 'space-between',
          ...style,
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography
            style={{
              fontSize: 16,
              marginLeft: 10,
            }}>
            See tax form
          </Typography>
        </div>
        <div style={{alignSelf: 'center', display: 'flex'}}>
          <RightChveron />
        </div>
      </Box>
      {/* <PopUp
        primaryButtonTitle="Close"
        primaryButtonOnClick={onClose}
        isOpen={isPopupVisible}
        onClose={onClose}>
        <JSONPretty
          id={`json-pretty-tax-form`}
          data={taxform.formData}
          theme={JSONPrettyMon}
          style={{
            fontSize: 20,
            maxHeight: '60vh',
            maxWidth: '50vw',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        />
      </PopUp> */}
    </div>
  );
};

export default TaxFormDataPopup;
