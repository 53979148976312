import React from 'react';
import {useSelector} from 'react-redux';
import BoiSubSectionBox from './BoiSubsectionBox';
import {selectBoiData} from 'src/store/boi/boi.selectors';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import BeneficialOwnerCard from './BeneficialOwnerCard';
import {Skeleton} from '@mui/material';
import {BOI_SECTION_NAMES} from '../types';

interface Props {
  style?: React.CSSProperties;
}

const BeneficialOwnerDetails = ({style}: Props) => {
  const {data: boiData, loaded: boiDataLoaded} = useSelector(selectBoiData);
  const {documents, loaded: documentsLoaded} = useSelector(selectDocuments);
  const {taxForms, loaded: taxFormsLoaded} = useSelector(
    selectTaxFormsReducer,
  );

  const isLoading = !documentsLoaded || !boiDataLoaded || !taxFormsLoaded;

  const beneficialOwners = taxForms.filter((forms) =>
    [TaxFormType.BOIR_BENEFICIAL_OWNER_DETAILS].includes(forms.formType),
  );

  return (
    <BoiSubSectionBox
      name="Beneficiary owners"
      style={style}
      boiSubsection={BOI_SECTION_NAMES.BOI_BENEFICIAL_OWNER_DETAILS}
      year={boiData.year}>
      {isLoading ? (
        <Skeleton width={'100%'} height={40} />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 16,
          }}>
          {beneficialOwners.map((owner, index) => {
            return (
              <div style={{flex: 0.45}}>
                <BeneficialOwnerCard
                  index={index}
                  data={owner.formData}
                  document={documents.find(
                    (doc) => doc.docLink === owner.docLink,
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
    </BoiSubSectionBox>
  );
};

export default BeneficialOwnerDetails;
