import React, {useMemo} from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {DOCUMENT_TYPES, themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import Skeleton from 'src/icons/Skeleton';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DSButton from 'src/DesignSystem/Button/Button';
import FileSystem from 'src/DesignSystem/FileSystem/FileSystem';

interface Props {
  filterDoc?: (doc: ReduxDocumentType) => boolean;
  onDocumentClick?: (doc: ReduxDocumentType) => void;
  onUpload?: (file: File[]) => void;
}

const DocumentsList = ({
  filterDoc = () => true,
  onDocumentClick,
  onUpload,
}: Props) => {
  const {documents, loaded} = useSelector(selectDocuments);

  const filteredDocuments = useMemo(
    () => documents.filter((doc) => filterDoc(doc)),
    [documents],
  );

  const documentCount = filteredDocuments.length;

  const ocrDocuments = filteredDocuments.filter((doc) => doc.ocrRequired);
  const nonOcrDocuments: any = filteredDocuments.filter(
    (doc) => !doc.ocrRequired && doc.docType !== DOCUMENT_TYPES.OTHER,
  );
  const additionalDocuments = filteredDocuments.filter(
    (doc) => doc.docType === DOCUMENT_TYPES.OTHER,
  );

  const sections = [
    {
      title: 'OCR documents',
      docsList: ocrDocuments,
    },
    {
      title: 'Non- OCR documents',
      docsList: nonOcrDocuments,
    },
    {
      title: 'Additional documents',
      docsList: additionalDocuments,
    },
  ];

  if (!loaded) {
    return (
      <div style={{marginTop: 20}}>
        <Skeleton width={100} height={24} />
        <Skeleton width={60} height={20} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
      </div>
    );
  }

  return (
    <div style={{overflowY: 'auto', height: '100%'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h1 style={{fontSize: 20}}>Document Review ({documentCount})</h1>

        {onUpload ? (
          <div>
            <FileSystem onSelectFiles={onUpload}>
              <DSButton type="secondary" text="Upload" onClick={() => {}} />
            </FileSystem>
          </div>
        ) : null}
      </div>
      <div>
        {sections.map(({title, docsList}) => {
          return (
            <div>
              <Typography
                style={{
                  color: themmeColor.black60,
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 8,
                }}>
                {title} ({docsList.length})
              </Typography>
              {docsList.map((doc: ReduxDocumentType) => {
                return (
                  <div style={{marginBottom: 8}}>
                    <DocumentPointer
                      key={doc.docId}
                      doc={doc}
                      onDocumentClick={
                        onDocumentClick
                          ? () => onDocumentClick(doc)
                          : undefined
                      }
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsList;
