import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import DownIcon from 'src/icons/DownIcon';
import Option from '../components/Option/Option';
import Text from '../Text/Text';
import DSButton from 'src/DesignSystem/Button/Button';
import {isString} from 'lodash';
import {Search} from '@mui/icons-material';
import TextField from '../TextField/TextField';

interface RadioOptionType {
  label: any;
  value: any;
}

interface RadioFieldProps extends InputWrapperBaseType {
  options: RadioOptionType[];
  value: any;
  onChangeValue: (newValue: any) => void;
  allowClear?: boolean;
}

const RadioField = ({
  title,
  validators,
  options = [],
  value,
  onChangeValue,
  allowClear = false,
  ...rest
}: RadioFieldProps) => {
  const selectedOption = useMemo(() => {
    return options.find((op) => op.value === value) ?? null;
  }, [options, value]);

  const [_selectedValue, setSelectedValue] = useState(value);

  const [optionsPopup, setOptionsPopup] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const onClickOptions: React.MouseEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setOptionsPopup(true);
      e.currentTarget.blur();
    }, []);

  const onClosePopup = useCallback(() => {
    setOptionsPopup(false);
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [optionsPopup]);

  const onSelect = useCallback((newValue: any) => {
    setSelectedValue(newValue);
  }, []);

  const onDeselect = useCallback(() => {
    if (allowClear) {
      setSelectedValue(null);
    }
  }, [allowClear]);

  const filteredOptions = useMemo(() => {
    if (isString(searchInput) && searchInput.trim().length > 0) {
      const _filtered = options.filter((o) => {
        if (
          isString(o.label) &&
          !o.label.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return false;
        }
        return true;
      });
      return _filtered;
    }
    return options;
  }, [options, searchInput]);
  const isPrimaryDisabled = useMemo(() => {
    if (allowClear) {
      return false;
    }
    return _selectedValue === null || _selectedValue === undefined;
  }, [_selectedValue, allowClear]);

  return (
    <>
      <InputWrapper
        title={title}
        type="text"
        onClick={onClickOptions}
        value={selectedOption?.label ?? 'Select one'}
        rightIcon={<DownIcon />}
        validators={validators}
        actualValue={value}
        {...rest}
      />

      <PopUp
        primaryButtonTitle="Change"
        primaryButtonOnClick={() => {
          onChangeValue(_selectedValue);
          onClosePopup();
        }}
        primaryButtonDisabled={isPrimaryDisabled}
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={() => {
          onClosePopup();
        }}
        style={{width: 400}}
        isOpen={optionsPopup}
        onClose={onClosePopup}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Text
            text={title ?? 'Choose one'}
            type={Text.TEXT_TYPES.L}
            fontWeight={Text.FONT_WEIGHTS.SemiBold}
            containerStyle={{marginBottom: 12}}
          />
          {allowClear ? (
            <DSButton
              type="tertiary"
              text="Clear"
              onClick={onDeselect}
              disabled={_selectedValue === null}
            />
          ) : null}
        </div>
        <TextField
          value={searchInput}
          onChangeValue={setSearchInput}
          placeholder="Search"
          rightIcon={<Search />}
          style={{marginBottom: 12}}
        />
        <div style={{maxHeight: 400, overflowY: 'auto'}}>
          {filteredOptions.map(({label, value}, index) => {
            return (
              <Option
                key={`${value}-${index}`}
                isSingle
                checked={_selectedValue === value}
                label={label}
                value={value}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            );
          })}
        </div>
      </PopUp>
    </>
  );
};

export default RadioField;
