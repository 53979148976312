import React, {useEffect} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {fetchQueries} from 'src/store/queries/queries.actions';
import ActionRequired from '../common/ActionRequired/ActionRequired';
import useCurrentUserId from '../hooks/useCurrentUserId';
import DocumentsList from './components/DocumentsList';
import {selectUser} from 'src/store/user/user.selector';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useCpaCenterV2Step from '../hooks/useCpaCenterV2Step';
import CompletedRibbon from '../common/CompletedRibbon/CompletedRibbon';
import useLayout from '../hooks/useLayout';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import Drawer from 'src/DesignSystem/Drawer/Drawer';

const DocumentsReview = () => {
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {markStepComplete, isLoading} = useCpaCenterV2Step();
  const {cpaFacingRetStatus} = useSelector(selectUser);

  const isDocumentReviewDone = isCpaCenterV2StepComplete(
    cpaFacingRetStatus,
    TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
  );

  const {documents, loaded} = useSelector(selectDocuments);

  useEffect(() => {
    dispatch(fetchDocumentsForGivenYear(userId, activeYear));
    dispatch(fetchQueries({userId, year: activeYear}));
  }, [activeYear]);

  const {spaceLeftBelowTaxFilingSteps, DOCUMENT_BOTTOM_BAR_HEIGHT} =
    useLayout();

  return (
    <div>
      <div
        style={{
          height: spaceLeftBelowTaxFilingSteps - DOCUMENT_BOTTOM_BAR_HEIGHT,
          display: 'flex',
        }}>
        <div style={{width: '60vw', paddingRight: '2vw'}}>
          <DocumentsList />
        </div>
        <div style={{display: 'flex', flex: 1, paddingRight: 20}}>
          <ActionRequired types={[CreateQueryDrawerTab.DOCUMENT]} />
        </div>
      </div>
      {!isDocumentReviewDone ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingInline: 40,
            alignItems: 'center',
            height: DOCUMENT_BOTTOM_BAR_HEIGHT,
          }}>
          <Typography style={{fontSize: 16}}>
            Once you have reviewed all the documents you will be able to
            proceed to next section
          </Typography>
          <DSButton
            disabled={
              !loaded ||
              documents.some(
                (doc) =>
                  doc.docStatus === VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW,
              ) ||
              isLoading
            }
            type="primary"
            onClick={() =>
              markStepComplete(TAX_FILING_STATUS.TAX_DOCS_REVIEWED)
            }
            text="Review complete"
            style={{width: 400, height: 48}}
          />
        </div>
      ) : (
        <CompletedRibbon
          height={DOCUMENT_BOTTOM_BAR_HEIGHT}
          text="Document review is complete"
        />
      )}
    </div>
  );
};

export default DocumentsReview;
