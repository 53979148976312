import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface DrivingLicenseProps {
  taxForm?: GenericTaxForm;
  isSpouse: boolean;
  onClickDocument?: () => void;
}

const DrivingLicense = ({
  isSpouse,
  taxForm,
  onClickDocument,
}: DrivingLicenseProps) => {
  const {activeYear} = useActiveYear();
  const legalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      isSpouse
        ? TaxProfileQuestion.SPOUSE_LEGAL_NAME
        : TaxProfileQuestion.LEGAL_NAME,
      activeYear,
    ),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const {documents} = useSelector(selectDocuments);

  const fName = legalName?.[TaxProfileField.FIRST_NAME];
  const lName = legalName?.[TaxProfileField.LAST_NAME];

  const fullName = `${getFieldValue(fName)} ${getFieldValue(lName)} (${
    isSpouse ? 'Spouse' : 'Self'
  })`;

  const linkedDoc = documents.find((doc) => doc.docId === taxForm?.docId);

  return (
    <div style={{flex: 0.48}}>
      <Typography style={{fontWeight: 600, marginBottom: 20}}>
        {fullName}
      </Typography>
      {(!isSpouse ||
        filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) && (
        <>
          <SimpleKeyValueField
            name="Issuing state"
            value={taxForm?.formData?.[TaxFormFieldIds.STATE]}
          />
          <Typography>Related document</Typography>
          <DocumentPointer
            taxform={taxForm}
            doc={linkedDoc}
            dontShowStatus
            onDocumentClick={onClickDocument}
            border
          />
        </>
      )}
    </div>
  );
};

export default DrivingLicense;
