import {SET_TAX_FORMS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {GenericTaxForm} from './taxForms.types';

const initialState = {
  taxForms: [] as GenericTaxForm[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const taxForms = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_FORMS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxFormsStateType = typeof initialState;
