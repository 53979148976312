const getFileExtension = (filename: string) => {
  const cleanName = filename.toLowerCase();
  const tokens = cleanName.split('.');
  const ext = tokens[tokens.length - 1];
  return ext;
};

export const getSupportedFiles = (files: FileList, extensions: string[]) => {
  const supportedFiles: File[] = [];
  const notSupprtedFiles: File[] = [];
  for (var i = 0; i < files.length; i++) {
    const currFile = files[i];
    const ext = getFileExtension(currFile.name);
    if (extensions.includes(ext)) {
      supportedFiles.push(currFile);
    } else {
      notSupprtedFiles.push(currFile);
    }
  }
  return {supportedFiles, notSupprtedFiles};
};

export const convertFileExtensionsToAcceptMimeType = (exts: string[]) => {
  return exts.map((ext) => `.${ext}`).join(', ');
};
