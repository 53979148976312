import React from 'react';
import {Form} from 'src/DesignSystem/Form/Form';
import {useEditTaxProfile} from './useEditTaxProfile';
import {getPath} from '../utils';
import {
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import Periods from './components/Periods';

interface EditTaxProfileProps {
  fields: ReturnType<typeof useEditTaxProfile>['fields'];
}

const EditTaxProfile = ({fields}: EditTaxProfileProps) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
      {fields.map((field) => {
        if (
          [
            getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.PERIODS),
            getPath(
              TaxProfileQuestion.HOME_ADDRESS,
              TaxProfileField.SPOUSE_PERIODS,
            ),
          ].includes(field.path)
        ) {
          return <Periods {...field} />;
        }
        return <Form key={field.path} {...field} />;
      })}
    </div>
  );
};

export default EditTaxProfile;
