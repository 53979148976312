import React from 'react';
import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import {format} from 'date-fns';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {WFH_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/Deductions/Deductions.constants';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import SimpleArrayField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleArrayField';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import JSONPretty from 'react-json-pretty';

interface Props {
  totalTaxAmount: number;
  calculationDate: string;
  federalTaxData: {
    federal_annual_tax: number;
    federal_quarterly_tax: number;
    calculation_defs: any;
    income_tax: any;
    taxes_paid: any;
    se_tax: any;
  };
  stateTaxData: {
    state_abbreviation: string;
    state_annual_tax: number;
    state_quarterly_tax: number;
    calculation_defs: any;
    income_tax: any;
    taxes_paid: any;
    zero_tax_state: boolean;
  }[];
  qtcInputData: {[key: number]: any};
  totalBizDeductions: number;
  taxProfile: {[key: number]: any};
}

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const EndUserQtcInput = ({
  totalTaxAmount,
  calculationDate,
  federalTaxData,
  stateTaxData,
  qtcInputData,
  totalBizDeductions,
  taxProfile,
}: Props) => {
  return (
    <Accordion>
      <AccordionSummary
        sx={{backgroundColor: themmeColor.offGrey}}
        expandIcon={<ExpandMore />}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <div style={{marginRight: 20}}>
            <Typography style={{fontWeight: '600', fontSize: 20}}>
              Calculated by user
            </Typography>
            <Typography style={{fontWeight: '400', fontSize: 13}}>
              {`${format(new Date(calculationDate), 'd MMM YYY, H:mm')}`}
            </Typography>
          </div>
          <Typography style={{fontWeight: '600', fontSize: 16}}>
            {CurrencyNumberFormat(totalTaxAmount)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            padding: 16,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: 20,
            }}>
            <div>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: 13,
                  color: themmeColor.black60,
                }}>
                Federal
              </Typography>
              <Typography
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                }}>
                {CurrencyNumberFormat(
                  federalTaxData.federal_quarterly_tax ?? 0,
                )}
              </Typography>
            </div>
            {stateTaxData.map((stateData) => {
              return (
                <div>
                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: 13,
                      color: themmeColor.black60,
                    }}>
                    {stateAbbreviationNameMap[
                      stateData.state_abbreviation ?? ''
                    ] ?? ''}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: '600',
                      fontSize: 18,
                    }}>
                    {CurrencyNumberFormat(stateData.state_quarterly_tax ?? 0)}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div
            style={{
              marginTop: 20,
              flex: 1,
            }}>
            <Typography
              style={{
                fontWeight: '600',
                fontSize: 16,
                color: themmeColor.black60,
              }}>
              Federal calc breakdown
            </Typography>
            <JSONPretty
              id={`json-pretty-federal`}
              data={federalTaxData}
              theme={JSONPrettyMon}
              style={{fontSize: 12, overflowX: 'scroll', width: 500}}
            />
          </div>
          {stateTaxData.map((stateData) => {
            return (
              <div
                style={{
                  marginTop: 20,
                  flex: 1,
                }}>
                <Typography
                  style={{
                    fontWeight: '600',
                    fontSize: 16,
                    color: themmeColor.black60,
                  }}>
                  {`${
                    stateAbbreviationNameMap[
                      stateData.state_abbreviation ?? ''
                    ] ?? ''
                  } calc breakdown`}
                </Typography>
                <JSONPretty
                  id={`json-pretty-state-${
                    stateData.state_abbreviation ?? ''
                  }`}
                  data={stateData}
                  theme={JSONPrettyMon}
                  style={{fontSize: 12, overflowX: 'scroll', width: 500}}
                />
              </div>
            );
          })}
          <div style={{paddingLeft: 20}}>
            <Divider style={{marginBottom: 20, marginTop: 20}} />
            <div>
              <Typography style={{fontWeight: '600', fontSize: 18}}>
                Basic details
              </Typography>
              <div style={{padding: 10}}>
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="Filing status"
                  value={qtcInputData?.[TaxProfileQuestion.FILING_STATUS]}
                />
                <SimpleArrayField
                  style={{marginTop: 12}}
                  name="Earning states"
                  value={qtcInputData?.[TaxProfileQuestion.EARNING_STATES]}
                />
              </div>
            </div>
            <Divider style={{marginBottom: 20, marginTop: 20}} />
            <div>
              <Typography style={{fontWeight: '600', fontSize: 18}}>
                User income details
              </Typography>
              <div style={{padding: 10}}>
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="1099 income"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[TaxProfileQuestion.FREELANCE_EARNINGS] ?? 0,
                  )}
                />
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="W-2 income"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[TaxProfileQuestion.W2_INCOME] ?? 0,
                  )}
                />
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="W-2 Federal withheld"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[TaxProfileQuestion.FEDERAL_W2_WITHHELD] ??
                      0,
                  )}
                />
                {qtcInputData?.[TaxProfileQuestion.EARNING_STATES].map(
                  (state) => {
                    return (
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name={`W-2 ${state} state withheld`}
                        value={CurrencyNumberFormat(
                          qtcInputData?.[
                            TaxProfileQuestion.STATE_W2_WITHHELD
                          ]?.[state] ?? 0,
                        )}
                      />
                    );
                  },
                )}
              </div>
            </div>
            <Divider style={{marginBottom: 20, marginTop: 20}} />
            <div>
              <Typography style={{fontWeight: '600', fontSize: 18}}>
                Spouse income details
              </Typography>
              <div style={{padding: 10}}>
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="1099 income"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[
                      TaxProfileQuestion.SPOUSE_FREELANCE_INCOME
                    ] ?? 0,
                  )}
                />
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="W-2 income"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[TaxProfileQuestion.SPOUSE_W2_INCOME] ?? 0,
                  )}
                />
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="W-2 Federal withheld"
                  value={CurrencyNumberFormat(
                    qtcInputData?.[
                      TaxProfileQuestion.SPOUSE_FEDERAL_W2_WITHHELD
                    ] ?? 0,
                  )}
                />
                {qtcInputData?.[TaxProfileQuestion.EARNING_STATES].map(
                  (state) => {
                    return (
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name={`W-2 ${state} state withheld`}
                        value={CurrencyNumberFormat(
                          qtcInputData?.[
                            TaxProfileQuestion.SPOUSE_STATE_W2_WITHHELD
                          ]?.[state] ?? 0,
                        )}
                      />
                    );
                  },
                )}
              </div>
            </div>
            <Divider style={{marginBottom: 20, marginTop: 20}} />
            <div>
              <Typography style={{fontWeight: '600', fontSize: 18}}>
                Deduction details
              </Typography>
              <div style={{paddingLeft: 10}}>
                <SimpleKeyValueField
                  style={{marginTop: 12}}
                  name="Deductions claimed"
                  value={CurrencyNumberFormat(totalBizDeductions)}
                />
                <Divider style={{marginBottom: 12, marginTop: 12}} />
                <div>
                  <Typography style={{fontWeight: '600', fontSize: 16}}>
                    Home office details
                  </Typography>
                  <SimpleKeyValueField
                    style={{marginTop: 12}}
                    name="Do you work from home?"
                    value={taxProfile[TaxProfileQuestion.WFH]}
                  />
                  {taxProfile[TaxProfileQuestion.WFH] != null &&
                    taxProfile[TaxProfileQuestion.WFH] === WFH_ANSWER.YES && (
                      <>
                        <SimpleKeyValueField
                          style={{marginTop: 12}}
                          name="Total area of your home (sq. ft.)"
                          value={
                            taxProfile[TaxProfileQuestion.HOME_OFFICE_AREA]?.[
                              TaxProfileField.HOME_AREA
                            ]
                          }
                        />
                        <SimpleKeyValueField
                          style={{marginTop: 12}}
                          name="Area of your home office space (sq. ft.)"
                          value={
                            taxProfile[TaxProfileQuestion.HOME_OFFICE_AREA]?.[
                              TaxProfileField.OFFICE_AREA
                            ]
                          }
                        />
                      </>
                    )}
                </div>
                <Divider style={{marginBottom: 12, marginTop: 12}} />
                <div>
                  <Typography style={{fontWeight: '600', fontSize: 16}}>
                    Business vehicle usage
                  </Typography>
                  <SimpleKeyValueField
                    style={{marginTop: 12}}
                    name="Do you use vehicle for business?"
                    value={
                      taxProfile[TaxProfileQuestion.VEHICLE_USED_FOR_BUSINESS]
                    }
                  />
                  {taxProfile[
                    TaxProfileQuestion.VEHICLE_USED_FOR_BUSINESS
                  ] && (
                    <>
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name="Total miles driven in 2024 so far"
                        value={
                          taxProfile[TaxProfileQuestion.MILES]?.[
                            TaxProfileField.TOTAL_MILES
                          ]
                        }
                      />
                      <SimpleKeyValueField
                        style={{marginTop: 12}}
                        name="Business miles driven in 2024 so far"
                        value={
                          taxProfile[TaxProfileQuestion.MILES]?.[
                            TaxProfileField.BUSINESS_MILES
                          ]
                        }
                      />
                    </>
                  )}
                </div>
                <Divider style={{marginBottom: 12, marginTop: 12}} />
                <div>
                  <Typography style={{fontWeight: '600', fontSize: 16}}>
                    Phone & internet business usage
                  </Typography>
                  <SimpleKeyValueField
                    style={{marginTop: 12}}
                    name="Phone business usage"
                    value={`${
                      taxProfile[TaxProfileQuestion.PHONE_PCT] ?? 'NA'
                    }%`}
                  />
                  <SimpleKeyValueField
                    style={{marginTop: 12}}
                    name="Internet business usage"
                    value={`${
                      taxProfile[TaxProfileQuestion.INTERNET_PCT] ?? 'NA'
                    }%`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default EndUserQtcInput;
