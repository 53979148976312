import React, {useMemo, useState} from 'react';
import TaxReturnFieldGridWrapper from './TaxReturnFieldGridWrapper';
import {IndividualReturnFieldsConfig} from '../CreateReturn.constants';
import {Form} from 'src/DesignSystem/Form/Form';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {ReturnFields, UserRelationType} from '../CreateReturn.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {getOcrBoxes} from '../CreateReturn.utils';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import PDFWithOcrBoxes from '../../../../../DesignSystem/OCR/PDFWithOcrBoxes';
import DownIcon from 'src/icons/DownIcon';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {themmeColor} from 'src/constants/constants';

interface IndividualTaxReturnFieldsProps {
  fieldsDictionary: Record<
    string,
    ReturnType<typeof useFormData>['fields'][0]
  >;
  user: UserRelationType | null;
  validationDisabled: boolean;
}

const IndividualTaxReturnFields = ({
  fieldsDictionary,
  validationDisabled,
  user,
}: IndividualTaxReturnFieldsProps) => {
  const [showDocument, setShowDocument] = useState(false);
  const individualReturnFields = IndividualReturnFieldsConfig.map(
    (field) => field.path,
  ).filter((path) => {
    if (path === ReturnFields.should_file_individual_return) {
      return false;
    }
    if (fieldsDictionary[path] === undefined) {
      return false;
    }
    return true;
  });

  const ocrBoxes = useMemo(() => {
    return getOcrBoxes(user, [
      TaxProfileField.FILING_STATUS,
      TaxProfileField.SPOUSE_SSN,
      TaxProfileField.FIRST_NAME,
      TaxProfileField.LAST_NAME,
      TaxProfileField.SSN,
    ]);
  }, [user]);

  return (
    <div>
      <TaxReturnFieldGridWrapper style={{paddingTop: 16}}>
        {individualReturnFields.map((fieldPath) => (
          <Form
            {...fieldsDictionary[fieldPath]}
            disableValidation={validationDisabled}
          />
        ))}
      </TaxReturnFieldGridWrapper>
      {user !== null && ocrBoxes.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 12,
            marginBottom: showDocument ? 12 : 0,
          }}>
          <DSButton
            type="secondary"
            text={showDocument ? 'Hide document' : 'Show document'}
            onClick={() => {
              setShowDocument(!showDocument);
            }}
            startIcon={showDocument ? <ArrowUpward /> : <ArrowDownward />}
          />
        </div>
      )}
      {showDocument && user !== null && (
        <PDFWithOcrBoxes
          pdfUrl={user[ReturnFields.read_url]}
          ocrData={ocrBoxes}
          onlyRenderPagesWithOcrFields
        />
      )}
    </div>
  );
};

export default IndividualTaxReturnFields;
