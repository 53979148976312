import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import useStateInfo from 'src/CpaCenterV2/hooks/useStateInfo';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import CrossIcon from 'src/icons/CrossIcon';
import {setAppState, setStatesInfoDrawer} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectStateInfoDrawer} from 'src/store/app/app.selector';
import MultiKeyValueField from '../../common/MultiKeyValueField';
import {DATE_FORMATS, themmeColor} from 'src/constants/constants';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import Box from 'src/DesignSystem/Box/Box';
import {format} from 'date-fns';
import {getDateFromYYYYMMDD} from 'src/common/utils';

const StatesInfoDrawer = () => {
  const {isVisible, isDefault, isSpouse, taxFormId} = useSelector(
    selectStateInfoDrawer,
  );

  const {statesInfo, fields, periods} = useStateInfo({
    isVisible,
    isDefault,
    isSpouse,
    taxFormId,
  });

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(
      setStatesInfoDrawer({
        isDefault: false,
        isSpouse: false,
        taxFormId: null,
        isVisible: false,
      }),
    );
  };

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      height={'85vh'}
      isOpen={isVisible}
      title={`${statesInfo[TaxProfileField.STATE]} ${
        statesInfo.current_address ? '(Current)' : ''
      } ${isSpouse ? '(Spouse)' : ''}`}
      onClose={onClose}>
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          paddingInline: 20,
        }}>
        <Text
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.ExtraBold}
          text={'Period of stay'}
        />
        <ol>
          {periods?.map((period) => (
            <li>
              {format(
                getDateFromYYYYMMDD(period[TaxFormFieldIds.FROM]),
                DATE_FORMATS.DATE_AND_MONTH,
              )}{' '}
              -{' '}
              {format(
                getDateFromYYYYMMDD(period[TaxFormFieldIds.TO]),
                DATE_FORMATS.DATE_AND_MONTH,
              )}
            </li>
          ))}
        </ol>

        <Box borderColor={themmeColor.offWhite} style={{marginBlock: 12}} />

        <MultiKeyValueField data={statesInfo} fields={fields} />
      </div>
    </Drawer>
  );
};

export default StatesInfoDrawer;
