import _ from 'lodash';
import {OverWriteFormFieldsFunctionType} from 'src/DesignSystem/Form/useFormData';
import {
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {
  getCountyNameAndCodeMapForState,
  getPath,
  isCountyRequired,
} from '../utils';
import {isSchoolDistrictCodeRequired} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';

export const overWriteTaxProfileFields: OverWriteFormFieldsFunctionType = ({
  fields,
  data,
  setData,
}) => {
  const fieldsToRemove = new Set<string>();
  const fieldByPath = _.keyBy(fields, 'path');

  const livingOutsideUSAField =
    fieldByPath[TaxProfileQuestion.LIVING_OUTSIDE_USA];
  if (livingOutsideUSAField !== undefined) {
    const livingOutside = data[TaxProfileQuestion.LIVING_OUTSIDE_USA];
    if (!livingOutside) {
      fieldsToRemove.add(
        getPath(TaxProfileQuestion.MAILING_ADDRESS_OUTSIDE_USA),
      );
    } else {
      const homeAddressFields = [
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.STREET_NUMBER,
        ),
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.APARTMENT_NUMBER,
        ),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.CITY),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.ZIP_CODE),
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.SCHOOL_DISTRICT_CODE,
        ),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.COUNTY),
      ];
      homeAddressFields.forEach((fieldPath) => fieldsToRemove.add(fieldPath));
    }
  }

  const schoolDistrictField =
    fieldByPath[
      getPath(
        TaxProfileQuestion.HOME_ADDRESS,
        TaxProfileField.SCHOOL_DISTRICT_CODE,
      )
    ];
  if (schoolDistrictField !== undefined) {
    const state = _.get(
      data,
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE, '[0]'),
    );
    if (!isSchoolDistrictCodeRequired(state)) {
      fieldsToRemove.add(schoolDistrictField.path);
    }
  }

  const countyField =
    fieldByPath[
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.COUNTY)
    ];

  if (
    countyField !== undefined &&
    countyField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    const state = _.get(
      data,
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE, '[0]'),
    );
    if (!isCountyRequired(state)) {
      fieldsToRemove.add(
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.COUNTY),
      );
    } else {
      countyField.options = Object.entries(
        getCountyNameAndCodeMapForState(state),
      ).map(([countyName, countyCode]) => {
        return {
          label: countyName,
          value: countyCode,
        };
      });
    }
  }

  const stateField =
    fieldByPath[
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE)
    ];

  if (stateField !== undefined) {
    stateField.onChangeValue = (newValue: any) => {
      setData((prev: any) => {
        let updatedState = {..._.set(prev, stateField.path, newValue)};
        if (countyField !== undefined) {
          updatedState = _.set(updatedState, countyField.path, null);
        }
        return updatedState;
      });
    };
  }

  return fields.filter((field) => !fieldsToRemove.has(field.path));
};
