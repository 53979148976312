import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  deleteCpaQueryMessage,
  getQtcData,
  getQueriesList,
  getTaxProfile,
  getTransactions,
  patchCpaQueryMessage,
  postDraftMessage,
  postMessage,
} from 'src/appApi';
import {
  AuthorType,
  CPAQuestionMessageType,
  LocalStorageKey,
  themmeColor,
} from 'src/constants/constants';
import {
  parseTaxProfileBackendResponse,
  sectionOrdering,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import RateConversationModal from 'src/UserQueries/components/RateConversationModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GenerateTaxProfileField, {
  GenerateTaxProfileFieldProps,
} from 'src/UserQueries/components/GenerateTaxProfileFields';
import {BackendQuery} from 'src/types/query.types';
import _ from 'lodash';
import CpaChat, {SendMessageHandler} from 'src/common/CpaChat/CpaChat';
import {getCurrentUserId} from 'src/authProvider';
import {makeStyles} from '@material-ui/core/styles';
import QueryPageHeader from 'src/UserQueries/QueryPageHeader';
import {
  RatingPermissionUserEmails,
  SupportedQueryTypes,
} from 'src/UserQueries/Queries.constants';
import QueryChatHeader from 'src/common/CpaChat/QueryChatHeader';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {
  QuarterlyPaymentType,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {parseSingleTransaction} from 'src/store/transactions/transactions.utils';
import {
  RawTransaction,
  Transaction,
} from 'src/store/transactions/transactions.reducer';
import QtcPayments from './components/QtcPayments';
import {QtcInputData} from 'src/appApi.types';
import EndUserQtcInput from './components/EndUserQtcInput';
import AIQtcInput from './components/AIQtcInput';
import {
  CpaQueryViewerProvider,
  useCpaQueryViewer,
} from './CpaQueryViewerContext';
import ExpenseListDrawer from './components/ExpenseListDrawer';

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  chatContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: `1px solid ${themmeColor.silver}`,
    borderRadius: 12,
  },
  otherQueryTitle: {
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 16,
  },
  otherQuery: {
    border: `1px solid ${themmeColor.silver}`,
    borderRadius: 12,
    overflow: 'hidden',
    marginTop: 12,
    cursor: 'pointer',
  },
});

const CpaQueryViewerContent = () => {
  const styles = useStyles();
  const {
    queryData,
    setQueryData,
    txnData,
    setTxnData,
    pageLoading,
    setPageLoading,
  } = useCpaQueryViewer();
  const [otherQueries, setOtherQueries] = useState<Array<BackendQuery>>([]);
  const [groupedTaxProfile, setGroupedTaxProfile] = useState({});
  const [showRatingModal, setShowRatingModal] = useState(false);
  const {queryId} = useParams();
  const navigate = useNavigate();
  const [qtcData, setQtcData] = useState<QtcInputData | null>(null);

  const [taxProfile, setTaxProfile] = useState<{[key: string]: any}>({});

  const userEmail = useMemo(
    () => localStorage.getItem(LocalStorageKey.UserEmail),
    [],
  );

  const isRatingPermitted =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? RatingPermissionUserEmails.includes(userEmail)
      : true;

  const fetchQuery = async () => {
    const queryResponse = await getQueriesList({
      id: queryId,
      is_conversation_required: true,
    });
    const query = queryResponse.data.results[0];
    setQueryData(query);
    return query;
  };

  const fetchData = async () => {
    setPageLoading(true);
    try {
      const query = await fetchQuery();
      Promise.all([
        await getTaxProfile({
          fly_user_id: query.fly_user_id,
          years: query.app_year,
        }),
        await getQueriesList({
          fly_user_id: query.fly_user_id,
          app_year: query.app_year,
          type: SupportedQueryTypes,
        }),
        getQtcData({
          fly_user_id: query.fly_user_id,
        }),
        getTransactions({user_id: query.fly_user_id, year: [query.app_year]}),
      ]).then(
        ([
          taxProfileResponse,
          otherQueriesResponse,
          qtcDataResponse,
          transactionsResponse,
        ]) => {
          const taxProfileData = taxProfileResponse.data[query.app_year];
          setGroupedTaxProfile(parseTaxProfileBackendResponse(taxProfileData));
          setOtherQueries(
            otherQueriesResponse.data.results.filter(
              (otherQuery) => query.id !== otherQuery.id,
            ),
          );
          setQtcData(qtcDataResponse.data);

          const transactionIdMap: {[key: number]: Transaction} = {};
          transactionsResponse.data.forEach((backendTxn) => {
            const txn = parseSingleTransaction(backendTxn);
            transactionIdMap[txn.txnId] = txn;
          });
          setTxnData(transactionIdMap);

          const taxProfileMap: {[key: string]: any} = {};
          taxProfileData.forEach((backendTPQ) => {
            taxProfileMap[backendTPQ.question_id] = backendTPQ.answer;
          });
          setTaxProfile(taxProfileMap);
        },
      );
      setPageLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  const groupedQtcPayments = useMemo(() => {
    const groupedForms: {[key: string]: GenericTaxForm[]} = {};
    if (qtcData != null) {
      qtcData.qt_payments.forEach((form) => {
        const {
          is_end_user_reviewed: isEndUserReviewed,
          doc_link: docLink,
          form_data: formData,
          form_type: formType,
          id: taxFormId,
          tfd_info_id: docId,
        } = form;
        const transformedForm = {
          isEndUserReviewed,
          docId,
          docLink,
          formData,
          formType,
          taxFormId,
        };
        if (
          form.form_data[TaxFormFieldIds.PAYMENT_TYPE] ===
          QuarterlyPaymentType.FEDERAL
        ) {
          groupedForms['Federal'] = [
            ...(groupedForms['Federal'] ?? []),
            transformedForm,
          ];
        } else if (
          form.form_data[TaxFormFieldIds.PAYMENT_TYPE] ===
          QuarterlyPaymentType.STATE
        ) {
          groupedForms[form.form_data[TaxFormFieldIds.STATE]] = [
            ...(groupedForms[form.form_data[TaxFormFieldIds.STATE]] ?? []),
            transformedForm,
          ];
        }
      });
    }

    return groupedForms;
  }, [qtcData]);

  const handleEditDraft = async (draftId: number, message: string) => {
    await patchCpaQueryMessage(draftId, {message});
    await fetchQuery();
  };

  const handleDeleteDraft = async (draftId: number) => {
    await deleteCpaQueryMessage(draftId);
    await fetchQuery();
  };

  const handlePostQuery: SendMessageHandler = async (message, extra) => {
    if (!queryId) return;
    if (extra.isDraft) {
      if (!extra.draftId) {
        alert('draft id is not present');
        return;
      }
      await postDraftMessage({
        draft_id: extra.draftId,
        is_terminal: extra.isTerminal,
      });
    } else {
      await postMessage({
        message,
        cpa_question_id: queryId,
        author_type: AuthorType.CPA,
        type: CPAQuestionMessageType.CHAT,
        is_draft: extra.asDraft,
        is_terminal: extra.isTerminal,
      });
    }
    await fetchQuery();
  };

  const goToAllQueries = () => {
    navigate('..');
  };

  useEffect(() => {
    fetchData();
  }, [queryId]);

  return (
    <Grid
      container
      gridRow={2}
      height={'100vh'}
      columnSpacing={1}
      overflow={'hidden'}>
      {pageLoading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container item xs={12} height={'10%'} minWidth={'100%'}>
            <QueryPageHeader query={queryData} goBack={goToAllQueries} />
          </Grid>
          <Grid item xs={5} height={'90%'} style={{overflow: 'auto'}}>
            {sectionOrdering.map(
              (sectionName) =>
                groupedTaxProfile[sectionName] && (
                  <Accordion>
                    <AccordionSummary
                      sx={{backgroundColor: themmeColor.lightBlue}}
                      expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={'bold'} fontSize={20}>
                        {sectionName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {groupedTaxProfile[sectionName].map(
                        (questionObject: GenerateTaxProfileFieldProps) => (
                          <GenerateTaxProfileField {...questionObject} />
                        ),
                      )}
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
            <Accordion>
              <AccordionSummary
                sx={{backgroundColor: themmeColor.lightBlue}}
                expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={'bold'} fontSize={20}>
                  {'Quarterly Taxes'}
                </Typography>
              </AccordionSummary>
              {qtcData != null && (
                <AccordionDetails>
                  {/* CALCULATED BY USER */}
                  {qtcData.end_user.map((endUserData) => {
                    return (
                      <div style={{marginTop: 20}}>
                        <EndUserQtcInput
                          totalTaxAmount={endUserData.total_tax_to_pay ?? 0}
                          calculationDate={endUserData.created_at ?? ''}
                          federalTaxData={
                            endUserData.federal_tax_details ?? {}
                          }
                          stateTaxData={Object.values(
                            endUserData.state_tax_details ?? {},
                          )}
                          qtcInputData={
                            endUserData.input_data?.tax_profile ?? {}
                          }
                          totalBizDeductions={
                            endUserData.input_data?.biz_deductions ?? 0
                          }
                          taxProfile={taxProfile}
                        />
                      </div>
                    );
                  })}

                  {/* CALCULATED BY AI */}
                  {qtcData.tax_form?.calculated_by != null && (
                    <div style={{marginTop: 20}}>
                      <AIQtcInput
                        totalTaxAmount={
                          qtcData.tax_form?.total_tax_to_pay ?? 0
                        }
                        calculationDate={
                          qtcData.tax_form?.calculated_date ?? ''
                        }
                        federalTaxData={
                          qtcData.tax_form?.federal_tax_details ?? {}
                        }
                        stateTaxData={Object.values(
                          qtcData.tax_form?.state_tax_details ?? {},
                        )}
                      />
                    </div>
                  )}

                  {/* QUATERLY PAYMENTS */}
                  {!_.isEmpty(groupedQtcPayments) && (
                    <div
                      style={{
                        marginTop: 20,
                        background: themmeColor.offGrey,
                        borderRadius: 20,
                        padding: 16,
                      }}>
                      <Typography style={{fontWeight: '600', fontSize: 20}}>
                        Quarterly tax payments
                      </Typography>
                      <div style={{marginTop: 20}}>
                        {Object.entries(groupedQtcPayments).map(
                          ([key, forms]) => {
                            return (
                              <div style={{marginTop: 12}}>
                                <Typography
                                  style={{fontWeight: '600', fontSize: 16}}>
                                  {`${
                                    stateAbbreviationNameMap[key] ?? 'Federal'
                                  } tax payments`}
                                </Typography>
                                <div style={{marginTop: 4}}>
                                  <QtcPayments
                                    forms={forms}
                                    transactionIdMap={txnData}
                                  />
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
          <Grid item xs={7} height={'90%'} style={{overflow: 'hidden'}}>
            {!_.isEmpty(queryData) && (
              <div className={styles.rightContainer}>
                <div className={styles.chatContainer}>
                  <CpaChat
                    queryData={queryData}
                    onSendMessage={handlePostQuery}
                    onDoneEditDraft={handleEditDraft}
                    onDeleteDraft={handleDeleteDraft}
                    flyUserId={queryData.fly_user_id}
                    cpaId={getCurrentUserId()}
                    txnData={txnData}
                  />
                  {isRatingPermitted && (
                    <>
                      <Divider />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}>
                        <Button
                          variant={'text'}
                          onClick={() => setShowRatingModal(true)}>
                          RATE CONVERSATION
                        </Button>
                      </div>
                      <RateConversationModal
                        queryId={queryId}
                        ratingId={queryData.cpa_resp_rating_id}
                        open={showRatingModal}
                        closeModal={() => setShowRatingModal(false)}
                      />
                    </>
                  )}
                </div>
                <div style={{marginBottom: 16}}>
                  <Typography className={styles.otherQueryTitle}>
                    OTHER QUERIES FROM THE USER
                  </Typography>
                  {otherQueries.map((query) => (
                    <div
                      onClick={() =>
                        navigate(`../${query.id}`, {replace: true})
                      }
                      className={styles.otherQuery}>
                      <QueryChatHeader
                        txnData={txnData}
                        queryData={query}
                        showStatus
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Grid>
        </>
      )}
      <ExpenseListDrawer />
    </Grid>
  );
};

const CpaQueryViewer = () => {
  return (
    <CpaQueryViewerProvider>
      <CpaQueryViewerContent />
    </CpaQueryViewerProvider>
  );
};

export default CpaQueryViewer;
