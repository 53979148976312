import {TaxProfileSubsection} from 'src/constants/constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';

export const PartnerCreditsSubsectionConfig = {
  [TaxProfileSubsection.DependentCredits]: {
    taxProfileQuestions: [],
    field: TaxFormFieldIds.IS_ELIGIBLE_FOR_DEPENDENT_CREDIT,
    creditName: 'dependent',
  },
  [TaxProfileSubsection.EducationalCredits]: {
    taxProfileQuestions: [
      TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
      TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT,
    ],
    field: TaxFormFieldIds.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
    creditName: 'education',
  },
  [TaxProfileSubsection.AdoptionCredits]: {
    taxProfileQuestions: [],
    field: TaxFormFieldIds.IS_ELIGIBLE_FOR_ADOPTION_CREDIT,
    creditName: 'adoption',
  },
} as const;
