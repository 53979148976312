import React from 'react';
import {useSelector} from 'react-redux';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {TaxProfileSubsection} from 'src/constants/constants';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {ReduxStateType} from 'src/store/store';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';

interface UserDetailsProps {
  style?: React.CSSProperties;
  onEdit?: (section: TaxProfileSubsection) => void;
}

const UserDetails = ({style, onEdit}: UserDetailsProps) => {
  const {loaded} = useSelector(selectTaxProfile);

  const {activeYear} = useActiveYear();

  const legalName = useSelector((state: ReduxStateType) => {
    return selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.LEGAL_NAME,
      activeYear,
    );
  });
  const ssn = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.SSN, activeYear),
  );
  const dob = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.DOB, activeYear),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const userClaimedDependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.CLAIM_YOU_DEPENDENT,
      activeYear,
    ),
  );
  const dependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.DEPENDENT, activeYear),
  );
  const phoneNumber =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.PHONE_NUMBER,
        activeYear,
      ),
    ) ?? '';

  return (
    <SubSectionBox
      name="User details"
      onEdit={onEdit}
      style={style}
      taxprofileSubsection={TaxProfileSubsection.UserDetails}>
      <SimpleKeyValueField
        name="First name & middle initial"
        style={{marginBottom: 12}}
        value={legalName?.[TaxProfileField.FIRST_NAME]}
        loading={!loaded}
      />
      <SimpleKeyValueField
        name="Last name"
        style={{marginBottom: 12}}
        value={legalName?.[TaxProfileField.LAST_NAME]}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Social Security Number"
        loading={!loaded}
        value={ssn}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Date of birth"
        value={dob}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Filing status"
        value={filingStatus}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Phone number"
        value={phoneNumber}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Can someone else claim you as a dependent?"
        value={userClaimedDependent}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Can you claim dependent(s)?"
        value={dependent}
        loading={!loaded}
      />
    </SubSectionBox>
  );
};

export default UserDetails;
