import React, {ReactNode} from 'react';
import {InlineNotificationType} from './InlineNotification.types';
import {InlineNotificationVariantsProps} from './InlineNotification.constants';
import {isNull, isString} from 'lodash';
import Text from '../Form/common/Text/Text';
import {FONT_WEIGHTS, TEXT_TYPES} from '../Form/common/Text/Text.types';

interface InlineNotificationProps {
  icon?: ReactNode;
  content?: ReactNode | ReactNode[];
  text?: string;
  variant: InlineNotificationType;
}

const InlineNotification = ({
  icon = null,
  content = null,
  text,
  variant,
}: InlineNotificationProps) => {
  const props = InlineNotificationVariantsProps[variant];

  return (
    <div
      style={{
        display: 'flex',
        paddingInline: 16,
        paddingBlock: 12,
        borderRadius: 8,
        backgroundColor: props.backgroundColor,
        alignItems: 'center',
      }}>
      {!isNull(icon) ? (
        <div style={{marginRight: 8, display: 'flex'}}>{icon}</div>
      ) : null}
      {isString(text) ? (
        <Text
          text={text}
          type={TEXT_TYPES.BASE}
          fontWeight={FONT_WEIGHTS.SemiBold}
          color={props.textColor}
        />
      ) : null}
      {content}
    </div>
  );
};

export default InlineNotification;
