import React from 'react';
import {useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import CCHCheckBox from './CCHCheckBox';
import {Integrator} from './CCHAndDrake.types';

interface CCHSectionsProps {
  drakeNameSections: {};
  integrator: Integrator;
  drakeSelectedSections: {[key: string]: string[]};
  setDrakeSelectedSections: (newValue: {[key: string]: string[]}) => void;
  selectedSections: {[key: string]: string[]};
  setSelectedSections: (newValue: {[key: string]: string[]}) => void;
  isLoading: boolean;
}

const CCHSections = ({
  drakeNameSections,
  drakeSelectedSections,
  integrator,
  setDrakeSelectedSections,
  selectedSections,
  setSelectedSections,
  isLoading,
}: CCHSectionsProps) => {
  const {sections, sectionWiseSubsection, status_info} =
    useSelector(selectCCHStatus);

  const onClickSection = (section: string, toAdd: boolean) => {
    if (toAdd) {
      const targetList = [...sectionWiseSubsection[section]];
      const targetState = {...selectedSections, [section]: targetList};
      setSelectedSections(targetState);
    } else {
      const targetState = {...selectedSections, [section]: []};
      setSelectedSections(targetState);
    }
  };

  const onClickDrakeSection = (section: string, toAdd: boolean) => {
    if (toAdd) {
      const targetList = [...drakeNameSections[section]];
      const targetState = {...drakeSelectedSections, [section]: targetList};
      setDrakeSelectedSections(targetState);
    } else {
      const targetState = {...drakeSelectedSections, [section]: []};
      setDrakeSelectedSections(targetState);
    }
  };

  return (
    <div>
      <div>
        {integrator === Integrator.DRAKE
          ? Object.keys(drakeNameSections).map((section) => {
              // const isSectionSelected = sectionWiseSubsection[section].every(
              //   (item) => selectedSections[section]?.includes(item),
              // );
              const isDrakeSectionSelected = drakeNameSections[section].every(
                (item) => drakeSelectedSections[section]?.includes(item),
              );
              return (
                <Box
                  key={section}
                  col
                  style={{
                    marginBottom: 12,
                    overflow: 'hidden',
                    marginRight: 8,
                    paddingBottom: 8,
                  }}>
                  {/* TODO: Create fork for CCH and drake */}
                  <CCHCheckBox
                    value={section}
                    isChecked={isDrakeSectionSelected}
                    isSubsection={false}
                    onClick={() =>
                      onClickDrakeSection(section, !isDrakeSectionSelected)
                    }
                    isLoading={isLoading}
                  />
                  <div style={{paddingLeft: 80, backgroundColor: 'white'}}>
                    {drakeNameSections[section].map((subsection) => {
                      return (
                        <CCHCheckBox
                          value={subsection}
                          isSubsection={true}
                          isLoading={isLoading}
                          // subsectionStatus={status_info[section][subsection]}
                        />
                      );
                    })}
                  </div>
                </Box>
              );
            })
          : sections.map((section) => {
              const isSectionSelected = sectionWiseSubsection[section].every(
                (item) => selectedSections[section]?.includes(item),
              );
              return (
                <Box
                  key={section}
                  col
                  style={{
                    marginBottom: 12,
                    overflow: 'hidden',
                    marginRight: 8,
                    paddingBottom: 8,
                  }}>
                  <CCHCheckBox
                    value={section}
                    isChecked={isSectionSelected}
                    isSubsection={false}
                    onClick={() => onClickSection(section, !isSectionSelected)}
                    isLoading={isLoading}
                  />
                  <div style={{paddingLeft: 80, backgroundColor: 'white'}}>
                    {sectionWiseSubsection[section].map((subsection) => {
                      return (
                        <CCHCheckBox
                          value={subsection}
                          isSubsection={true}
                          isLoading={isLoading}
                          subsectionStatus={status_info[section][subsection]}
                        />
                      );
                    })}
                  </div>
                </Box>
              );
            })}
      </div>
    </div>
  );
};

export default CCHSections;
