import React, {ComponentProps, useState} from 'react';
import {
  TaxProfileSection,
  TaxProfileSubsection,
} from 'src/constants/constants';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import RelatedDocuments from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/RelatedDocuments/RelatedDocuments';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import UserDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/UserDetails/UserDetails';
import HomeAddress from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/HomeAddress/HomeAddress';
import SpouseDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/SpouseDetails/SpouseDetails';
import DependentDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/DependentDetails/DependentDetails';
import BusinessDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/BusinessDetails/BusinessDetails';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import EditBusinessDetails from 'src/CpaCenterV2/common/EditBusinessDetails/EditBusinessDetails';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';

const PartnerBasicDetails = () => {
  const {navigateToDocumentReview} = usePartnershipNavigation();
  const {onEditTaxProfileSubsection, onEditTaxForm, onAddTaxForm} =
    usePartnerTaxProfileReview();

  const [addBusiness, setAddBusiness] = useState(false);

  const onEdit = (subsection: TaxProfileSubsection) => {
    onEditTaxProfileSubsection(subsection);
  };
  const onEditDependents: ComponentProps<
    typeof DependentDetails
  >['onEditTaxForm'] = (taxFormId) => {
    onEditTaxForm({
      taxFormId,
      taxFormType: TaxFormType.DEPENDENT,
      title: 'Edit Dependents',
    });
  };

  const onAddDependent = () => {
    onAddTaxForm({taxFormType: TaxFormType.DEPENDENT, title: 'Add dependent'});
  };

  const onAddBusiness = () => {
    setAddBusiness(true);
  };

  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          sectionName="Basic Details"
          section={TaxProfileSection.BasicDetails}
        />
        <div
          style={{
            overflowY: 'auto',
          }}>
          <RelatedDocuments onClick={navigateToDocumentReview} />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit,minmax(350px,1fr))',
            }}>
            <UserDetails onEdit={onEdit} />
            <HomeAddress onEdit={onEdit} />
            <SpouseDetails onEdit={onEdit} />
            <DependentDetails
              onEditTaxForm={onEditDependents}
              onAddTaxForm={onAddDependent}
            />
            <BusinessDetails onAddBusiness={onAddBusiness} showDeleteBiz />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.25,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <ActionRequired
          title="Comments"
          dontAllowAdd
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          taxProfileSection={TaxProfileSection.BasicDetails}
        />
      </div>
      <EditBusinessDetails
        isOpen={addBusiness}
        onClose={() => setAddBusiness(false)}
      />
    </div>
  );
};

export default PartnerBasicDetails;
