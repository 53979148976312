import React, {useCallback} from 'react';
import {Add} from '@mui/icons-material';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import {
  FORM_INPUT_TYPE,
  FormInputProps,
} from 'src/DesignSystem/Form/Form.types';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import {DATE_FORMATS, themmeColor} from 'src/constants/constants';
import DeleteIcon from 'src/icons/DeleteIcon';
import {keyBy} from 'lodash';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {lastDateOfYear, startDateOfYear} from 'src/common/utils';
import {format} from 'date-fns';

interface SinglePeriodProps extends ArrayChildProps {}

const SinglePeriod = ({
  array,
  arrayObjectIndex,
  onChangeValue,
  childProps,
}: SinglePeriodProps) => {
  const {arrayObject, updateObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );
  const {activeYear} = useActiveYear();

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    ({fields, data}) => {
      const fieldByPath = keyBy(fields, 'path');
      const fromField = fieldByPath[TaxFormFieldIds.FROM];

      const yearStart = format(
        startDateOfYear(activeYear),
        DATE_FORMATS.DB_FORMAT,
      );
      const yearEnd = format(
        lastDateOfYear(activeYear),
        DATE_FORMATS.DB_FORMAT,
      );

      if (fromField && fromField.inputType === FORM_INPUT_TYPE.Date) {
        fromField.min = yearStart;
        fromField.max = yearEnd;
        if (typeof data[TaxFormFieldIds.TO] === 'string') {
          fromField.max = data[TaxFormFieldIds.TO];
        }
      }

      const toField = fieldByPath[TaxFormFieldIds.TO];
      if (toField && toField.inputType === FORM_INPUT_TYPE.Date) {
        toField.min = yearStart;
        toField.max = yearEnd;
        if (typeof data[TaxFormFieldIds.FROM] === 'string') {
          toField.min = data[TaxFormFieldIds.FROM];
        }
      }

      return fields;
    },
    [],
  );

  const onDelete = () => {
    const newValue = array.filter((_, index) => index !== arrayObjectIndex);
    onChangeValue(newValue);
  };

  const {fields} = useFormData({
    data: arrayObject,
    setData: updateObject,
    config: childProps,
    overWriteFields,
  });

  return (
    <Box
      borderColor={themmeColor.silver}
      col
      style={{
        padding: 16,
        marginBottom: 12,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text
          type={TEXT_TYPES.BASE}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={`Period ${arrayObjectIndex + 1}`}
        />
        <DSButton
          text="Delete"
          startIcon={<DeleteIcon />}
          type="secondary"
          onClick={onDelete}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
        }}>
        {fields.map((field) => (
          <Form {...field} />
        ))}
      </div>
    </Box>
  );
};

const Periods = (props: FormInputProps) => {
  if (props.inputType !== FORM_INPUT_TYPE.Array) {
    return null;
  }
  const onAddPeriod = () => {
    const oldValue = Array.isArray(props.value) ? props.value : [];
    const newValue = [
      ...oldValue,
      createEmptyFormDataFromFormConfig(props.childProps, 0),
    ];
    props.onChangeValue(newValue);
  };
  return (
    <div>
      <div>
        {props.value?.map((_, index) => {
          return (
            <SinglePeriod
              array={props.value}
              arrayObjectIndex={index}
              onChangeValue={props.onChangeValue}
              childProps={props.childProps}
            />
          );
        })}
      </div>
      <DSButton
        type="secondary"
        text="Add Period"
        onClick={onAddPeriod}
        startIcon={<Add />}
      />
    </div>
  );
};

export default Periods;
