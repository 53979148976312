export const RETURN_USER_INFO_HEADER = 88;
export const TAX_PROFILE_STEPS_HEIGHT = 24;

export const HEADER_AND_STEPS_HEIGHT =
  RETURN_USER_INFO_HEADER + TAX_PROFILE_STEPS_HEIGHT;

export const BOTTOM_PRIMARY_ACTION_HEIGHT = 84;

export const DOCUMENTS_ACTION_BAR_HEIGHT = 44;

export const MAIN_CONTENT_HEIGHT = `calc(100vh - ${
  HEADER_AND_STEPS_HEIGHT + BOTTOM_PRIMARY_ACTION_HEIGHT
}px)`;
