import {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  IncomeEarnerType,
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import {useTaxProfile} from './useTaxProfile';
import {
  FILING_STATUS_ANSWER,
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {cloneDeep, get, isString, isUndefined} from 'lodash';
import {getDateFromYYYYMMDD, getPath} from 'src/common/utils';
import {differenceInDays, parse} from 'date-fns';
import {stateAbbreviationNameMap} from '../TaxProfileSections/TaxProfileSections.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCurrentUserId from './useCurrentUserId';
import {bulkModifyTaxForms} from 'src/appApi';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {bulkSaveTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.actions';
import {cloneTaxFormForBackend} from 'src/store/taxForms/taxForms.utils';

const calculateDaysCountFromPeriods = (
  periods: TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS],
) => {
  let count = 0;
  if (Array.isArray(periods)) {
    periods.forEach((period) => {
      if (
        isString(period[TaxFormFieldIds.FROM]) &&
        isString(period[TaxFormFieldIds.TO])
      ) {
        const start = getDateFromYYYYMMDD(period[TaxFormFieldIds.FROM]);
        const end = getDateFromYYYYMMDD(period[TaxFormFieldIds.TO]);
        const diff = differenceInDays(end, start);
        if (diff === 0) count += 1;
        else count += diff;
      }
    });
  }
  return count;
};

const getStateLivedTableData = ({
  taxForms,
  defaultState,
  defaultPeriod,
  isSpouse,
}: {
  taxForms: TaxForm<TaxFormType.STATES_LIVED>[];
  defaultState: string | null;
  defaultPeriod: TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS];
  isSpouse: boolean;
}) => {
  const tableData: {
    isSpouse: boolean;
    isDefault: boolean;
    stateName: string | null;
    periods: TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS];
    taxFormId: null | number;
    displayName: string;
    daysCount: number;
  }[] = [];
  if (defaultState !== null) {
    tableData.push({
      isSpouse,
      isDefault: true,
      stateName: defaultState,
      periods: defaultPeriod,
      taxFormId: null,
      displayName: `${defaultState} (Current address)`,
      daysCount: calculateDaysCountFromPeriods(defaultPeriod),
    });
  }
  taxForms.forEach((tf) => {
    tableData.push({
      isSpouse,
      isDefault: false,
      stateName: tf.formData[TaxFormFieldIds.STATE],
      periods: tf.formData[TaxFormFieldIds.PERIODS] ?? [],
      taxFormId: tf.taxFormId,
      displayName:
        // @ts-ignore
        stateAbbreviationNameMap[tf.formData[TaxFormFieldIds.STATE]] ??
        'Unknown',
      daysCount: calculateDaysCountFromPeriods(
        tf.formData[TaxFormFieldIds.PERIODS],
      ),
    });
  });

  return tableData;
};

export const useStatesLived = () => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const dispatch = useDispatch();

  const {taxProfileMap} = useTaxProfile();

  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const [isLoading, setIsLoading] = useState(false);

  const homeAddress = get(
    taxProfileMap,
    getPath(TaxProfileQuestion.HOME_ADDRESS),
    {},
  );

  const isLivingOutsideUSA =
    taxProfileMap[TaxProfileQuestion.LIVING_OUTSIDE_USA];

  const isSpousePeriodsSame =
    taxProfileMap[TaxProfileQuestion.SPOUSE_PERIODS_ARE_SAME] === true ||
    isUndefined(taxProfileMap[TaxProfileQuestion.SPOUSE_PERIODS_ARE_SAME]);

  const defaultState = isLivingOutsideUSA
    ? null
    : (get(homeAddress, getPath(TaxProfileField.STATE, '[0]'), null) as
        | null
        | string);

  const selfDefaultPeriods = get(
    homeAddress,
    TaxProfileField.PERIODS,
    [],
  ) as TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS];

  const spouseDefaultPeriods = get(
    homeAddress,
    TaxProfileField.SPOUSE_PERIODS,
    [],
  ) as TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS];
  const filingStatus = taxProfileMap[TaxProfileQuestion.FILING_STATUS];

  const isSpouseApplicable =
    filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY;

  const statesTaxForm = useMemo(() => {
    return taxForms.filter(
      (tf) => tf.formType === TaxFormType.STATES_LIVED,
    ) as TaxForm<TaxFormType.STATES_LIVED>[];
  }, [taxForms]);

  const selfStateTaxForms = useMemo(() => {
    return statesTaxForm.filter(
      (tf) =>
        tf.formData[TaxFormFieldIds.INCOME_EARNER] !==
        IncomeEarnerType.MY_SPOUSE,
    );
  }, [statesTaxForm]);

  const spouseStateTaxForms = useMemo(() => {
    return statesTaxForm.filter(
      (tf) =>
        tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
        IncomeEarnerType.MY_SPOUSE,
    );
  }, [statesTaxForm]);

  const selfStateLivedTableData = useMemo(() => {
    return getStateLivedTableData({
      taxForms: selfStateTaxForms,
      defaultState,
      defaultPeriod: selfDefaultPeriods,
      isSpouse: false,
    });
  }, [selfStateTaxForms, selfDefaultPeriods, defaultState]);

  const spouseStateLivedTableData = useMemo(() => {
    return getStateLivedTableData({
      taxForms: spouseStateTaxForms,
      defaultState,
      defaultPeriod: spouseDefaultPeriods,
      isSpouse: true,
    });
  }, [spouseStateTaxForms, spouseDefaultPeriods, defaultState]);

  const onChangeSpousePeriodsAreSame = async (newValue: boolean) => {
    try {
      setIsLoading(true);
      const taxProfileQuestion = [
        {
          question_id: TaxProfileQuestion.SPOUSE_PERIODS_ARE_SAME,
          answer: newValue as any,
        },
      ];
      if (newValue) {
        // spouse periods are same, delete spouse tax forms
        await bulkModifyTaxForms({
          fly_user_id: userId,
          upsert_forms: [],
          delete_form_ids: spouseStateTaxForms.map((tf) => tf.taxFormId),
        });
      } else {
        // spouse periods are not same
        // duplicate tax payer state tax forms
        await bulkModifyTaxForms({
          fly_user_id: userId,
          upsert_forms: selfStateTaxForms.map((tf) => {
            const clone = cloneTaxFormForBackend(tf, {
              [TaxFormFieldIds.INCOME_EARNER]: IncomeEarnerType.MY_SPOUSE,
            });
            // @ts-ignore
            delete clone['id'];
            return clone;
          }),
          delete_form_ids: [],
        });
        // replicate default periods for spouse
        taxProfileQuestion.push({
          question_id: TaxProfileQuestion.HOME_ADDRESS,
          answer: {
            ...homeAddress,
            [TaxProfileField.SPOUSE_PERIODS]: selfDefaultPeriods,
          },
        });
      }
      await dispatch(
        bulkSaveTaxProfileQuestions(userId, taxProfileQuestion, activeYear),
      );
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    defaultState,
    selfDefaultPeriods,
    spouseDefaultPeriods,
    selfStateTaxForms,
    spouseStateTaxForms,
    selfStateLivedTableData,
    spouseStateLivedTableData,
    isSpouseApplicable,
    isSpousePeriodsSame,
    isLoading,
    onChangeSpousePeriodsAreSame,
  };
};
