import {useSelector} from 'react-redux';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {USAStatesAbbreviations} from 'src/constants/constants';
import {TaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {selectStateInfoDrawer} from 'src/store/app/app.selector';

const useStateInfo = ({
  isVisible,
  isDefault,
  isSpouse,
  taxFormId,
}: ReturnType<typeof selectStateInfoDrawer>) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const stateLivedTaxForms = taxForms.filter(
    (tf) => tf.formType === TaxFormType.STATES_LIVED,
  );
  const {activeYear} = useActiveYear();
  const homeAddress = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.HOME_ADDRESS, activeYear),
  );
  const {notify} = useNotify();

  const getFieldsFromHomeAddress = () => {
    return {
      [TaxProfileField.STREET_NUMBER]:
        homeAddress?.[TaxProfileField.STREET_NUMBER],
      [TaxProfileField.APARTMENT_NUMBER]:
        homeAddress?.[TaxProfileField.APARTMENT_NUMBER],
      [TaxProfileField.STATE]: homeAddress?.[TaxProfileField.STATE],
      [TaxProfileField.CITY]: homeAddress?.[TaxProfileField.CITY],
      [TaxProfileField.ZIP_CODE]: homeAddress?.[TaxProfileField.ZIP_CODE],
      [TaxProfileField.SCHOOL_DISTRICT_CODE]:
        homeAddress?.[TaxProfileField.SCHOOL_DISTRICT_CODE],
      [TaxProfileField.LIVED_FOR]: homeAddress?.[TaxProfileField.LIVED_FOR],
      current_address: true,
    };
  };

  const getFullStateName = (smallName: string) => {
    const fullStateInfo = USAStatesAbbreviations.find(
      (item) => item.id === smallName,
    );
    if (fullStateInfo) {
      return fullStateInfo.name;
    }
    notify(`Abbrevation not found for ${smallName}`, NotificationType.warning);
    return smallName;
  };

  const getFieldsForStateLivedForm = ({formData}: GenericTaxForm) => {
    return {
      [TaxProfileField.STREET_NUMBER]:
        formData[TaxFormFieldIds.NUMBER_AND_STREET],
      [TaxProfileField.APARTMENT_NUMBER]:
        formData[TaxFormFieldIds.APARTMENT_NUMBER],
      [TaxProfileField.CITY]: formData[TaxFormFieldIds.CITY],
      [TaxProfileField.STATE]: getFullStateName(
        formData[TaxFormFieldIds.STATE],
      ),
      [TaxProfileField.ZIP_CODE]: formData[TaxFormFieldIds.ZIP_CODE],
      [TaxProfileField.LIVED_FOR]: formData[TaxFormFieldIds.LIVED_FOR],
      [TaxProfileField.SCHOOL_DISTRICT_CODE]:
        formData[TaxFormFieldIds.SCHOOL_DISTRICT_CODE],
      current_address: false,
    };
  };

  // @ts-ignore
  let statesInfo: ReturnType<typeof getFieldsFromHomeAddress> = {};
  if (isVisible && isDefault) {
    statesInfo = getFieldsFromHomeAddress();
  } else if (isVisible && !isDefault) {
    const form = stateLivedTaxForms.find((t) => t.taxFormId === taxFormId);
    if (form !== undefined) {
      statesInfo = getFieldsForStateLivedForm(form);
    }
  }

  let periods: TaxForm<TaxFormType.STATES_LIVED>['formData'][TaxFormFieldIds.PERIODS] =
    [];

  if (isVisible && isDefault) {
    const field = isSpouse
      ? TaxProfileField.SPOUSE_PERIODS
      : TaxProfileField.PERIODS;
    periods = homeAddress[field] ?? [];
  } else if (isVisible && !isDefault) {
    const form = stateLivedTaxForms.find((t) => t.taxFormId === taxFormId);
    if (form !== undefined) {
      periods = form.formData[TaxFormFieldIds.PERIODS] ?? [];
    }
  }

  const fields = [
    {name: 'House number and street', path: TaxProfileField.STREET_NUMBER},
    {
      name: 'Apartment/Unit number (if applicable)',
      path: TaxProfileField.APARTMENT_NUMBER,
    },
    {name: 'City', path: TaxProfileField.CITY},
    {name: 'State', path: TaxProfileField.STATE},
    {name: 'Zip code', path: TaxProfileField.ZIP_CODE},
    {name: 'School district code', path: TaxProfileField.SCHOOL_DISTRICT_CODE},
    {name: 'Lived for', path: TaxProfileField.LIVED_FOR},
  ];
  return {
    statesInfo,
    fields,
    periods,
  };
};

export default useStateInfo;
