import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import DownIcon from 'src/icons/DownIcon';
import Option from '../components/Option/Option';
import Text from '../Text/Text';
import _, {isString} from 'lodash';
import TextField from '../TextField/TextField';
import {Search} from '@mui/icons-material';

interface CheckFieldOptionType {
  label: any;
  value: any;
}

interface CheckFieldProps extends InputWrapperBaseType {
  options: CheckFieldOptionType[];
  value: (number | string)[];
  onChangeValue: (newValue: any) => void;
  allowEmpty?: boolean;
}

const CheckField = ({
  title,
  validators,
  options = [],
  value,
  onChangeValue,
  allowEmpty = false,
  ...rest
}: CheckFieldProps) => {
  const [_selectedValues, setSelectedValues] = useState(
    () => new Set([...(value ?? [])]),
  );
  const [optionsPopup, setOptionsPopup] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const readLabel = useMemo(() => {
    const labels: string[] = [];
    const optionsWithKey = _.keyBy(options, 'value');
    value?.forEach((value) => {
      if (optionsWithKey?.[value]) {
        labels.push(optionsWithKey[value].label);
      }
    });
    return labels.join(', ');
  }, [value, options]);

  const onClickOptions: React.MouseEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setOptionsPopup(true);
      e.currentTarget.blur();
    }, []);

  const onClosePopup = useCallback(() => {
    setOptionsPopup(false);
  }, []);

  useEffect(() => {
    if (optionsPopup) {
      setSelectedValues(() => new Set(value ?? []));
    }
  }, [optionsPopup, value]);

  const onSelect = useCallback((newValue: any) => {
    setSelectedValues((prev) => {
      const updatedSet = _.cloneDeep(prev);
      updatedSet.add(newValue);
      return updatedSet;
    });
  }, []);

  const onDeselect = useCallback((toRemove: any) => {
    setSelectedValues((prev) => {
      const updatedSet = _.cloneDeep(prev);
      updatedSet.delete(toRemove);
      return updatedSet;
    });
  }, []);

  const filteredOptions = useMemo(() => {
    if (isString(searchInput) && searchInput.trim().length > 0) {
      const _filtered = options.filter((o) => {
        if (
          isString(o.label) &&
          !o.label.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return false;
        }
        return true;
      });
      return _filtered;
    }
    return options;
  }, [options, searchInput]);

  return (
    <>
      <InputWrapper
        title={title}
        actualValue={value}
        type="text"
        onClick={onClickOptions}
        value={value?.length > 0 ? readLabel : 'Not selected'}
        rightIcon={<DownIcon />}
        validators={validators}
        {...rest}
      />

      <PopUp
        primaryButtonTitle="Change"
        primaryButtonOnClick={() => {
          onChangeValue([..._selectedValues]);
          onClosePopup();
        }}
        primaryButtonDisabled={_selectedValues.size === 0 && !allowEmpty}
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={() => {
          onClosePopup();
        }}
        style={{width: 400}}
        isOpen={optionsPopup}
        onClose={onClosePopup}>
        <Text
          text={title ?? 'Select all applicable'}
          type={Text.TEXT_TYPES.L}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          containerStyle={{marginBottom: 12}}
        />
        <TextField
          value={searchInput}
          onChangeValue={setSearchInput}
          placeholder="Search"
          rightIcon={<Search />}
          style={{marginBottom: 12}}
        />
        <div style={{maxHeight: 400, overflowY: 'auto'}}>
          {filteredOptions.map(({label, value}, index) => {
            return (
              <Option
                key={`${value}-${index}`}
                isSingle={false}
                checked={_selectedValues.has(value)}
                label={label}
                value={value}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            );
          })}
        </div>
      </PopUp>
    </>
  );
};

export default CheckField;
