import React, {ReactNode, useEffect, useState} from 'react';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {themmeColor} from 'src/constants/constants';

interface CreateReturnDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: any;
}

const CreateReturnDrawer = ({
  isOpen,
  onClose,
  children,
  title,
}: CreateReturnDrawerProps) => {
  const [discardChanges, setDiscardChanges] = useState(false);

  const _onClose = () => {
    setDiscardChanges(true);
  };

  const onDoNotDiscard = () => {
    setDiscardChanges(false);
  };

  const onDiscardChanges = () => {
    setDiscardChanges(false);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      window.onbeforeunload = (e) => {
        return true;
      };
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      direction="up"
      width={'100vw'}
      height={'100vh'}
      style={{display: 'flex', flexDirection: 'column'}}
      title={title}
      onClose={_onClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          backgroundColor: themmeColor.cpaCenterV2Bg,
        }}>
        {children}
      </div>
      <PopUp
        isOpen={discardChanges}
        onClose={onDoNotDiscard}
        primaryButtonTitle="Yes, discard"
        primaryButtonOnClick={onDiscardChanges}
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={onDoNotDiscard}>
        <Text
          text={'Are you sure you want to discard?'}
          fontWeight={FONT_WEIGHTS.SemiBold}
          type={TEXT_TYPES.BASE}
        />
      </PopUp>
    </Drawer>
  );
};
export default CreateReturnDrawer;
