import {DOCUMENT_TYPES, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import {SET_DOCUMENTS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export interface ReduxDocumentType {
  docTypeId: number;
  docType: DOCUMENT_TYPES;
  docTypeStatus: string;
  docId: number;
  filename: string;
  docStatus: VALID_DOCUMENT_STATUS;
  docLink: string;
  extras?: any;
  year: number;
  ocrRequired: boolean;
  errorMsg: null | string;
  linkedBusinessId: number | null;
}

const initialState = {
  status: REDUX_STATUS.INIT,
  loaded: false,
  documents: [] as ReduxDocumentType[],
};

export const documents = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type DocumentStateType = typeof initialState;
