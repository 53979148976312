import React, {useMemo, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {OCRBox} from './OCR.types';
import DSButton from '../Button/Button';
import {ZoomIn, ZoomOut} from '@mui/icons-material';
import Text from '../Form/common/Text/Text';
import {TEXT_TYPES} from '../Form/common/Text/Text.types';

interface PageDimensions {
  width: number;
  height: number;
}

interface OCROverlayViewerProps {
  pdfUrl: string;
  ocrData: OCRBox[];
  onlyRenderPagesWithOcrFields?: boolean;
  height?: number;
  scale?: number;
  boxesBorderWidth?: number;
}

const delta = 0.02;

const zoomDelta = 0.1;

const PDFWithOcrBoxes: React.FC<OCROverlayViewerProps> = ({
  pdfUrl,
  ocrData,
  onlyRenderPagesWithOcrFields = false,
  height = 400,
  scale = 1.3,
  boxesBorderWidth = 2,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const [pdfScale, setPdfScale] = useState(scale);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  const pageNumbersWithOcrFields = useMemo(() => {
    const pagesWithOcrFields = new Set();
    ocrData.forEach((field) => {
      pagesWithOcrFields.add(field.pageNumber);
    });
    return pagesWithOcrFields;
  }, [ocrData]);

  // Triggered on successful PDF document load
  const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setNumPages(numPages);
  };

  // Capture page dimensions on render success
  const handlePageRender = (pageNumber: number, page: pdfjs.PDFPageProxy) => {
    if (pageNumbersWithOcrFields.has(pageNumber) || pageNumber === 1) {
      const viewport = page.getViewport({scale: pdfScale});
      setPageDimensions({width: viewport.width, height: viewport.height});
    }
  };

  // Calculate absolute positions for OCR boxes
  const calculateBoxStyle = ({
    Top,
    Left,
    Width,
    Height,
  }: OCRBox): React.CSSProperties => {
    const actualTop = Top * pageDimensions.height;
    const newTop = actualTop * (1 - delta);
    const heightDifference = actualTop - newTop;
    const actualHeight = Height * pageDimensions.height;
    const newHeight = actualHeight + 2 * heightDifference;

    const actualLeft = Left * pageDimensions.width;
    const newLeft = actualLeft * (1 - delta);
    const leftDifference = actualLeft - newLeft;
    const actualWidth = Width * pageDimensions.width;
    const newWidth = actualWidth + 2 * leftDifference;

    return {
      position: 'absolute',
      top: newTop,
      height: newHeight,
      left: newLeft,
      width: newWidth,
      border: `${boxesBorderWidth}px solid red`,
      boxSizing: 'border-box',
    };
  };
  const onZoomOut = () => {
    setPdfScale((prev) => Math.max(prev - zoomDelta, 0.1)); // Minimum zoom at 10%
  };

  const onZoomIn = () => {
    setPdfScale((prev) => Math.min(prev + zoomDelta, 3)); // Maximum zoom at 300%
  };

  return (
    <div style={{overflow: 'hidden', width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div onClick={onZoomOut}>
          <ZoomOut style={{height: 24, width: 24}} />
        </div>
        <Text
          containerStyle={{marginInline: 8, width: 50, textAlign: 'center'}}
          type={TEXT_TYPES.S}
          text={`${Math.round(pdfScale * 100)}%`}
        />
        <div onClick={onZoomIn}>
          <ZoomIn style={{height: 24, width: 24}} />
        </div>
      </div>
      <div
        ref={pdfContainerRef}
        style={{position: 'relative', height, overflowY: 'auto'}}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from({length: numPages || 0}, (_, index) => {
            if (
              onlyRenderPagesWithOcrFields &&
              !pageNumbersWithOcrFields.has(index + 1)
            ) {
              return null;
            }
            return (
              <div key={index} style={{position: 'relative'}}>
                <Page
                  key={index}
                  pageNumber={index + 1}
                  onRenderSuccess={(pdfPage) =>
                    handlePageRender(index + 1, pdfPage)
                  }
                  scale={pdfScale}
                />
                {ocrData
                  .filter((data) => data.pageNumber === index + 1)
                  .map((box, boxIndex) => (
                    <div key={boxIndex} style={calculateBoxStyle(box)}></div>
                  ))}
              </div>
            );
          })}
        </Document>
      </div>
    </div>
  );
};

export default React.memo(PDFWithOcrBoxes);
