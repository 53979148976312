import React, {useEffect, useMemo, useState} from 'react';
import Box from 'src/DesignSystem/Box/Box';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {themmeColor} from 'src/constants/constants';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {useDispatch, useSelector} from 'react-redux';
import {
  TaxReturnField,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {Form} from 'src/DesignSystem/Form/Form';
import _ from 'lodash';
import {patchBulkTaxFiling} from 'src/appApi';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

interface FilingOptionsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const FilingOptionsPopup = ({isOpen, onClose}: FilingOptionsPopupProps) => {
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {notify} = useNotify();

  const bizReturnsById = useMemo(() => {
    const bizReturn = taxReturns.filter(
      (taxReturn) =>
        taxReturn[TaxReturnField.return_type] === TaxReturnType.BUSINESS,
    );
    return _.keyBy(bizReturn, TaxReturnField.return_id);
  }, [taxReturns]);

  const [filingOptionsState, setFilingOptions] = useState<
    {
      [TaxReturnField.return_id]: number;
      [TaxReturnField.return_type]: TaxReturnType;
      [TaxReturnField.should_file_return]: boolean;
    }[]
  >([]);

  useEffect(() => {
    const initState = taxReturns.map((t) => ({
      [TaxReturnField.return_id]: t[TaxReturnField.return_id],
      [TaxReturnField.return_type]: t[TaxReturnField.return_type],
      [TaxReturnField.should_file_return]:
        t[TaxReturnField.should_file_return],
    }));
    setFilingOptions(initState);
  }, [taxReturns, isOpen]);

  const onChangeValue = (newValue: boolean, index: number) => {
    setFilingOptions((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {...item, [TaxReturnField.should_file_return]: newValue};
      });
    });
  };

  const onSaveChanges = async () => {
    try {
      setLoading(true);
      const mappedData = filingOptionsState.map((taxReturn) => {
        const {return_id, return_type, ...rest} = taxReturn;
        return {
          return_id,
          return_type,
          data: rest,
        };
      });
      await patchBulkTaxFiling(mappedData);
      await dispatch(fetchTaxReturns(userId, activeYear));
      notify(`Updated successfully`, NotificationType.success);
      onClose();
    } catch (e) {
      notify(`Failed to update ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUp
      isOpen={isOpen}
      primaryButtonTitle="Save changes"
      primaryButtonOnClick={onSaveChanges}
      primaryButtonDisabled={loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 500}}
      onClose={onClose}>
      <Text
        type={TEXT_TYPES.L}
        text={'Filing options'}
        fontWeight={FONT_WEIGHTS.SemiBold}
        containerStyle={{marginBottom: 20}}
      />
      <Text
        type={TEXT_TYPES.BASE}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={'Select all the returns the user is filing with us'}
        containerStyle={{marginBottom: 12}}
      />
      {filingOptionsState.map((taxReturn, index) => {
        return (
          <Box
            borderColor={themmeColor.offWhite}
            style={{
              justifyContent: 'space-between',
              padding: 12,
              marginBottom: 12,
            }}>
            {taxReturn[TaxReturnField.return_type] === TaxReturnType.INDIVIDUAL
              ? 'Personal return'
              : bizReturnsById[taxReturn[TaxReturnField.return_id]][
                  TaxReturnField.business_name
                ]}
            <Form.SwitchField
              value={taxReturn[TaxReturnField.should_file_return]}
              onChangeValue={(newValue) => onChangeValue(newValue, index)}
            />
          </Box>
        );
      })}
    </PopUp>
  );
};

export default FilingOptionsPopup;
