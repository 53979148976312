import {Divider, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {isSchoolDistrictCodeRequired} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {updateTaxFilingExtensionStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {TAX_EXTENSION_STATUS, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import CrossIcon from 'src/icons/CrossIcon';
import TickIcon from 'src/icons/TickIcon';
import {setFileExtensionDetailsDrawer} from 'src/store/app/app.actions';
import {selectFileExtensionDetailsDrawer} from 'src/store/app/app.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';

const FileExtensionDetailsDrawer = () => {
  const {activeYear} = useActiveYear();
  const isVisible = useSelector(selectFileExtensionDetailsDrawer);
  const {tax_extension_status: taxExtensionStatus} = useSelector(selectUser);
  const {loaded} = useSelector(selectTaxProfile);
  const {userId} = useCurrentUserId();

  const legalName = useSelector((state: ReduxStateType) => {
    return selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.LEGAL_NAME,
      activeYear,
    );
  });
  const ssn = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.SSN, activeYear),
  );
  const dob = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.DOB, activeYear),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );
  const homeAddress = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.HOME_ADDRESS, activeYear),
  );
  const spouseLegalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.SPOUSE_LEGAL_NAME,
      activeYear,
    ),
  );
  const spouseSSN = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.SPOUSE_SSN, activeYear),
  );
  const spouseDOB = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.SPOUSE_DOB, activeYear),
  );
  const {taxForms} = useSelector(selectTaxFormsReducer);

  const [showLoader, setShowLoader] = useState(false);

  const dispatch = useDispatch();

  const isLoading = !loaded;

  const dependents = [FILING_STATUS_ANSWER.HEAD_OF_HOUSEHOLD].includes(
    filingStatus,
  )
    ? taxForms
        .filter((taxform) => taxform.formType === TaxFormType.DEPENDENT)
        .map((tf) => tf.formData)
    : [];

  const onCloseDrawer = () => {
    dispatch(setFileExtensionDetailsDrawer(false));
  };

  const getHomeAddressFields = () => {
    const fields: {name: string; path: string}[] = [];
    if (isLoading) {
      return fields;
    }

    fields.push(
      ...[
        {name: 'House number and street', path: TaxProfileField.STREET_NUMBER},
        {
          name: 'Apartment/Unit number (if applicable)',
          path: TaxProfileField.APARTMENT_NUMBER,
        },
        {name: 'City', path: TaxProfileField.CITY},
        {name: 'State', path: TaxProfileField.STATE},
        {name: 'Zip code', path: TaxProfileField.ZIP_CODE},
      ],
    );

    if (
      isSchoolDistrictCodeRequired(homeAddress?.[TaxProfileField.STATE] ?? '')
    ) {
      fields.push({
        name: 'School district code',
        path: TaxProfileField.SCHOOL_DISTRICT_CODE,
      });
    }

    return fields;
  };

  const dependentFields = [
    {
      path: TaxFormFieldIds.DEPENDENT_FIRST_NAME,
      name: 'First name & middle initial',
    },
    {path: TaxFormFieldIds.DEPENDENT_LAST_NAME, name: 'Last name'},
    {path: TaxFormFieldIds.DEPENDENT_RELATION, name: 'Relationship to you'},
    {path: TaxFormFieldIds.DEPENDENT_BIRTHDATE, name: 'Date of birth'},
    {path: TaxFormFieldIds.DEPENDENT_SSN, name: 'Social Security Number'},
    {
      path: TaxFormFieldIds.DEPENDENT_US_CITIZEN,
      name: 'Are they either a US resident or US citizen?',
    },
  ];

  const onPressExtensionComplete = async () => {
    setShowLoader(true);
    try {
      await updateTaxFilingExtensionStatus({
        fly_user_id_list: [userId],
        year: activeYear,
        tax_extension_status: TAX_EXTENSION_STATUS.FILED,
      });
      await dispatch(fetchUserInfo(userId, activeYear));
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={400}
      height={'90vh'}
      isOpen={isVisible}
      direction="left">
      <div
        style={{
          padding: '20px 32px',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography style={{fontSize: 20, fontWeight: 600, marginBlock: 16}}>
            Individual extension
          </Typography>
          <div onClick={onCloseDrawer}>
            <CrossIcon />
          </div>
        </div>
        <Box
          backgroundColor="#F3F3F3"
          style={{
            padding: '8px 12px',
            borderRadius: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {taxExtensionStatus === TAX_EXTENSION_STATUS.FILED && (
            <TickIcon color={themmeColor.successGreen} />
          )}
          <Typography
            style={{
              fontSize: 11,
              fontWeight: 600,
              color: themmeColor.grey,
              marginLeft: 2,
            }}>
            {`Status: ${
              taxExtensionStatus === TAX_EXTENSION_STATUS.FILED
                ? 'extension Filed'
                : 'User submitted details'
            }`.toUpperCase()}
          </Typography>
        </Box>
        <div style={{marginTop: 20}}>
          <Typography style={{fontSize: 16, fontWeight: 600}}>
            Your basic details
          </Typography>
          <SimpleKeyValueField
            name="First name & middle initial"
            style={{marginBottom: 12, marginTop: 20}}
            value={legalName?.[TaxProfileField.FIRST_NAME]}
            loading={isLoading}
          />
          <SimpleKeyValueField
            name="Last name"
            style={{marginBottom: 12}}
            value={legalName?.[TaxProfileField.LAST_NAME]}
            loading={isLoading}
          />
          <SimpleKeyValueField
            style={{marginBottom: 12}}
            name="Social Security Number"
            value={ssn}
            loading={isLoading}
          />
          <SimpleKeyValueField
            style={{marginBottom: 12}}
            name="Date of birth"
            value={dob}
            loading={isLoading}
          />
          <SimpleKeyValueField
            style={{marginBottom: 12}}
            name="Filing status"
            value={filingStatus}
            loading={isLoading}
          />
        </div>
        <Divider style={{marginTop: 24}} />
        <div style={{marginTop: 24}}>
          <Typography
            style={{fontSize: 16, fontWeight: 600, marginBottom: 20}}>
            Home address
          </Typography>
          <MultiKeyValueField
            data={homeAddress}
            fields={getHomeAddressFields()}
            loading={isLoading}
          />
        </div>
        <Divider style={{marginTop: 24}} />
        {[
          FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY,
          FILING_STATUS_ANSWER.MARRIED_FILING_SEPARAELY,
        ].includes(filingStatus) && (
          <>
            <div style={{marginTop: 24}}>
              <Typography style={{fontSize: 16, fontWeight: 600}}>
                Spouse details
              </Typography>
              <SimpleKeyValueField
                name="Spouse first name & middle initial"
                style={{marginBottom: 12, marginTop: 20}}
                value={spouseLegalName?.[TaxProfileField.FIRST_NAME]}
                loading={!loaded}
              />
              <SimpleKeyValueField
                name="Spouse last name"
                style={{marginBottom: 12}}
                value={spouseLegalName?.[TaxProfileField.LAST_NAME]}
                loading={!loaded}
              />
              <SimpleKeyValueField
                name="Social Security Number"
                style={{marginBottom: 12}}
                value={spouseSSN}
                loading={!loaded}
              />
              <SimpleKeyValueField
                name="Date of birth"
                style={{marginBottom: 12}}
                value={spouseDOB}
                loading={!loaded}
              />
            </div>
            <Divider style={{marginTop: 24}} />
          </>
        )}
        {dependents.map((dependent, index) => {
          return (
            <div style={{marginTop: 24}}>
              <Typography style={{fontSize: 16, fontWeight: 600}}>
                {`Dependent ${index + 1}`}
              </Typography>
              <div
                style={{
                  marginTop: 14,
                  borderRadius: 12,
                  backgroundColor: '#F5F6F8',
                  padding: 20,
                }}>
                <MultiKeyValueField
                  data={dependent}
                  fields={dependentFields}
                />
              </div>
            </div>
          );
        })}
        {taxExtensionStatus !== TAX_EXTENSION_STATUS.FILED && (
          <div
            style={{
              marginTop: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <DSButton
              text="Complete File Extension"
              onClick={onPressExtensionComplete}
              disabled={showLoader}
              type="primary"
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default FileExtensionDetailsDrawer;
