import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import {TaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import Skeleton from 'src/icons/Skeleton';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';
import DependentCard from './components/DependentCard';
import {ReduxStateType} from 'src/store/store';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import DSButton from 'src/DesignSystem/Button/Button';
import {Add} from '@mui/icons-material';

interface DependentDetailsProps {
  style?: React.CSSProperties;
  onEditTaxForm?: (taxformId: number) => void;
  onAddTaxForm?: () => void;
}

const DependentDetails = ({
  style,
  onEditTaxForm,
  onAddTaxForm,
}: DependentDetailsProps) => {
  const {loaded} = useSelector(selectTaxProfile);
  const {activeYear} = useActiveYear();

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );
  const canSomeoneElseClaimSpouseAsDependent = useSelector(
    (state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT,
        activeYear,
      ),
  );
  const userClaimedDependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.CLAIM_YOU_DEPENDENT,
      activeYear,
    ),
  );
  const hasDependents = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.DEPENDENT, activeYear),
  );

  const showDependentDetails =
    hasDependents &&
    !userClaimedDependent &&
    (filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY ||
      !canSomeoneElseClaimSpouseAsDependent);

  const dependents = showDependentDetails
    ? (taxForms.filter(
        (taxform) => taxform.formType === TaxFormType.DEPENDENT,
      ) as TaxForm<TaxFormType.DEPENDENT>[])
    : [];

  const fields = [
    {
      path: TaxFormFieldIds.DEPENDENT_FIRST_NAME,
      name: 'First name & middle initial',
    },
    {path: TaxFormFieldIds.DEPENDENT_LAST_NAME, name: 'Last name'},
    {path: TaxFormFieldIds.DEPENDENT_RELATION, name: 'Relationship to you'},
    {path: TaxFormFieldIds.DEPENDENT_BIRTHDATE, name: 'Date of birth'},
    {path: TaxFormFieldIds.DEPENDENT_SSN, name: 'Social Security Number'},
    {
      path: TaxFormFieldIds.DEPENDENT_US_CITIZEN,
      name: 'Are they either a US resident or US citizen?',
    },
  ];

  return (
    <SubSectionBox
      name="Dependent details"
      style={style}
      customSectionActions={
        <div>
          {onAddTaxForm && (
            <DSButton
              type="secondary"
              onClick={(e) => {
                onAddTaxForm();
              }}
              text="Add"
              endIcon={<Add />}
              style={{marginRight: 8}}
            />
          )}
        </div>
      }
      taxprofileSubsection={TaxProfileSubsection.DependentDetails}>
      {!loaded ? (
        <Skeleton width={'100%'} height={40} />
      ) : (
        dependents.map((dependent, index: number) => (
          <DependentCard
            index={index}
            data={dependent.formData}
            fields={fields}
            taxFormId={dependent.taxFormId}
            onEdit={onEditTaxForm}
          />
        ))
      )}
    </SubSectionBox>
  );
};

export default DependentDetails;
