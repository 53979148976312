import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import TaxFormDataPopup from 'src/CpaCenterV2/TaxProfileSections/common/TaxFormDataPopup';
import Box from 'src/DesignSystem/Box/Box';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import Tag from 'src/DesignSystem/Tag/Tag';
import ErrorIcon from 'src/icons/ErrorIcon';
import IgnoredIcon from 'src/icons/IgnoredIcon';
import NotesIcon from 'src/icons/NotesIcon';
import RightChveron from 'src/icons/RightChveron';
import Skeleton from 'src/icons/Skeleton';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';
import TickIcon from 'src/icons/TickIcon';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';

interface DocumentPointerProps {
  doc?: ReduxDocumentType;
  dontShowStatus?: boolean;
  style?: React.CSSProperties;
  paddingBlock?: number;
  border?: boolean;
  taxform?: GenericTaxForm;
  onDocumentClick?: () => void;
  backgroundColor?: string;
}

const DocumentPointer = ({
  doc,
  dontShowStatus = false,
  style = {},
  paddingBlock = 16,
  border = false,
  taxform = undefined,
  onDocumentClick,
  backgroundColor,
}: DocumentPointerProps) => {
  const {navigateToDocumentViewer} = useCpaCenterV2Navigation();

  if (!doc && !taxform) {
    return (
      <Box
        backgroundColor={themmeColor.cpaCenterV2Bg}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 16,
          ...style,
        }}
        borderColor={themmeColor.offWhite}>
        <Typography>No attached document</Typography>
      </Box>
    );
  }

  if (!doc && taxform !== undefined) {
    return (
      <TaxFormDataPopup
        backgroundColor={themmeColor.cpaCenterV2Bg}
        taxform={taxform}
        borderColor={themmeColor.silver}
        style={{marginBottom: 4}}
        onClick={onDocumentClick}
      />
    );
  }

  const {docId, docType, filename, docStatus} = doc;

  const getIcon = () => {
    if (dontShowStatus) {
      return <NotesIcon />;
    }
    switch (docStatus) {
      case VALID_DOCUMENT_STATUS.IGNORED:
        return <IgnoredIcon />;
      case VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW:
        return <StatusPendingIcon />;
      case VALID_DOCUMENT_STATUS.ACTION_REQUIRED:
        return <ErrorIcon />;
      case VALID_DOCUMENT_STATUS.ACCEPTED:
      case VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA:
        return <TickIcon />;
    }
    return <Skeleton height={20} width={20} />;
  };

  const onClickDocument = () => {
    console.log('b');
    if (onDocumentClick) {
      onDocumentClick();
    } else {
      navigateToDocumentViewer(docId);
    }
  };
  return (
    <Box
      onClick={onClickDocument}
      hoverColor={themmeColor.focusedBlue}
      backgroundColor={backgroundColor}
      hoverEffect
      style={{
        padding: 16,
        flexFlow: 'column',
        paddingBlock,
        ...(border
          ? {
              borderColor: themmeColor.silver,
              borderStyle: 'solid',
              borderWidth: 1,
            }
          : {}),
        ...style,
      }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          overflowX: 'auto',
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 10}}>{getIcon()}</div>
          <Tag text={docType} />
          <Typography
            style={{
              fontSize: 16,
              marginLeft: 10,
            }}>
            {filename}
          </Typography>
        </div>
        <div style={{alignSelf: 'center', display: 'flex'}}>
          <RightChveron />
        </div>
      </div>

      {doc !== undefined &&
        doc !== null &&
        doc.errorMsg !== null &&
        doc?.errorMsg?.length > 0 && (
          <Text
            containerStyle={{marginTop: 12}}
            type={TEXT_TYPES.XS}
            text={doc.errorMsg}
            fontWeight={FONT_WEIGHTS.SemiBold}
          />
        )}
    </Box>
  );
};

export default DocumentPointer;
