import React, {ComponentProps} from 'react';
import PDF from './PDF';
import Image from './Image';
import {isPDF} from './File.utils';

// @ts-ignore
interface FileViewerProps {
  filename: string;
  uri: string;
  imgContainerStyle?: React.CSSProperties;
  imgContainerHeight?: number | string;
  pdfHeight?: number | string;
  pdfProps?: Omit<ComponentProps<typeof PDF>, 'filename' | 'uri'>;
  imgProps?: Omit<ComponentProps<typeof Image>, 'filename' | 'uri'>;
}

const FileViewer = ({
  filename,
  uri,
  imgContainerStyle = {overflowY: 'auto'},
  imgContainerHeight = '100%',
  pdfHeight = '100%',
  pdfProps = {width: '100%'},
  imgProps = {width: '100%'},
}: FileViewerProps) => {
  return isPDF(filename) ? (
    <PDF filename={filename} uri={uri} {...pdfProps} height={pdfHeight} />
  ) : (
    <div style={{...imgContainerStyle, height: imgContainerHeight}}>
      <Image filename={filename} uri={uri} {...imgProps} />
    </div>
  );
};
export default FileViewer;
