import React from 'react';
import { themmeColor } from 'src/constants/constants';

const ReplyIcon = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4612 3.6381H16.7737V2.1081C16.7737 1.61685 16.4977 1.1766 16.0544 0.9591C15.6097 0.74085 15.0914 0.7941 14.6977 1.0986L11.4772 3.6381H6.31119C4.66044 3.6381 3.31869 4.9371 3.22419 6.5646C1.65969 6.72285 0.429688 8.03235 0.429688 9.63885V17.2619C0.429688 18.9741 1.82244 20.3676 3.53469 20.3676H5.22294V21.8923C5.22294 22.3874 5.49969 22.8291 5.94519 23.0459C6.12519 23.1329 6.31719 23.1763 6.50769 23.1763C6.78894 23.1763 7.06644 23.0826 7.29894 22.9004L10.5179 20.3676H15.6854C17.3369 20.3676 18.6794 19.0671 18.7724 17.4374C20.3362 17.2784 21.5662 15.9689 21.5662 14.3631V6.7431C21.5662 5.03085 20.1727 3.6381 18.4612 3.6381ZM17.4397 17.2619C17.4397 18.2294 16.6529 19.0168 15.6854 19.0168H10.2839C10.1324 19.0168 9.98544 19.0679 9.86619 19.1616L6.57294 21.7529V19.6919C6.57294 19.3191 6.27069 19.0168 5.89794 19.0168H3.53469C2.56644 19.0168 1.77969 18.2294 1.77969 17.2619V9.63885C1.77969 8.67135 2.56719 7.88385 3.53469 7.88385H15.6854C16.6529 7.88385 17.4397 8.67135 17.4397 9.63885V17.2619ZM20.2154 14.3624C20.2154 15.2174 19.6004 15.9291 18.7904 16.0844V9.63885C18.7904 7.9266 17.3977 6.5331 15.6854 6.5331H4.57719C4.68219 5.66535 5.41494 4.9881 6.31119 4.9881H11.7112C11.8634 4.9881 12.0104 4.9371 12.1289 4.84335L15.4229 2.24535V4.3131C15.4229 4.68585 15.7252 4.9881 16.0979 4.9881H18.4604C19.4279 4.9881 20.2147 5.7756 20.2147 6.7431V14.3624H20.2154Z" fill="#555555" />
            <path d="M11.4389 11.8664H7.79694L8.06169 11.6017C8.32569 11.3377 8.32569 10.9102 8.06169 10.6469C7.79769 10.3837 7.37094 10.3829 7.10769 10.6469L5.69019 12.0644C5.42619 12.3284 5.42619 12.7559 5.69019 13.0184L7.10769 14.4374C7.23969 14.5694 7.41219 14.6354 7.58544 14.6354C7.75869 14.6354 7.93044 14.5694 8.06244 14.4374C8.32644 14.1734 8.32644 13.7467 8.06244 13.4827L7.79694 13.2172H11.4389C11.9572 13.2172 12.3794 13.6394 12.3794 14.1577C12.3794 14.6759 11.9572 15.0982 11.4389 15.0982H10.1234C9.75069 15.0982 9.44844 15.4004 9.44844 15.7732C9.44844 16.1459 9.75069 16.4482 10.1234 16.4482H11.4389C12.7027 16.4482 13.7302 15.4207 13.7302 14.1569C13.7302 12.8932 12.7019 11.8664 11.4389 11.8664Z" fill="#555555" />
        </svg>
    );
};

export default ReplyIcon;
