import React from 'react';
import {GridRenderEditCellParams} from '@mui/x-data-grid';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import DSButton from 'src/DesignSystem/Button/Button';
import {PartnershipReturnFields} from 'src/appApi.types';
import {Edit} from '@mui/icons-material';
import {TaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
const EditExistingPayment = ({
  row,
}: GridRenderEditCellParams<
  any,
  TaxForm<TaxFormType.QUARTERLY_TAX_PAYMENTS>,
  any
>) => {
  const {onEditTaxForm} = usePartnerTaxProfileReview();

  return (
    <div
      onClick={() => {
        onEditTaxForm({
          taxFormId: row.taxFormId,
          taxFormType: row.formType,
          title: 'Edit quarterly payment',
        });
      }}>
      <Edit />
    </div>
  );
};

export default EditExistingPayment;
