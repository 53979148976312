import _ from 'lodash';
import {FormConfigProps, FormInputProps} from '../../Form.types';
import Text from '../Text/Text';
import {useFormData} from 'src/DesignSystem/Form/useFormData';
import {themmeColor} from 'src/constants/constants';
import {useArrayField} from './useArrayField';

type ChildProps = FormConfigProps[];

export interface ArrayProps {
  title: string;
  childTitle?: string;
  childProps: ChildProps;
  value: Array<any>;
  onChangeValue: (newValue: Array<any>) => void;
  disableValidation?: boolean;
}

export interface ArrayChildProps {
  childProps: FormConfigProps[];
  array: any[];
  onChangeValue: (newValue: Array<any>) => void;
  arrayObjectIndex: number;
  disableValidation?: boolean;
}

const TempForm = ({
  childProps,
  array,
  onChangeValue,
  arrayObjectIndex,
  disableValidation,
}: ArrayChildProps) => {
  const {Form: DynamicForm} = require('src/DesignSystem/Form/Form');
  const {updateObject, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );

  const {fields} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
  });

  return fields.map((field) => {
    return (
      <div style={{marginBottom: 12}}>
        <DynamicForm {...field} disableValidation={disableValidation} />
      </div>
    );
  });
};

const ArrayField = ({
  title,
  childTitle,
  childProps,
  value,
  onChangeValue,
  disableValidation,
}: ArrayProps) => {
  return (
    <div>
      <Text
        type={Text.TEXT_TYPES.L}
        fontWeight={Text.FONT_WEIGHTS.ExtraBold}
        text={title}
        containerStyle={{marginBottom: 12}}
      />
      {value?.map((_, arrayObjectIndex) => {
        return (
          <div
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: themmeColor.silver,
              marginBottom: 8,
              borderRadius: 12,
              padding: 16,
            }}>
            <Text
              type={Text.TEXT_TYPES.BASE}
              fontWeight={Text.FONT_WEIGHTS.SemiBold}
              text={`${childTitle} ${arrayObjectIndex + 1}`}
              containerStyle={{marginBottom: 12}}
            />
            <TempForm
              childProps={childProps}
              array={value}
              arrayObjectIndex={arrayObjectIndex}
              onChangeValue={onChangeValue}
              disableValidation={disableValidation}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ArrayField;
