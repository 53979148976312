import {
  FormConfigProps,
  FORM_INPUT_TYPE,
} from 'src/DesignSystem/Form/Form.types';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {TRANSFORMERS} from 'src/DesignSystem/Form/Forms.transformers';
import {getPath} from 'src/common/utils';
import {BOOLEAN_OPTIONS} from 'src/constants/constants';
import {
  TaxProfileQuestion,
  TaxProfileField,
  FILING_STATUS_ANSWER,
} from './taxProfile.types';

export const TaxProfileConfig: FormConfigProps[] = [
  // User details
  {
    title: 'First name & middle initial ',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.LEGAL_NAME, TaxProfileField.FIRST_NAME),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Last name',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.LEGAL_NAME, TaxProfileField.LAST_NAME),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Social Security Number',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.SSN),
    transformers: TRANSFORMERS.SSN,
    validators: [VALIDATOR.VALIDATE_SSN],
  },
  {
    title: 'Date of birth',
    inputType: FORM_INPUT_TYPE.Date,
    path: getPath(TaxProfileQuestion.DOB),
    validators: [VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Filing status',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: getPath(TaxProfileQuestion.FILING_STATUS),
    options: Object.values(FILING_STATUS_ANSWER).map((o) => ({
      label: o,
      value: o,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Can someone else claim you as dependent?',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.CLAIM_YOU_DEPENDENT),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  {
    title: 'Can you claim dependents?',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.DEPENDENT),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  {
    title: 'Eligible for education credits?',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  // Home address
  {
    title: 'I currently living outside USA',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.LIVING_OUTSIDE_USA),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  {
    title: 'House number and street',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      TaxProfileQuestion.HOME_ADDRESS,
      TaxProfileField.STREET_NUMBER,
    ),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Apartment/Unit number (optional)',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      TaxProfileQuestion.HOME_ADDRESS,
      TaxProfileField.APARTMENT_NUMBER,
    ),
  },
  {
    title: 'City',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.CITY),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'State',
    inputType: FORM_INPUT_TYPE.State,
    path: getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE),
    isSingle: false,
    useAbbrevation: false,
    validators: [VALIDATOR.SINGLE_ELEMENT_ARRAY],
  },
  {
    title: 'Zip code',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.ZIP_CODE),
    validators: [VALIDATOR.VALIDATE_ZIP_CODE],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    title: 'School district code',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      TaxProfileQuestion.HOME_ADDRESS,
      TaxProfileField.SCHOOL_DISTRICT_CODE,
    ),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'County',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.COUNTY),
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Mailing Address outside USA',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.MAILING_ADDRESS_OUTSIDE_USA),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  // Spouse Details
  {
    title: 'Spouse first name & middle initial ',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      TaxProfileQuestion.SPOUSE_LEGAL_NAME,
      TaxProfileField.FIRST_NAME,
    ),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Spouse last name',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      TaxProfileQuestion.SPOUSE_LEGAL_NAME,
      TaxProfileField.LAST_NAME,
    ),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Spouse Social Security Number',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.SPOUSE_SSN),
    transformers: TRANSFORMERS.SSN,
    validators: [VALIDATOR.VALIDATE_SSN],
  },
  {
    title: 'Spouse Date of birth',
    inputType: FORM_INPUT_TYPE.Date,
    path: getPath(TaxProfileQuestion.SPOUSE_DOB),
    validators: [VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Spouse Email id',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(TaxProfileQuestion.SPOUSE_EMAIL),
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Can someone else claim your spouse as a dependant?',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  {
    title: 'Spouse eligible for education credits?',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    path: getPath(TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT),
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
  },
  // periods
  {
    title: 'Self Residency dates',
    path: getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.PERIODS),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Period',
    childProps: [
      {
        title: 'From',
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
        path: TaxProfileField.FROM,
      },
      {
        title: 'To',
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
        path: TaxProfileField.TO,
      },
    ],
  },
  {
    title: 'Spouse Residency dates',
    path: getPath(
      TaxProfileQuestion.HOME_ADDRESS,
      TaxProfileField.SPOUSE_PERIODS,
    ),
    childTitle: 'Period',
    inputType: FORM_INPUT_TYPE.Array,
    childProps: [
      {
        title: 'From',
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
        path: TaxProfileField.FROM,
      },
      {
        title: 'To',
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
        path: TaxProfileField.TO,
      },
    ],
  },
];
