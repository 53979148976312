import React, {ComponentProps, useMemo} from 'react';
import {InputWrapperBaseType} from '../../common/components/InputWrapper/InputWrapper';
import CheckField from '../../common/CheckField/CheckField';
import RadioField from '../../common/RadioField/RadioField';
import {STATES} from 'src/constants/constants';

interface SingleStateProps {
  isSingle: true;
  value: string;
}

interface MultiStateProps {
  isSingle: false;
  value: string[];
}

type ConditionalProps = SingleStateProps | MultiStateProps;

type StateProps = ConditionalProps &
  InputWrapperBaseType & {
    useAbbrevation: boolean;
    excludeStates?: string[];
    onChangeValue: (newValue: any) => void;
  };

const StateField = ({
  title,
  onChangeValue,
  useAbbrevation,
  excludeStates = [],
  ...rest
}: StateProps) => {
  const options: ComponentProps<typeof CheckField>['options'] = useMemo(() => {
    return STATES.map((state) => {
      return {
        label: `${state.name} (${state.abbreviation})`,
        value: useAbbrevation ? state.abbreviation : state.name,
      };
    }).filter((state) => !excludeStates.includes(state.value));
  }, [useAbbrevation, excludeStates]);

  return (
    <div>
      {!rest.isSingle ? (
        <CheckField
          title={title}
          onChangeValue={onChangeValue}
          options={options}
          {...rest}
        />
      ) : (
        <RadioField
          title={title}
          onChangeValue={onChangeValue}
          options={options}
          {...rest}
        />
      )}
    </div>
  );
};

export default StateField;
