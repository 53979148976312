import {ChevronLeft} from '@mui/icons-material';
import {Typography} from '@mui/material';
import React, {useMemo, useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';

interface CPAContentTileParams {
  title: string;
  body: string;
  masterShowContent: boolean;
  setMessage: (newString: string) => void;
  setCpaQuestionTemplatePopup: (cpaQuestionTemplatePopup: boolean) => void;
}

const CPAContentTile = ({
  title,
  body,
  masterShowContent,
  setMessage,
  setCpaQuestionTemplatePopup,
}: CPAContentTileParams) => {
  // TODO: We need to also consider the parent value of showContent as well
  const [showContent, setShowContent] = useState(true);

  useMemo(() => {
    setShowContent(masterShowContent);
  }, [masterShowContent]);

  return (
    <div>
      <div style={{marginTop: 8}} />
      <div
        style={{
          borderStyle: 'ridge',
          borderWidth: 1,
          width: 664,
          height: 64,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          display: 'flex',
          alignItems: 'center',
        }}>
        <Typography
          style={{marginLeft: 24, fontSize: 16, height: 24, width: 381}}>
          {title}
        </Typography>
        <div style={{marginLeft: 41}} />
        <div
          onClick={() => {
            setShowContent(!showContent);
          }}
          style={{
            color: '#1F7FD8',
            fontSize: 13,
            height: 20,
            display: 'flex',
            alignItems: 'center',
          }}>
          {showContent ? 'Hide content' : 'Show content'}{' '}
          <ChevronLeft
            style={{
              marginLeft: 4,
              height: 18,
              width: 18,
              rotate: showContent ? '-90deg' : '90deg',
            }}
          />
        </div>
        <div style={{marginLeft: 29}} />
        <DSButton
          text="Use"
          onClick={() => {
            setMessage(body);
            setCpaQuestionTemplatePopup(false);
          }}
          type="secondary"
        />
      </div>
      {/* TODO: Handle styling  */}
      {showContent && (
        <div
          style={{
            borderStyle: 'ridge',
            borderWidth: 1,
            width: 664,
            height: 166,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopWidth: 0,
          }}>
          {body}
        </div>
      )}
    </div>
  );
};

export default CPAContentTile;
