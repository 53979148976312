import {TRANSFORMERS} from 'src/DesignSystem/Form/Forms.transformers';
import {
  PartnershipFilterType,
  PartnershipFilters,
  PartnershipMenuOptionTypes,
  PartnshipFilterLocation,
} from './PartnershipUserList.types';
import {
  DATE_FORMATS,
  TAX_EXTENSION_STATUS,
  TAX_FILING_STATUS,
  UserActivenessStatus,
  VALID_CPA_EMAILS,
} from 'src/constants/constants';
import {PartnershipReturnFields} from 'src/appApi.types';
import {format, parseISO} from 'date-fns';
import ChooseCPA from './components/ChooseCPA';
import {GridColDef} from '@mui/x-data-grid';
import SeeReturnDetails from './components/SeeReturnDetails';
import {PARTNERSHIP_RETURNS_SUPPORTED_YEARS} from './components/CreateReturnComponents/CreateReturn.constants';

export const PartnershipFiltersConfig = [
  {
    filter_name: PartnershipFilters.fly_user_id,
    transform: TRANSFORMERS.STRING_TO_NUMBER,
    type: PartnershipFilterType.single,
    location: PartnshipFilterLocation.home,
  },
  {
    filter_name: PartnershipFilters.email,
    transform: TRANSFORMERS.NONE,
    type: PartnershipFilterType.single,
    location: PartnshipFilterLocation.home,
  },
  {
    filter_name: PartnershipFilters.name,
    transform: TRANSFORMERS.NONE,
    type: PartnershipFilterType.single,
    location: PartnshipFilterLocation.home,
  },
  {
    filter_name: PartnershipFilters.return_status,
    transform: TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    options: Object.values(TAX_FILING_STATUS),
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.tax_extension_status,
    transform: TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    options: Object.values(TAX_EXTENSION_STATUS),
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.year,
    transform: [
      TRANSFORMERS.LIST_OF_STRING_TO_NUMBER,
      TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    ],
    options: PARTNERSHIP_RETURNS_SUPPORTED_YEARS,
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.assigned_cpa,
    transform: TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    options: Object.values(VALID_CPA_EMAILS),
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.reviewer,
    transform: TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    options: Object.values(VALID_CPA_EMAILS),
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.activity,
    transform: TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    options: Object.values(UserActivenessStatus),
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.partner,
    transform: [
      TRANSFORMERS.LIST_OF_STRING_TO_NUMBER,
      TRANSFORMERS.EMPTY_ARRAY_TO_NULL,
    ],
    options: [],
    type: PartnershipFilterType.multi,
    location: PartnshipFilterLocation.filter_pop_up,
  },
  {
    filter_name: PartnershipFilters.page_size,
    transform: TRANSFORMERS.STRING_TO_NUMBER,
    type: PartnershipFilterType.single,
    location: PartnshipFilterLocation.footer,
  },
  {
    filter_name: PartnershipFilters.page,
    transform: TRANSFORMERS.STRING_TO_NUMBER,
    type: PartnershipFilterType.single,
    location: PartnshipFilterLocation.footer,
  },
];

export const PartnershipMenuOptions = [
  {
    id: PartnershipMenuOptionTypes.ManagePartners,
    title: 'Manage Partners',
  },
  {
    id: PartnershipMenuOptionTypes.ExportColumns,
    title: 'Export columns',
  },
  {
    id: PartnershipMenuOptionTypes.ExportExtensionData,
    title: 'Export extension data',
  },
];

export const PartnershipUserTableColumns: GridColDef[] = [
  {
    field: PartnershipReturnFields.fly_user_id,
    headerName: 'User ID',
  },
  {field: PartnershipReturnFields.name, headerName: 'Name'},
  {
    field: PartnershipReturnFields.return_status,
    headerName: 'Return status',
    width: 150,
  },
  {
    field: PartnershipReturnFields.tax_extension_status,
    headerName: 'Extension status',
  },
  {field: PartnershipReturnFields.year, headerName: 'Tax year'},
  {field: PartnershipReturnFields.partner, headerName: 'Partner name'},
  {
    field: PartnershipReturnFields.assigned_cpa,
    headerName: 'CPA',
    editable: true,
    renderEditCell: ChooseCPA,
    width: 150,
  },
  {
    field: PartnershipReturnFields.reviewer,
    headerName: 'Reviewer',
    editable: true,
    renderEditCell: ChooseCPA,
    width: 150,
  },
  {field: PartnershipReturnFields.activity, headerName: 'Activity'},
  {
    field: PartnershipReturnFields.created_at,
    headerName: 'Created at',
    valueFormatter: (params: any) =>
      format(parseISO(params.value), DATE_FORMATS.DB_FORMAT),
  },
  {
    field: PartnershipReturnFields.notes,
    headerName: 'Notes',
    editable: true,
  },
  {
    field: '_',
    headerName: 'See details',
    renderCell: SeeReturnDetails,
  },
];
