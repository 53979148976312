import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {patchTaxFiling} from 'src/appApi';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';
import useCpaCenterV2Navigation from './useCpaCenterV2Navigation';
import useCurrentUserId from './useCurrentUserId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import _ from 'lodash';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {getCpaCenterV2Steps} from '../CpaCenterV2.utils';
const useCpaCenterV2Step = () => {
  const dispatch = useDispatch();
  const {
    navigateToTaxProfileReview,
    navigateToUserQueries,
    navigateToReviewEsign,
  } = useCpaCenterV2Navigation();

  const {userId} = useCurrentUserId();
  const {notify} = useNotify();
  const {activeYear} = useActiveYear();

  const {
    taxFilingId,
    loaded: userLoaded,
    cpaFacingRetStatus,
  } = useSelector(selectUser);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );

  const [isLoading, setIsLoading] = useState(false);
  const steps = getCpaCenterV2Steps({cpaFacingRetStatus, taxReturns});

  const markStepComplete = async (status: TAX_FILING_STATUS) => {
    try {
      setIsLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {cpa_facing_ret_status: status},
      );
      await dispatch(fetchUserInfo(userId, activeYear));
      if (status === TAX_FILING_STATUS.TAX_DOCS_REVIEWED) {
        navigateToTaxProfileReview();
      } else if (status === TAX_FILING_STATUS.USER_QUERIES_SENT) {
        navigateToUserQueries();
      } else if (status === TAX_FILING_STATUS.PREP_STARTED) {
        navigateToReviewEsign();
      }
      notify('Status updated successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to update status ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {pathname} = useLocation();

  const step = steps.find((step) => pathname.includes(step.props.section));

  let currentLabel;

  if (step) {
    currentLabel = step.label;
  }

  return {
    currentLabel,
    steps,
    markStepComplete,
    isLoading: isLoading || !taxReturnsLoaded || !userLoaded,
    cpaFacingRetStatus,
  };
};

export default useCpaCenterV2Step;
