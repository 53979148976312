import React, {useMemo} from 'react';
import {cloneDeep} from 'lodash';
import {useSelector} from 'react-redux';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';

export const useTaxProfile = (taxProfileYear?: number) => {
  const {activeYear} = useActiveYear();
  const year = taxProfileYear ?? activeYear;
  const {[year]: taxProfile} = useSelector(selectTaxProfile);
  const taxProfileMap = useMemo(() => {
    const _tpm: {[t in TaxProfileQuestion]?: any} = {};
    taxProfile?.forEach((tp) => {
      _tpm[tp.question_id] = tp.answer;
    });
    return cloneDeep(_tpm);
  }, [taxProfile]);

  return {
    taxProfileMap,
  };
};
