import React from 'react';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import CommonDeductionTable from './CommonDeductionTable';
import {useSelector} from 'react-redux';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const OtherDepreciations = () => {
  const {activeYear} = useActiveYear();
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const depreciationFoundInLastYear1040 = useSelector(
    (state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.DEPRECIATION_FOUND_IN_LAST_YEAR_1040,
        activeYear,
      ),
  );

  const relatedTF = taxForms.filter((tf) =>
    [TaxFormType.FORM_1040_NORMAL, TaxFormType.DEPRECIATION].includes(
      tf.formType,
    ),
  );

  const isNewDepreciation = relatedTF.some(
    (tf) => tf.formData[TaxFormFieldIds.TAX_FILING_YEAR] === activeYear,
  );

  return (
    <SubSectionBox
      name="Other depreciations"
      taxprofileSubsection={TaxProfileSubsection.OtherDepreciations}>
      <div style={{display: 'flex', flex: 1}}>
        <div style={{flex: 0.48}}>
          <SimpleKeyValueField
            name="Depreciation found in 1040"
            value={depreciationFoundInLastYear1040}
          />
          <SimpleKeyValueField
            name="New depreciation taken"
            value={isNewDepreciation}
          />
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.black60,
              marginBottom: 4,
            }}>
            Related document
          </Typography>
          {relatedTF.map((tf) => (
            <DocumentPointer
              doc={documents.find((doc) => doc.docId === tf.docId)}
              taxform={tf}
              dontShowStatus
              border
            />
          ))}
        </div>
        <div style={{flex: 0.48}}>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.OtherDepreciations}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default OtherDepreciations;
