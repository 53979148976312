import {getTaxForms} from 'src/appApi';
import {SET_TAX_FORMS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {GenericTaxForm} from './taxForms.types';

const setTaxForms = (payload: any) => {
  return {
    type: SET_TAX_FORMS,
    payload,
  };
};

export const fetchTaxFormsForGivenYear = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getTaxForms(userId, year);
      const taxForms: GenericTaxForm[] = response.data.map(
        ({
          is_end_user_reviewed: isEndUserReviewed,
          doc_link: docLink,
          form_data: formData,
          form_type: formType,
          id: taxFormId,
          tfd_info_id: docId,
          year,
        }) => {
          return {
            isEndUserReviewed,
            docId,
            docLink,
            formData,
            formType,
            taxFormId,
            year,
          };
        },
      );
      dispatch(setTaxForms({taxForms}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch taxforms (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
