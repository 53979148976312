import {get, isArray, isNull, isString, isUndefined, noop} from 'lodash';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getBusinessDetailFormConfig,
  overWriteBusinessDetailsFields,
} from 'src/CpaCenterV2/common/EditBusinessDetails/EditBusinesDetails.utils';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';
import {
  checkFormDataValidity,
  getParsedFields,
  parseFormConfigToFormData,
} from 'src/DesignSystem/Form/Form.utils';
import {PartnerCreditsSubsectionConfig} from 'src/Partnership/PartnerReturnInfo/PartnerTaxProfileReview/PartnerTaxProfileSections/PartnerCredits/PartnerCredits.constants';
import {overWriteTaxProfileFields} from 'src/common/EditTaxProfile/EditTaxProfile.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {getPath} from 'src/common/utils';
import {
  PartnerTaxProfileSectionSequence,
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {
  setEditTaxForm,
  setEditTaxProfileQuestions,
} from 'src/store/app/app.actions';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {TaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import {getEditTaxFormConfig} from 'src/store/taxForms/taxForms.utils';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import {
  FILING_STATUS_ANSWER,
  TaxProfileField,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import {getConfigForTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.utils';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {selectTaxProfileCpaSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {
  TaxReturnField,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';

export const usePartnerTaxProfileReview = () => {
  const {taxProfileCpaSideStatus, loaded} = useSelector(
    selectTaxProfileCpaSideStatus,
  );
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {businesses} = useSelector(selectBusinessDetails);
  const dispatch = useDispatch();

  const {activeYear} = useActiveYear();

  const isFilingPersonalReturn = useMemo(() => {
    const personalReturn = taxReturns.filter(
      (t) => t[TaxReturnField.return_type] === TaxReturnType.INDIVIDUAL,
    )?.[0];
    if (personalReturn && personalReturn[TaxReturnField.should_file_return]) {
      return true;
    }
    return false;
  }, [taxReturns]);

  const {taxProfileMap} = useTaxProfile();

  const getPreviousSection = (section: TaxProfileSection) => {
    const sectionIndex = PartnerTaxProfileSectionSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === 0) {
      return null;
    }
    return PartnerTaxProfileSectionSequence[sectionIndex - 1];
  };

  const getNextSection = (section: TaxProfileSection) => {
    const sectionIndex = PartnerTaxProfileSectionSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === PartnerTaxProfileSectionSequence.length - 1) {
      return null;
    }
    return PartnerTaxProfileSectionSequence[sectionIndex + 1];
  };

  const isSectionReviewPending = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    const isPending = allSubsections.some(
      (subsection) => !isSubsectionReviewComplete(subsection),
    );
    return isPending;
  };

  const isSubSectionVisible = (subsection: TaxProfileSubsection) => {
    const filingStatus = taxProfileMap[TaxProfileQuestion.FILING_STATUS];
    const selfSomeoneDependent =
      taxProfileMap[TaxProfileQuestion.CLAIM_YOU_DEPENDENT];
    const spouseSomeoneElseDependent =
      taxProfileMap[TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT];

    if (
      subsection === TaxProfileSubsection.SpouseDetails &&
      ([
        FILING_STATUS_ANSWER.SINGLE,
        FILING_STATUS_ANSWER.HEAD_OF_HOUSEHOLD,
      ].includes(filingStatus) ||
        !isFilingPersonalReturn)
    ) {
      return false;
    }
    if (
      subsection === TaxProfileSubsection.DependentDetails &&
      (!isFilingPersonalReturn ||
        selfSomeoneDependent ||
        spouseSomeoneElseDependent)
    ) {
      return false;
    }
    return true;
  };

  const isSubsectionVisibleAndReviewComplete = (
    subsection: TaxProfileSubsection,
  ) => {
    return (
      isSubSectionVisible(subsection) &&
      taxProfileCpaSideStatus[subsection] === TaxProfileReviewStatus.COMPLETE
    );
  };

  const isSomeSubsectionReviewComplete = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    return allSubsections.some(isSubsectionVisibleAndReviewComplete);
  };

  const getSubsectionTaxProfileQuestion = (
    subsection: TaxProfileSubsection,
  ) => {
    const filingStatus = taxProfileMap[TaxProfileQuestion.FILING_STATUS];
    let questions: (TaxProfileQuestion | string)[] = [];
    if (subsection === TaxProfileSubsection.UserDetails) {
      questions = [
        TaxProfileQuestion.LEGAL_NAME,
        TaxProfileQuestion.SSN,
        TaxProfileQuestion.DOB,
        TaxProfileQuestion.FILING_STATUS,
        TaxProfileQuestion.CLAIM_YOU_DEPENDENT,
        TaxProfileQuestion.DEPENDENT,
        TaxProfileQuestion.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
      ];
    }
    if (subsection === TaxProfileSubsection.HomeAddress) {
      questions = [
        TaxProfileQuestion.LIVING_OUTSIDE_USA,
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.STREET_NUMBER,
        ),
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.APARTMENT_NUMBER,
        ),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.CITY),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.ZIP_CODE),
        getPath(
          TaxProfileQuestion.HOME_ADDRESS,
          TaxProfileField.SCHOOL_DISTRICT_CODE,
        ),
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.COUNTY),
        TaxProfileQuestion.MAILING_ADDRESS_OUTSIDE_USA,
      ];
    }
    if (subsection === TaxProfileSubsection.SpouseDetails) {
      questions = [
        TaxProfileQuestion.SPOUSE_LEGAL_NAME,
        TaxProfileQuestion.SPOUSE_SSN,
      ];
      if (filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_SEPARAELY) {
        questions.push(
          ...[
            TaxProfileQuestion.SPOUSE_DOB,
            TaxProfileQuestion.SPOUSE_EMAIL,
            TaxProfileQuestion.SPOUSE_CLAIMED_DEPENDENT,
            TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT,
          ],
        );
      }
    }
    return questions.map((q) => getPath(q));
  };

  const isSubsectionComplete = (subsection: TaxProfileSubsection) => {
    if (!isSubSectionVisible(subsection)) {
      // if not visible then treat it as complete
      return true;
    }
    const filingStatus = taxProfileMap[TaxProfileQuestion.FILING_STATUS];
    const pureTaxProfileSubsections = [
      TaxProfileSubsection.UserDetails,
      TaxProfileSubsection.HomeAddress,
      TaxProfileSubsection.SpouseDetails,
    ];
    if (pureTaxProfileSubsections.includes(subsection)) {
      const taxProfieQuestions = getSubsectionTaxProfileQuestion(subsection);
      const config = getConfigForTaxProfileQuestions(taxProfieQuestions);
      const data = parseFormConfigToFormData(config, taxProfileMap);
      const fields = getParsedFields(
        config,
        data,
        noop,
        overWriteTaxProfileFields,
      );
      const areAllValid = checkFormDataValidity(fields);
      return areAllValid;
    }

    if (subsection === TaxProfileSubsection.DependentDetails) {
      const dependentTaxForms = taxForms.filter(
        (tf) => tf.formType === TaxFormType.DEPENDENT,
      ) as TaxForm<TaxFormType.DEPENDENT>[];
      if (
        filingStatus === FILING_STATUS_ANSWER.HEAD_OF_HOUSEHOLD &&
        dependentTaxForms.length === 0
      ) {
        return false;
      }
      const config = getEditTaxFormConfig(TaxFormType.DEPENDENT);
      return dependentTaxForms.every((dep) => {
        const fields = getParsedFields(config, dep.formData, noop);
        return checkFormDataValidity(fields);
      });
    }

    if (subsection === TaxProfileSubsection.BusinessDetails) {
      return businesses.every((biz) => {
        const config = getBusinessDetailFormConfig(
          biz[BusinessDetailsField.have_ein],
          biz[BusinessDetailsField.entity_type],
        );
        const fields = getParsedFields(config, biz, noop, (props) =>
          overWriteBusinessDetailsFields({...props, filingStatus}),
        );
        return checkFormDataValidity(fields);
      });
    }

    if (
      subsection === TaxProfileSubsection.DependentCredits ||
      subsection === TaxProfileSubsection.EducationalCredits ||
      subsection === TaxProfileSubsection.AdoptionCredits
    ) {
      const config = PartnerCreditsSubsectionConfig[subsection];
      const tpQuestions = config.taxProfileQuestions;
      const field = config.field;
      const dependentTaxForms = taxForms.filter(
        (tf) => tf.formType === TaxFormType.DEPENDENT,
      ) as TaxForm<TaxFormType.DEPENDENT>[];
      const allDependentCreditsAnswered = dependentTaxForms.every(
        (dep) => typeof dep.formData[field] === 'boolean',
      );

      const allCreditsTPQuestionsAnswered = tpQuestions.every((tp) => {
        if (
          tp === TaxProfileQuestion.IS_SPOUSE_ELIGIBLE_FOR_EDUCATION_CREDIT ||
          filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY
        ) {
          return true;
        }
        return typeof taxProfileMap[tp] === 'boolean';
      });

      return allDependentCreditsAnswered && allCreditsTPQuestionsAnswered;
    }

    if (subsection === TaxProfileSubsection.StatesTaxFilingInformation) {
      const tpState = get(
        taxProfileMap,
        getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE, '[0]'),
        null,
      );
      const livingOutsideUSA = get(
        taxProfileMap,
        TaxProfileQuestion.LIVING_OUTSIDE_USA,
        undefined,
      );
      if (!isSubsectionComplete(TaxProfileSubsection.HomeAddress)) {
        // if home address not complete then staes will be incomplete as well
        return false;
      }

      if (livingOutsideUSA === false) {
        const tpPeriods = get(
          taxProfileMap,
          getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.PERIODS),
          undefined,
        ) as undefined | Array<any>;
        if (!isArray(tpPeriods) || tpPeriods.length === 0) {
          return false;
        }
      }
    }

    return true;
  };

  const isSectionComplete = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    return allSubsections.every(isSubsectionComplete);
  };

  const onEditTaxProfileSubsection = (subsection: TaxProfileSubsection) => {
    const tpQuestions = getSubsectionTaxProfileQuestion(subsection);
    dispatch(
      setEditTaxProfileQuestions({
        pathPrefixes: tpQuestions,
        title: subsection,
      }),
    );
  };

  const onEditTaxForm = ({
    taxFormId,
    taxFormType,
    title,
    prefilledFormData = {},
  }: {
    taxFormId: number;
    taxFormType: TaxFormType;
    title: string;
    prefilledFormData?: any;
  }) => {
    dispatch(
      setEditTaxForm({
        isVisible: true,
        taxFormId,
        taxFormType,
        title,
        prefilledFormData,
      }),
    );
  };

  const onAddTaxForm = ({
    taxFormType,
    title,
    prefilledFormData = {},
  }: {
    taxFormType: TaxFormType;
    title: string;
    prefilledFormData?: any;
  }) => {
    dispatch(
      setEditTaxForm({
        isVisible: true,
        taxFormId: null,
        taxFormType,
        title,
        prefilledFormData,
      }),
    );
  };

  const isSubsectionReviewComplete = (subsection: TaxProfileSubsection) => {
    return (
      taxProfileCpaSideStatus[subsection] ===
        TaxProfileReviewStatus.COMPLETE || !isSubSectionVisible(subsection)
    );
  };
  const isSubSectionPendingFromUser = (subsection: TaxProfileSubsection) => {
    return false;
  };

  return {
    isSectionReviewPending,
    isSomeSubsectionReviewComplete,
    getNextSection,
    getPreviousSection,
    loaded,
    onEditTaxProfileSubsection,
    onEditTaxForm,
    onAddTaxForm,
    isSubsectionReviewComplete,
    isSubSectionPendingFromUser,
    isSubSectionVisible,
    isSubsectionComplete,
    isSectionComplete,
  };
};
