import _ from 'lodash';
import {getPath} from 'src/common/utils';
import {
  BusinessDetail,
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {BUSINESS_DETAILS_FIELD_CONFIG} from './EditBusinessDetails.config';
import {
  OverWriteFormFieldsFunctionArgsType,
  OverWriteFormFieldsFunctionType,
} from 'src/DesignSystem/Form/useFormData';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {BUSINESS_ENTITY_OPTIONS} from './EditBusinessDetails.constants';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';

const getFieldsForBusinessEntity = (
  have_ein: boolean,
  entity_type: BusinessType,
) => {
  if (!have_ein) {
    // Unreg. business
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.professions,
      BusinessDetailsField.owner_or_proprietor,
      BusinessDetailsField.start_date,
      BusinessDetailsField.is_spouse_business_partner,
      BusinessDetailsField.is_work_continuing,
      BusinessDetailsField.work_stop_date,
    ];
  }

  const addressFields = [
    getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.number_and_street,
    ),
    getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.apartment_number,
    ),
    getPath(BusinessDetailsField.address, BusinessDetailsField.city),
    getPath(BusinessDetailsField.address, BusinessDetailsField.state),
    getPath(BusinessDetailsField.address, BusinessDetailsField.zip_code),
  ];

  if (entity_type === BusinessType.SOLE_PROPRIETORSHIP) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.owner_or_proprietor,
      BusinessDetailsField.ein,
      BusinessDetailsField.start_date,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
    ];
  }

  if (entity_type === BusinessType.S_CORP) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.ein,
      BusinessDetailsField.incorporation_date,
      BusinessDetailsField.s_corp_effective_date,
      BusinessDetailsField.phone_number,
      BusinessDetailsField.accounting_method,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
      BusinessDetailsField.share_holders,
      BusinessDetailsField.signing_authority_id,
    ];
  }

  if (
    entity_type === BusinessType.C_CORP ||
    entity_type === BusinessType.PARTNERSHIP
  ) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.ein,
      BusinessDetailsField.incorporation_date,
      BusinessDetailsField.phone_number,
      BusinessDetailsField.accounting_method,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
      BusinessDetailsField.share_holders,
      BusinessDetailsField.signing_authority_id,
    ];
  }
  return [];
};

export const getBusinessDetailFormConfig = (
  have_ein: boolean,
  entity_type: BusinessType,
) => {
  const config = _.cloneDeep(BUSINESS_DETAILS_FIELD_CONFIG);
  let fieldsToShow = new Set();
  if (typeof have_ein !== 'boolean' || typeof entity_type !== 'string') {
    fieldsToShow = new Set([
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
    ]);
  } else {
    fieldsToShow = new Set(getFieldsForBusinessEntity(have_ein, entity_type));
  }

  return config.filter((field) => fieldsToShow.has(field.path));
};

export const getShareholderKey = (shareholder: any) => {
  const fName =
    shareholder[BusinessDetailsField.first_name_and_middle_initial];
  const lName = shareholder[BusinessDetailsField.last_name];
  const ssn = shareholder[BusinessDetailsField.ssn];
  return `${fName}-${lName}-${ssn}`;
};

export const overWriteBusinessDetailsFields = ({
  fields,
  data,
  setData,
  filingStatus,
}: OverWriteFormFieldsFunctionArgsType & {
  filingStatus: FILING_STATUS_ANSWER;
}) => {
  const fieldPathToFieldMap = _.keyBy(fields, 'path');
  const haveEinField = fieldPathToFieldMap[BusinessDetailsField.have_ein];
  const businessTypeField =
    fieldPathToFieldMap[BusinessDetailsField.entity_type];
  if (haveEinField && businessTypeField) {
    if (
      haveEinField.value === false &&
      businessTypeField.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      businessTypeField.options = BUSINESS_ENTITY_OPTIONS.filter(
        (option) => option.value === BusinessType.SOLE_PROPRIETORSHIP,
      );
    }
    haveEinField.onChangeValue = (newValue: boolean) => {
      setData((prev: any) => {
        let newState = {..._.set(prev, haveEinField.path, newValue)};
        if (newValue === false) {
          newState = _.set(
            newState,
            businessTypeField.path,
            BusinessType.SOLE_PROPRIETORSHIP,
          );
        }
        return newState;
      });
    };
  }

  const shareholders = (data as BusinessDetail)[
    BusinessDetailsField.share_holders
  ];
  const signAuthorityField =
    fieldPathToFieldMap[BusinessDetailsField.signing_authority_id];
  if (
    Array.isArray(shareholders) &&
    signAuthorityField &&
    signAuthorityField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    const options: typeof signAuthorityField.options = shareholders.map(
      (s) => ({
        label: s[BusinessDetailsField.first_name_and_middle_initial],
        value: getShareholderKey(s),
      }),
    );
    signAuthorityField.options = options;
  }

  const shareholderFields =
    fieldPathToFieldMap[BusinessDetailsField.share_holders];
  if (
    shareholderFields &&
    shareholderFields.inputType === FORM_INPUT_TYPE.Array
  ) {
    shareholderFields.onChangeValue = (newValue) => {
      setData((prev: any) => {
        let newState = {..._.set(prev, shareholderFields.path, newValue)};
        // on change in shareholder, reset signing authority id
        newState = _.set(
          newState,
          BusinessDetailsField.signing_authority_id,
          null,
        );
        return newState;
      });
    };
  }

  const sameHomeAddress =
    fieldPathToFieldMap[BusinessDetailsField.is_address_same_as_home];

  const fieldsToRemove: string[] = [];

  if (sameHomeAddress && sameHomeAddress.value === true) {
    const homeAddressFieldsPath = [
      BusinessDetailsField.number_and_street,
      BusinessDetailsField.apartment_number,
      BusinessDetailsField.city,
      BusinessDetailsField.state,
      BusinessDetailsField.zip_code,
    ].map((path) => getPath(BusinessDetailsField.address, path));
    fieldsToRemove.push(...homeAddressFieldsPath);
  }

  if (filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) {
    fieldsToRemove.push(BusinessDetailsField.is_spouse_business_partner);
  }
  const isWorkContinuing =
    fieldPathToFieldMap[BusinessDetailsField.is_work_continuing];

  if (isWorkContinuing && isWorkContinuing.value === true) {
    fieldsToRemove.push(BusinessDetailsField.work_stop_date);
  }

  const filteredFields = fields.filter(
    (field) => !fieldsToRemove.includes(field.path),
  );

  return filteredFields;
};
