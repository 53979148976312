import {SWITCH_ACTIVE_YEAR} from '../actionNames';
import {ReduxActionType} from '../types';

export const SUPPORTED_YEARS = [2022, 2023, 2024] as const;

const defaultYear = SUPPORTED_YEARS[SUPPORTED_YEARS.length - 1];

const initialState = {
  activeYear: defaultYear,
};

export const globals = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SWITCH_ACTIVE_YEAR:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

export type GlobalsStateType = typeof initialState;
