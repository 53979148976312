import {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {selectUser} from 'src/store/user/user.selector';
import {getPartnershipSteps} from '../Partnership.utils';
import {useLocation} from 'react-router-dom';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {patchTaxFiling} from 'src/appApi';
import {fetchUserInfo} from 'src/store/user/user.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {NotificationType} from 'src/store/app/app.reducer';
import useNotify from 'src/DesignSystem/Notify/useNotify';

export const useParntershipSteps = () => {
  const dispatch = useDispatch();

  const {
    cpaFacingRetStatus,
    taxFilingId,
    loaded: userLoaded,
  } = useSelector(selectUser);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const [isLoading, setIsLoading] = useState(false);
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {notify} = useNotify();
  const {navigateToTaxProfileReview} = usePartnershipNavigation();

  const steps = useMemo(
    () => getPartnershipSteps({cpaFacingRetStatus, taxReturns}),
    [cpaFacingRetStatus, taxReturns],
  );

  const markStepComplete = async (status: TAX_FILING_STATUS) => {
    try {
      setIsLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {cpa_facing_ret_status: status},
      );
      await dispatch(fetchUserInfo(userId, activeYear));
      if (status === TAX_FILING_STATUS.TAX_DOCS_REVIEWED) {
        navigateToTaxProfileReview();
      } else if (status === TAX_FILING_STATUS.USER_QUERIES_SENT) {
        // navigateToUserQueries();
      } else if (status === TAX_FILING_STATUS.PREP_STARTED) {
        // navigateToReviewEsign();
      }
      notify('Status updated successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to update status ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {pathname} = useLocation();

  const step = steps.find((step) => pathname.includes(step.props.section));

  let currentLabel;

  if (step) {
    currentLabel = step.label;
  }

  return {
    steps,
    currentLabel,
    markStepComplete,
    isLoading: isLoading || !taxReturnsLoaded || !userLoaded,
  };
};
