import {Typography} from '@mui/material';
import {format, parseISO} from 'date-fns';
import _ from 'lodash';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {CurrencyNumberFormat} from 'src/common/utils';
import {DATE_FORMATS, themmeColor} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';
import {setChildCareExpenseDrawer} from 'src/store/app/app.actions';
import {selectChildCareExpenseDrawer} from 'src/store/app/app.selector';
import {
  IdentificationType,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTransactionIdMap} from 'src/store/transactions/transaction.selector';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';

const ChildCareExpenseDrawer = () => {
  const {isVisible, title, formId} = useSelector(selectChildCareExpenseDrawer);

  const {taxForms} = useSelector(selectTaxFormsReducer);
  const txnMap = useSelector(selectTransactionIdMap);

  const dispatch = useDispatch();

  const onCloseDrawer = () => {
    dispatch(setChildCareExpenseDrawer(false));
  };

  const cceForm = taxForms.find((tf) => tf.taxFormId === formId);

  const txns = useMemo(() => {
    if (!isVisible || !cceForm) return [];
    const linkedTxnIds = cceForm.formData[TaxFormFieldIds.TXN_IDS];
    if (!Array.isArray(linkedTxnIds)) return [];
    const _txns: Transaction[] = [];
    linkedTxnIds.forEach((txnId) => {
      const txn = txnMap[txnId];
      if (txn) {
        _txns.push(txn);
      }
    });
    return _txns;
  }, [txnMap, cceForm, isVisible]);

  const fields = useMemo(() => {
    if (!isVisible || !cceForm) return [];
    const _fields = [
      {
        name: 'Name',
        fieldId: TaxFormFieldIds.CAREGIVER_NAME,
        value: '',
      },
    ];
    const idType = cceForm.formData[TaxFormFieldIds.CAREGIVER_ID_TYPE];
    if (idType === IdentificationType.EIN) {
      _fields.push({
        name: 'EIN',
        fieldId: TaxFormFieldIds.CAREGIVER_EIN,
        value: '',
      });
    } else {
      _fields.push({
        name: 'SSN',
        fieldId: TaxFormFieldIds.CAREGIVER_SSN,
        value: '',
      });
    }
    _fields.push(
      ...[
        {
          name: 'House number and street',
          fieldId: TaxFormFieldIds.NUMBER_AND_STREET,
          value: '',
        },
        {
          name: 'Apartment/Unit number (if applicable)',
          fieldId: TaxFormFieldIds.APARTMENT_NUMBER,
          value: '',
        },
        {
          name: 'City',
          fieldId: TaxFormFieldIds.CITY,
          value: '',
        },
        {
          name: 'State',
          fieldId: TaxFormFieldIds.STATE,
          value: '',
        },
        {
          name: 'Zip code',
          fieldId: TaxFormFieldIds.ZIP_CODE,
          value: '',
        },
      ],
    );
    return _fields.map((f) => ({...f, value: cceForm.formData[f.fieldId]}));
  }, [cceForm, isVisible]);

  const amount = _.sum(txns.map((txn) => txn.currentAmount));

  if (!cceForm || !isVisible) {
    return null;
  }

  return (
    <Drawer
      backgroundColor={themmeColor.white}
      direction="left"
      width={450}
      height={'90vh'}
      isOpen={isVisible}>
      <div
        style={{
          padding: 20,
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 24,
          }}>
          <Typography style={{fontSize: 20, fontWeight: 600, marginBlock: 16}}>
            {title}
          </Typography>
          <div onClick={onCloseDrawer}>
            <CrossIcon />
          </div>
        </div>
        <Box col style={{overflow: 'hidden'}} borderColor={themmeColor.silver}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              backgroundColor: themmeColor.cpaCenterV2Bg,
              paddingBlock: 8,
              paddingInline: 16,
            }}>
            <Typography style={{fontWeight: 600}}>Amount paid</Typography>
            <Typography style={{fontWeight: 600}}>
              {CurrencyNumberFormat(amount)}
            </Typography>
          </div>
          {txns.map((txn, index) => {
            const isLast = index === txns.length - 1;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                  padding: 16,
                  borderBottomStyle: isLast ? 'none' : 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: themmeColor.silver,
                }}>
                <div>
                  <Typography style={{fontWeight: 600}}>
                    {txn.merchantName}
                  </Typography>
                  <Typography style={{fontSize: 12}}>
                    Linked expense
                  </Typography>
                </div>
                <div>
                  <Typography>
                    {CurrencyNumberFormat(txn.currentAmount)}
                  </Typography>
                  <Typography style={{fontSize: 12}}>
                    {format(
                      parseISO(txn.txnDate),
                      DATE_FORMATS.DISPLAY_FORMAT,
                    )}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Box>

        <Typography style={{fontWeight: 600, marginBlock: 24}}>
          Details of persons or organisation who provided the care
        </Typography>
        {fields.map((field) => {
          return <SimpleKeyValueField name={field.name} value={field.value} />;
        })}
      </div>
    </Drawer>
  );
};

export default ChildCareExpenseDrawer;
