import React, {useState} from 'react';
import CCHAndDrake from 'src/CpaCenterV2/SentToCCH/components/CCHAndDrake';
import {Integrator} from 'src/CpaCenterV2/SentToCCH/components/CCHAndDrake.types';

const PartnerReviewAndEsign = () => {
  const [integrator, setIntegrator] = useState<Integrator>(Integrator.DRAKE);

  return (
    <div style={{padding: 20, overflowY: 'auto'}}>
      <CCHAndDrake integrator={integrator} setIntegrator={setIntegrator} />
    </div>
  );
};

export default PartnerReviewAndEsign;
