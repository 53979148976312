import {isString} from 'lodash';
import React from 'react';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {useCreateReturn} from './useCreateReturn';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import DSButton from 'src/DesignSystem/Button/Button';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';

interface ExistingUserPopupProps {
  ssn: string | null;
  onClose: () => void;
}

const ExistingUserPopup = ({ssn, onClose}: ExistingUserPopupProps) => {
  const {existingUsersData} = useCreateReturn();

  const {navigateToReturnDetails} = usePartnershipNavigation();

  const users = isString(ssn) ? existingUsersData[ssn] : [];
  type UserType = (typeof users)[0];
  return (
    <PopUp isOpen={isString(ssn)} onClose={onClose}>
      <Text
        type={TEXT_TYPES.L}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={`User profiles already exists (SSN: ${ssn})`}
        containerStyle={{marginBottom: 12}}
      />
      <div style={{maxHeight: '50vh', overflowY: 'auto'}}>
        <KeyValueTable
          keys={[
            {
              getValue: (row: UserType) => row.name,
            },
            {
              getValue: (row: UserType) => row.linked_partner,
              cellStyle: {
                paddingLeft: 20,
              },
            },
            {
              getValue: (row: UserType) => row.year,
            },
            {
              getValue: (row: UserType) => (
                <DSButton
                  type="secondary"
                  onClick={() => {
                    navigateToReturnDetails({
                      userId: row.fly_user_id,
                      year: row.year,
                      newTab: true,
                      status: '',
                    });
                  }}
                  text="View"
                />
              ),
            },
          ]}
          data={users}
        />
      </div>
    </PopUp>
  );
};

export default ExistingUserPopup;
