import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import PartnersList from './PartnersList/PartnersList';
import {RouteParts} from 'src/constants/routeName';
import Partnership from './PartnerReturnInfo/Partnership';
import PartnershipUserList from './PartnershipUserList/PartnershipUserList';
import {useDispatch} from 'react-redux';
import {fetchPartners} from 'src/store/partners/partners.actions';

const PartnershipRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartners());
  }, []);

  return (
    <Routes>
      <Route path={RouteParts.Partners} element={<PartnersList />} />
      <Route
        path={`${RouteParts.UserId}/${RouteParts.Year}/*`}
        element={<Partnership />}
      />
      <Route path={'/'} element={<PartnershipUserList />} />
      <Route path="*" element={<h1>OMG not found</h1>} />
    </Routes>
  );
};

export default PartnershipRouter;
